import {DeleteApikeyApiParams, AddApikeyApiParams, UpdateApikeyApiParams, GetApikeysApiParams, RenewApikeyApiParams } from './types';
import { useCallback } from 'react';
import apiClient from '../axiosClient';

export function useGetApikeysApi(): GetApikeysApiParams {
  return useCallback((params) => apiClient.get(`/api-keys`, {params}), []);
}

export function useDeleteApikeyApi(): DeleteApikeyApiParams {
  return useCallback((apikeyIds, companyId) => apiClient.delete(`/api-keys`, {data: {ids: apikeyIds}, params: {companyId}}), []);
}

export function useAddApikeyApi(): AddApikeyApiParams {
  return useCallback((params, companyId) => apiClient.post(`/api-keys`, params, {params: {companyId}}), []);
}

export function useUpdateApikeyApi(): UpdateApikeyApiParams {
  return useCallback((params, apikeyId, companyId) => apiClient.put(`/api-keys/${apikeyId}`, params, {params: {companyId}}), []);
}

export function useRenewApikeyApi(): RenewApikeyApiParams {
  return useCallback((apikeyId, companyId) => apiClient.post(`/api-keys/${apikeyId}/renew`, {}, {params: {companyId}}), []);
}
