import { GetCompaniesApiParams } from './types';
import { useCallback } from 'react';
import apiClient from '../axiosClient';

/**
 * @function useGetCompaniesApi
 * @returns { GetCompaniesApiParams }
 */
export function useGetCompaniesApi(): GetCompaniesApiParams {
  return useCallback(() => apiClient.get(`/users/companies`), []);
}
