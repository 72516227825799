import React, { useContext } from "react";
import { Container, Description, Lock, Title } from "./styles";
import { AuthContext } from "src/contexts/AuthContext";
import { useDispatch } from "react-redux";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

function AccessDenied() {
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    authContext?.setIsAuthenticated(false);
    localStorage.removeItem('a_token');
    localStorage.removeItem('r_token');
    dispatch({ type: 'logout' });
  }

  return (
    <Container>
      <Lock />
      <div>
        <Title>Access denied.
</Title>
        <Description>
        You do not currently have access to any companies on the platform.
        </Description>
        <Description>
          <Link href="#" onClick={() => { handleLogout(); navigate('/login')}}>Back to Login</Link>
        </Description>
      </div>
    </Container>
  );
}

export default AccessDenied;
