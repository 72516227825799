import styled from "styled-components";

export const PageHeader = styled.div`
  font-size: 23px;
  color: #254597;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: end;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
`;
