import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ImportContacts from "./importContacts";
import { ImportContactsModalStep } from "../costants";
import { ContentContainer } from "./styles";
import AddContactsToGroups from "./AddContactsToGroups";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "700px",
    height: "700px",
  },
}));

type DownloadCsvModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  refreshGroups: () => void;
};

export default function DownloadCsvModal({
  show,
  setShowModal,
  refreshGroups,
}: DownloadCsvModalType) {
  const [error, setError] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('Import Contacts');
  const [createdContactsIds, setCreatedContactsIds] = useState<string[]>([]);
  const [existedContactsIds, setExistedContactsIds] = useState<string[]>([]);
  const [importContactsStep, setImportContactsStep] =
    useState<ImportContactsModalStep>(ImportContactsModalStep.import);

  const handleClose = () => {
    setShowModal(false);
    refreshGroups();
  };

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
        fullWidth={true}
        keepMounted={false}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {modalTitle}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ContentContainer>
            {importContactsStep === ImportContactsModalStep.import && (
              <ImportContacts
                setCreatedContactsIds={setCreatedContactsIds}
                setExistedContactsIds={setExistedContactsIds}
                error={error}
                setError={setError}
                setImportContactsStep={setImportContactsStep}
              />
            )}
            {importContactsStep === ImportContactsModalStep.addToGroups && (
              <AddContactsToGroups
                error={error}
                setError={setError}
                existedContactsIds={existedContactsIds}
                createdContactsIds={createdContactsIds}
                setImportContactsStep={setImportContactsStep}
                handleClose={handleClose}
                setModalTitle={setModalTitle}
              />
            )}
          </ContentContainer>
        </DialogContent>
      </BootstrapDialog>
    </Fragment>
  );
}
