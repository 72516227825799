import React, { Fragment, useState, useEffect } from "react";
import { styled, css } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { ContentContainer, Container, FooterContainer } from "./styles";
import Button from "@mui/material/Button";
import clsx from "clsx";
import {
  ValidationErrors,
  addApikeyValidationSchema,
} from "src/constants/validations";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import FormHelperText from "@mui/material/FormHelperText";
import { Apikey } from "src/constants/types";
import FormControl from "@mui/material/FormControl";
import { PermissionType } from "../constants";
import { useAddApikeyApi, useUpdateApikeyApi } from "src/api/apikeys";
import SelectWithSearch from "src/components/SelectWithSearch/view";
import { Modal as BaseModal } from "@mui/base/Modal";
import { Box } from "@mui/material";

// eslint-disable-next-line react/display-name
const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    height: "700px",
  },
  "& .MuiPaper-root": {
    maxWidth: "unset",
  },
}));

type AddApikeyModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  apikeyData?: Apikey;
  refresh: () => void;
};

const initialValues = {
  name: "",
  permissions: [] as PermissionType[],
  description: "",
};

export default function AddApiKeyModal({
  show,
  setShowModal,
  refresh,
  apikeyData,
}: AddApikeyModalType) {
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  const [openTokenModal, setOpenTokenModal] = React.useState<boolean>(false);
  const [token, setToken] = useState<string>(apikeyData?.token ?? "");
  const addApiKey = useAddApikeyApi();
  const updateApiKey = useUpdateApikeyApi();

  const handleClose = () => {
    setFormValues(initialValues);
    setErrors({});
    setIsButtonDisabled(true);
    setShowModal(false);
    refresh();
  };

  const validateFormSync = (values: {
    name: string;
    permissions: PermissionType[];
    description: string;
  }) => {
    try {
      addApikeyValidationSchema.validateSync(values, { abortEarly: false });
      return false;
    } catch (err) {
      return true;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValues = { ...formValues, [name]: value };
    setFormValues(newValues);
    setIsButtonDisabled(validateFormSync(newValues));
  };

  const handleSelectChange = (permissions: string[]) => {
    const newValues = {
      ...formValues,
      permissions: permissions as PermissionType[],
    };
    setFormValues(newValues);

    setIsButtonDisabled(validateFormSync(newValues));
  };

  const validate = async (values: typeof formValues) => {
    try {
      await addApikeyValidationSchema.validate(values, {
        abortEarly: false,
      });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleAddApikey = async () => {
    const isValid = await validate(formValues);
    if (isValid) {
      selectedCompanyId &&
        addApiKey(
          formValues.description
            ? {
                ...formValues,
              }
            : {
                name: formValues.name,
                permissions: formValues.permissions,
              },
          selectedCompanyId
        ).then((data) => {
            setToken(data.data.token);
            setOpenTokenModal(true);
            handleClose();
          })
          .catch((e) => {
            if (
              e.message &&
              e.message.includes("api_keys_company_id_name_key")
            ) {
              setErrors({
                name: "Each API Key requires a unique name.",
              });
            }
          });
    }
  };

  const handleApikeyEdit = () => {
    selectedCompanyId &&
      apikeyData?.id &&
      updateApiKey(
        formValues.description
          ? {
              name: formValues.name,
              description: formValues.description,
              permissions: formValues.permissions,
            }
          : {
              name: formValues.name,
              permissions: formValues.permissions,
            },
        apikeyData?.id,
        selectedCompanyId
      )
        .then(() => {
          handleClose();
        })
        .catch((e) => {
          if (e.error === "conflict" || e.error === "validation") {
            setErrors({ validation: "Something went wrong!" });
          }
          if (
            e.message &&
            e.message.includes("api_keys_company_id_name_key")
          ) {
            setErrors({
              name: "Each API Key requires a unique name.",
            });
          }
        });
  };

  const formatPermissions = (data: {name: string, description: string}[]) : PermissionType[]=> {
    const permissions = data.map(d => {
      switch (d.name) {
        case "campaign_send":
          return PermissionType.CampaignSend;
        case "contact_metadata":
          return PermissionType.ContactMetadata;
        case "message_send":
          return PermissionType.MessageSend;
      }
    }) as PermissionType[];
    return permissions;
  }

  useEffect(() => {
    apikeyData &&
      setFormValues({
        name: apikeyData.name,
        permissions: formatPermissions(apikeyData.permissions),
        description: apikeyData.description,
      });
  }, [apikeyData?.id]);

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
        keepMounted={false}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {apikeyData ? "Edit API Key" : "Add API Key"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
        >
          <Container>
            <ContentContainer>
              <TextField
                id="outlined-basic"
                label="Name"
                name="name"
                variant="outlined"
                value={formValues.name}
                onChange={handleChange}
                error={!!errors?.name}
                helperText={errors?.name || ""}
              />
              <TextField
                id="outlined-basic"
                style={{ marginTop: "20px" }}
                label="Description"
                name="description"
                variant="outlined"
                value={formValues.description}
                onChange={handleChange}
                error={!!errors?.description}
                helperText={errors?.description || ""}
              />
              <FormControl sx={{ marginTop: "20px" }}>
                <SelectWithSearch
                  label="Permissions"
                  placeholder=""
                  options={[
                    { text: "Create Campaign", value: "campaign_send" },
                    { text: "Add/Update Contacts", value: "contact_metadata" },
                    { text: "Send Message", value: "message_send" },
                  ]}
                  values={formValues.permissions || []}
                  handleSelectChange={(e, items) =>
                    handleSelectChange(items.map((i) => i.value))
                  }
                  style={{ width: "100%", maxHeight: "120px" }}
                />
                {errors?.permissions && (
                  <FormHelperText error>{errors?.permissions}</FormHelperText>
                )}
              </FormControl>
              {apikeyData && apikeyData.token && (
                <>
                  <FormHelperText sx={{ marginTop: "10px" }}>
                    API Key
                  </FormHelperText>
                  <Box
                    component="section"
                    sx={{
                      p: 2,
                      wordWrap: "break-word",
                      border: "1px dashed grey",
                      fontSize: "12px",
                    }}
                  >
                    {apikeyData.token}
                  </Box>
                </>
              )}
            </ContentContainer>
          </Container>
          {errors?.validation && (
            <FormHelperText error>{errors?.validation}</FormHelperText>
          )}
          <FooterContainer>
            {apikeyData ? (
              <Button
                variant="contained"
                disabled={isButtonDisabled}
                style={{ width: "180px", marginTop: "20px" }}
                onClick={handleApikeyEdit}
              >
                Update API Key
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{ width: "180px", marginTop: "20px" }}
                onClick={handleAddApikey}
                disabled={isButtonDisabled}
              >
                Create API Key
              </Button>
            )}
          </FooterContainer>
        </DialogContent>
      </BootstrapDialog>

      <Modal
        slots={{ backdrop: StyledBackdrop }}
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={openTokenModal}
        onClose={() => setOpenTokenModal(false)}
      >
        <ModalContent sx={{ width: 400 }}>
          <FormHelperText>API Key</FormHelperText>
          <Box
            component="section"
            sx={{
              p: 2,
              wordWrap: "break-word",
              border: "1px dashed grey",
              fontSize: "12px",
            }}
          >
            {token}
          </Box>
        </ModalContent>
      </Modal>
    </Fragment>
  );
}
