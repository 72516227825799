import React, { Fragment, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ContentContainer } from './styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        width: '700px',
        height: '700px'
    },
}));

type SetCampaignScheduleModalType = {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleCampaignSave: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>
    dateScheduled?: string | null | undefined;
    nextDateScheduled?: string | null | undefined;
    prevDateScheduled?: string | null | undefined;
}

export default function SetCampaignScheduleModal({ show, dateScheduled, nextDateScheduled, prevDateScheduled, setShowModal, handleCampaignSave }: SetCampaignScheduleModalType) {
    const [date, setDate] = useState<dayjs.Dayjs | null>(dateScheduled ? dayjs(dateScheduled) : null);
    const handleClose = () => {
        setShowModal(false);
    }

    const handleSaveSchedule = async () => {
        handleCampaignSave(date);
        handleClose();
    }

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={show}
                fullWidth={true}
                keepMounted={false}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" >
                    Schedule
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <ContentContainer>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DateTimePicker']}>
                                <DateTimePicker minDate={dateScheduled ? dayjs(prevDateScheduled) : prevDateScheduled ? dayjs(prevDateScheduled) : null} maxDate={nextDateScheduled ? dayjs(nextDateScheduled) : null} label="Date and Time Scheduled" disablePast onChange={(value) => setDate(value)} defaultValue={null} />
                            </DemoContainer>
                        </LocalizationProvider>
                        <Box style={{marginTop: '40px'}}>
                            <Button variant="contained" color="secondary" style={{ width: '150px', marginRight: "20px" }} onClick={handleClose}>Cancel</Button>
                            <Button variant="contained" style={{ width: '150px' }} disabled={!date} onClick={handleSaveSchedule}>Schedule</Button>
                        </Box>
                    </ContentContainer>
                </DialogContent>
            </BootstrapDialog>
        </Fragment>
    );
}

