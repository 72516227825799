import { useCallback } from 'react';
import apiClient from '../axiosClient';
import { GetCampaignsApiParams } from './types';
import { CreateJoinCampaignApiParams, CreateMessageCampaignApiParams, UpdateJoinCampaignApiParams, UpdateMessageCampaignApiParams, ActivateCampaignApiParams, DeactivateCampaignApiParams, GetCampaignApiParams} from './types';

/**
 * @function useGetCampaignsApi
 * @returns { GetCampaignsApiParams }
 */
export function useGetCampaignsApi(): GetCampaignsApiParams {
    return useCallback(params => apiClient.get(`/campaigns`, { params }), []);
}


/**
 * @function useGetCampaignApi
 * @returns { GetCampaignApiParams }
 */
export function useGetCampaignApi(): GetCampaignApiParams {
    return useCallback((params, campaignId) => apiClient.get(`/campaigns/${campaignId}`, { params }), []);
}

/**
 * @function useCreateJoinCampaignApi
 * @returns { CreateJoinCampaignApiParams }
 */
export function useCreateJoinCampaignApi(): CreateJoinCampaignApiParams {
    return useCallback((params, companyId) => apiClient.post(`/campaigns`, params, { params: { companyId } }), []);
}

/**
 * @function useCreateMessageCampaignApi
 * @returns { CreateMessageCampaignApiParams }
 */
export function useCreateMessageCampaignApi(): CreateMessageCampaignApiParams {
    return useCallback((params, companyId) => apiClient.post(`/campaigns`, params, { params: { companyId } }), []);
}

/**
 * @function useUpdateJoinCampaignApi
 * @returns { UpdateJoinCampaignApiParams }
 */
export function useUpdateJoinCampaignApi(): UpdateJoinCampaignApiParams {
    return useCallback((params, campaignId, companyId) => apiClient.put(`/campaigns/${campaignId}`, params, { params: { companyId } }), []);
}

/**
 * @function useUpdateMessageCampaignApi
 * @returns { UpdateMessageCampaignApiParams }
 */
export function useUpdateMessageCampaignApi(): UpdateMessageCampaignApiParams {
    return useCallback((params, campaignId, companyId) => apiClient.put(`/campaigns/${campaignId}`, params, { params: { companyId } }), []);
}

/**
 * @function useActivateCampaignApi
 * @returns { ActivateCampaignApiParams }
 */
export function useActivateCampaignApi(): ActivateCampaignApiParams {
    return useCallback((params, companyId) => apiClient.put(`/campaigns/activate`, params, { params: { companyId } }), []);
}

/**
 * @function useDeactivateCampaignApi
 * @returns { DeactivateCampaignApiParams }
 */
export function useDeactivateCampaignApi(): DeactivateCampaignApiParams {
    return useCallback((params, companyId) => apiClient.put(`/campaigns/deactivate`, params, { params: { companyId } }), []);
}