import { Box, Button, IconButton, Stack, TextField } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { GetAdminCompaniesApiParams } from "src/api/adminCompanies/types";
import { AdminCompany } from "src/constants/types";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import CreateAdminCompanyModal from "src/pages/Companies/CreateCompanyModal/view";
import useConfirmationDialog from "../ConfirmationDialog/view";
import {
  useDeleteAdminCompanyApi,
  useGetAdminCompanyApi,
} from "src/api/adminCompanies";

interface IProps {
  fetchData: GetAdminCompaniesApiParams;
  pageSize: number;
}

const BhiTable = ({ fetchData, pageSize = 5 }: IProps) => {
  const initialCompany: AdminCompany = {
    id: "",
    name: "",
    messagingProfileId: "",
    createMessageProfile: true,
    tcrCampaignId: "",
    status: "",
    validContacts: 0,
    phoneNumber: "",
    phoneNumbers: [],
  };

  const [rows, setRows] = useState<AdminCompany[]>();
  const [data, setData] = useState<AdminCompany>(initialCompany);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>();
  const { openDialog, ConfirmationDialog } = useConfirmationDialog();
  const [showCreateAdminCompanyModal, setCreateAdminCompanyModal] =
    useState(false);

  const deleteCompany = useDeleteAdminCompanyApi();
  const getCompany = useGetAdminCompanyApi();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Company",
      minWidth: 100,
      flex: 1,
      sortable: true,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 80,
      sortable: false,
      editable: false,
    },
    {
      field: "phoneNumbers",
      headerName: "Phone Numbers",
      flex: 1,
      valueGetter: (value, row) => row.phoneNumbers,
      renderCell: (row) => {
        if (!row.formattedValue) {
          return "";
        }
        if (Array.isArray(row.formattedValue)) {
          return row.formattedValue.map((m) => m.number).join(",");
        }
      },
      sortable: false,
      editable: false,
    },
    {
      field: "messagingProfileId",
      headerName: "Messaging Profile ID",
      flex: 1,
      minWidth: 200,
      sortable: false,
      editable: false,
    },
    {
      field: "tcrCampaignId",
      headerName: "TCR Campaign ID",
      flex: 1,
      sortable: false,
      resizable: false,
      editable: false,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
      maxWidth: 150,
      sortable: false,
      resizable: false,
      editable: false,
      renderCell: (row) => {
        return (
          <>
            <IconButton
              sx={{
                color: "#264597",
                borderRadius: '50%',
                backgroundColor: 'transparent',
                "&:hover": {
                  backgroundColor: "transparent",
                }
              }}
              className="action"
              onClick={(e) => {
                e.stopPropagation();
                handleAdminCompanyEdit(row.row.id);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              sx={{
                color: "#264597",
                borderRadius: '50%',
                "&:hover": {
                  backgroundColor: "transparent",
                  
                }
              }}
              className="action"
              onClick={(e) => {
                e.stopPropagation();
                openDialog({
                  title: `Delete ${row.row.name}`,
                  content: "Are you sure you want to delete this company?",
                  onConfirm: () => {
                    handleAdminCompanyDelete(row.row.id);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: pageSize,
    });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);

  const getQueryParams = () => {
    const params = {
      limit: pageSize,
      offset: paginationModel.page * pageSize,
      sort: `${sortModel[0].field}_${sortModel[0].sort}`,
      query: searchQuery,
    };

    if (!searchQuery) {
      delete params.query;
    }

    return params;
  };

  const loadData = async () => {
    setIsLoading(true);
    const response = await fetchData(getQueryParams());
    setRows(response.data.items);
    setTotalCount(response.data.count);
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [paginationModel, sortModel, searchQuery]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleAdminCompanyEdit = (id: string) => {
    getCompany(id).then((response) => {
      setData(response.data);
      setCreateAdminCompanyModal(true);
    });
  };

  const handleAdminCompanyDelete = (id: string) => {
    deleteCompany(id).then(loadData);
  };

  return (
    <Box sx={{ paddingTop: "20px;" }}>
      <Stack
        direction={"row"}
        sx={{
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          border: "1px solid #F0F0F0",
          padding: "15px",
        }}
        justifyContent={"space-between"}
        height="40px"
      >
        <TextField
          size="small"
          onChange={handleChange}
          InputProps={{ endAdornment: <SearchIcon /> }}
        />
        <Button
          sx={{ height: "40px;" }}
          size="small"
          variant="contained"
          onClick={() => {
            setData(initialCompany);
            setCreateAdminCompanyModal(true);
          }}
        >
          Create Company
        </Button>
        <CreateAdminCompanyModal
          initialCompany={initialCompany}
          adminCompany={data}
          show={showCreateAdminCompanyModal}
          setShowModal={setCreateAdminCompanyModal}
          refreshCompanies={loadData}
        />
      </Stack>

      <Box sx={{ marginTop: "-5px" }}>
        <DataGrid
          columns={columns}
          autosizeOptions={{
            columns: ["actions"],
            includeOutliers: true,
            includeHeaders: false,
          }}
          rows={rows}
          pagination
          disableMultipleRowSelection
          disableRowSelectionOnClick
          pageSizeOptions={[pageSize]}
          sortingMode="server"
          paginationMode="server"
          paginationModel={paginationModel}
          sortModel={sortModel}
          disableColumnFilter
          sortingOrder={["asc", "desc"]}
          rowSelection={false}
          rowCount={totalCount}
          loading={isLoading}
          onPaginationModelChange={(
            newPaginationModel: GridPaginationModel
          ) => {
            setPaginationModel(newPaginationModel);
          }}
          onSortModelChange={(sortModel) => {
            setSortModel(sortModel);
          }}
          sx={{
            height: "calc(100vh - 250px)",
            "&, [class=MuiDataGrid]": {
              borderTop: "none",
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: "6px",
              borderBottomRightRadius: "6px",
            },
            "& .MuiDataGrid-columnSeparator": {
              opacity: "1 !important;",
            },
            "& .MuiDataGrid-columnHeader[data-field=tcrCampaignId] .MuiDataGrid-columnSeparator--sideRight, .MuiDataGrid-columnHeader[data-field=actions] .MuiDataGrid-columnSeparator--sideRight":
              {
                display: "none",
              },
            "& .MuiDataGrid-container--top [role=row]": {
              backgroundColor: "rgb(37, 69, 151)",
              color: "white",
            },
            "& .MuiDataGrid-sortIcon": {
              opacity: 1,
              color: "white",
            },
            "& .MuiDataGrid-menuIconButton": {
              opacity: 1,
              color: "white",
            },
            "& .MuiDataGrid-columnHeader": {
              borderBottom: "none !important",
            },
            "& .action": {
              display: "none",
            },
            "& .MuiDataGrid-row:hover .action": {
              display: "initial",
            },
          }}
        />
      </Box>
      <ConfirmationDialog />
    </Box>
  );
};

export default BhiTable;
