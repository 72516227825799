import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

type ConfirmationDialogProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    content: string;
    confirmButtonText?: string,
}
type DialogType = { title: string, content: string, onConfirm: () => void,  confirmButtonText?: string}

const ConfirmationDialog = ({ open, onClose, onConfirm, title, content, confirmButtonText = 'Confirm' }: ConfirmationDialogProps) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant='contained' color="primary">
                    Cancel
                </Button>
                <Button onClick={onConfirm} variant='contained' color="error">
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useConfirmationDialog = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [confirmButtonText, setConfirmButtonText] = useState('Confirm');
    const [dialogConfig, setDialogConfig] = useState({
        title: '',
        content: '',
        onConfirm: () => {},
    });

    const openDialog = ({ title, content, onConfirm, confirmButtonText }: DialogType) => {
        if (confirmButtonText) {
            setConfirmButtonText(confirmButtonText);
        }
        setDialogConfig({ title, content, onConfirm});
        setIsOpen(true);
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    return {
        openDialog,
        ConfirmationDialog: () => (
            <ConfirmationDialog
                open={isOpen}
                onClose={closeDialog}
                onConfirm={() => {dialogConfig.onConfirm(); closeDialog()}}
                title={dialogConfig.title}
                content={dialogConfig.content}
                confirmButtonText={confirmButtonText}
            />
        ),
    };
};

export default useConfirmationDialog;
