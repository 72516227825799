import styled from 'styled-components';

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Label = styled.div`
    margin-top: 10px;
`;