import styled from 'styled-components';

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 450px;
    border-radius: 5px;
`;

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-left: 30px;
    width: 800px;
`

export const Container = styled.div`
    display: flex;
    width: 100%;
`

export const FooterContainer = styled.div`
    display: flex;
    margin-top: 10px;
    align-items: center;

    .MuiFormControlLabel-label { 
        font-size: 14px;
    }
`;