import React, { useState } from 'react';
import { ForgorPassContainer, Container, Header, SubHeader } from './styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForgotPasswordApi } from 'src/api/authentication';
import { emailValidationSchema, ValidationErrors } from 'src/constants/validations';
import * as Yup from 'yup';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
    const forgotPassword = useForgotPasswordApi();
    const navigate = useNavigate();
    const handleSendEmail = async () => {
        const isValid = await validate(formValues);
        if (isValid) {
            forgotPassword(formValues).then(() => {
                setEmailSent(true);
            }).catch((e) => {
                if (e.error === 'not_exists') {
                    setEmailSent(true);
                }
            })
        }
    };
    const [emailSent, setEmailSent] = useState(false);
    const [errors, setErrors] = useState<ValidationErrors>({});
    const [formValues, setFormValues] = useState({ email: '' });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const validate = async (values: typeof formValues) => {
        try {
            await emailValidationSchema.validate(values, { abortEarly: false });
            setErrors({});
            return true;
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const newErrors = err.inner?.reduce((acc, curr) => {
                    if (curr.path) {
                        acc[curr.path] = curr?.message;
                    }
                    return acc;
                }, {} as ValidationErrors);
                setErrors(newErrors);
            }
            return false;
        }
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement | HTMLTextAreaElement> ): void => {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          handleSendEmail()
        }
      }


    return <Container>
        <ForgorPassContainer>
            <Header>Forgot Password</Header>
            {!emailSent ? <><SubHeader>Enter the email associated with your account. We will email you a link to reset your password.</SubHeader>
                <TextField onKeyDown={onKeyDown} id="outlined-basic" error={!!errors?.email} helperText={errors?.email || ''} label="Email Address" name="email" variant="outlined" value={formValues.email} onChange={handleChange} />
                <Button variant="contained" size="large" onClick={handleSendEmail} style={{ marginTop: '30px' }}>
                    Send Link
                </Button></> :
                <>
                    <SubHeader>Check Your Email</SubHeader>
                    <div>{`We're on it! If there's an account linked to this email address, you'll find an email from us with a password reset link shortly.
                    If you don't see our email within a few minutes, please check your spam or junk folder. For additional assistance, contact our support team `}<i>support@agencytext.com</i>.</div>
                </>}
                <Link
                    component="button"
                    variant="body2"
                    style={{textAlign: 'right', marginTop: '8px'}}
                    onClick={() => {
                       navigate('/login');
                    }}
                    >
                    Back to Login
                    </Link>
        </ForgorPassContainer>
    </Container>
}

export default ForgotPassword;
