import React from "react";
import {SortIconType} from "./styles";

const SortIcon = ({dir}: {
    dir: string;
}) => {
    return <SortIconType>{ dir == 'asc' ? '△' : '▽'}</SortIconType>
};

export default SortIcon;
