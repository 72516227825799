import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface PermissionsState {
  list: string[];
}

const initialState: PermissionsState = {
  list: [],
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<string[]>) => {
      state.list = action.payload;
    },
  },
});

export const { setPermissions } = permissionsSlice.actions;

export const selectPermissions = (state: RootState) => state.permissions.list;

export default permissionsSlice.reducer;
