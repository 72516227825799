import MainWrapper from "src/components/MainWrapper/view";
import React, { useMemo, useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import UserManagment from "./Components/UserManagement/view";
import { useSelector } from "react-redux";
import Integrations from "./Components/Integrations/view";
import { isActionAllowed } from "src/helpers/permissions";
import { selectProfile } from "src/store/profile/profileSlice";
import CustomFields from "./Components/CustomFields/view";
import ApiKeys from "./Components/ApiKeys/view";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const getProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const PersonalInformation = () => {
  const [tab, setTab] = useState(0);
  const { profile } = useSelector(selectProfile);
  const profileRole = useMemo(
    () => (profile?.role ? profile.role.name : "bhi_admin"),
    [profile]
  );

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  if (!profile) {
    return null;
  }

  return (
    <MainWrapper>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="personal information tab"
        >
          <Tab label="Company" {...getProps(0)} />
          {isActionAllowed({ authorRole: profileRole }) && (
            <Tab label="User Management" {...getProps(1)} />
          )}
          <Tab label="Contact Fields" {...getProps(2)} />
          {isActionAllowed({ authorRole: profileRole }) && (
            <Tab label="Webhook Integrations" {...getProps(3)} />
          )}
          {isActionAllowed({ authorRole: profileRole }) && (
            <Tab label="API Integrations" {...getProps(4)} />
          )}
        </Tabs>
        <CustomTabPanel value={tab} index={0}>
          <Box sx={{ width: "100%" }}>
            <CustomFields isCompanyFields={true} showButtons={isActionAllowed({ authorRole: profileRole })} />
          </Box>
        </CustomTabPanel>
        {isActionAllowed({ authorRole: profileRole }) && (
          <CustomTabPanel value={tab} index={1}>
            <Box sx={{ width: "100%" }}>
              <UserManagment />
            </Box>
          </CustomTabPanel>
        )}
        
        <CustomTabPanel value={tab} index={2}>
          <Box sx={{ width: "100%" }}>
            <CustomFields isCompanyFields={false} showButtons={isActionAllowed({ authorRole: profileRole })} />
          </Box>
        </CustomTabPanel>
        
        <CustomTabPanel value={tab} index={3}>
          <Box sx={{ width: "100%" }}>
            <Integrations />
          </Box>
        </CustomTabPanel>
        {isActionAllowed({ authorRole: profileRole }) && (
          <CustomTabPanel value={tab} index={4}>
            <Box sx={{ width: "100%" }}>
              <ApiKeys />
            </Box>
          </CustomTabPanel>
        )}
      </Box>
    </MainWrapper>
  );
};

export default PersonalInformation;
