import { Column } from "./types";

export const columns: Column[] = [
    { id: 'description', label: 'Display Name', minWidth: 100 },
    { id: 'name', label: 'Merge Tag', minWidth: 100 },
    { id: 'type', label: 'Type', minWidth: 100},
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 20,
      align: 'center',
    },
  ];

export const itemsPerPage = 25;

 