import {DeleteIntegrationApiParams, AddIntegrationApiParams, UpdateIntegrationApiParams, GetIntegrationsApiParams } from './types';
import { useCallback } from 'react';
import apiClient from '../axiosClient';

/**
 * @function useGetIntegrationsApi
 * @returns { GetIntegrationsApiParams }
 */
export function useGetIntegrationsApi(): GetIntegrationsApiParams {
  return useCallback((params) => apiClient.get(`/hooks`, {params}), []);
}

// /**
//  * @function useGetIntegrationApi
//  * @returns { GetIntegrationApiParams }
//  */
// export function useGetIntegrationApi(): GetIntegrationApiParams {
//   return useCallback((integrationId, companyId) => apiClient.get(`/hooks/${integrationId}`, {params: {companyId}}), []);
// }

/**
 * @function useDeleteIntegrationApi
 * @returns { DeleteIntegrationApiParams }
 */
export function useDeleteIntegrationApi(): DeleteIntegrationApiParams {
  return useCallback((integrationId, companyId) => apiClient.delete(`/hooks/${integrationId}`, {params: {companyId}}), []);
}

/**
 * @function useInviteIntegrationApi
 * @returns { InviteIntegrationApiParams }
 */
export function useAddIntegrationApi(): AddIntegrationApiParams {
  return useCallback((params, companyId) => apiClient.post(`/hooks`, params, {params: {companyId}}), []);
}

/**
 * @function useUpdateIntegrationRoleApi
 * @returns { UpdateIntegrationRoleApiParams }
 */
export function useUpdateIntegrationApi(): UpdateIntegrationApiParams {
  return useCallback((params, integrationId, companyId) => apiClient.put(`/hooks/${integrationId}`, params, {params: {companyId}}), []);
}
