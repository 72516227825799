import MainWrapper from "src/components/MainWrapper/view";
import React, { useEffect, useState } from "react";
import { Tabs, Tab, TextField, Button, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectProfile } from "src/store/profile/profileSlice";
import InputAdornment from "@mui/material/InputAdornment";
import {
  ValidationErrors,
  updateUserValidationSchema,
} from "src/constants/validations";
import * as Yup from "yup";
import { useUpdatePasswordApi, useUpdateUserApi } from "src/api/profile";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const getProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const PersonalInformation = () => {
  const { profile } = useSelector(selectProfile);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formValues, setFormValues] = useState({
    firstName: profile?.firstName || "",
    lastName: profile?.lastName || "",
    newPassword: "",
    currentPassword: "",
    confirmPassword: "",
    phoneNumber: "",
  });
  const updateUserApi = useUpdateUserApi();
  const updatePasswordApi = useUpdatePasswordApi();
 
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isProfileFormDirty, setIsProfileFormDirty] = useState(false);
  const [tab, setTab] = useState(0);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handlePasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setIsProfileFormDirty(true);
  };

  const validate = async (values: typeof formValues) => {
    try {
      await updateUserValidationSchema.validate(values, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const handlePasswordEditClick = async () => {
    if (formValues.currentPassword) {
      if (formValues.newPassword && formValues.newPassword.length < 8) {
        setErrors({
          ...errors,
          newPassword: "Password must be at least 8 characters long.",
        });
      } else if (!formValues.newPassword) {
        setErrors({ ...errors, newPassword: "Password is required." });
      } else if (formValues.newPassword !== formValues.confirmPassword) {
        setErrors({...errors, confirmPassword: "New password and confirm password are not match"})
      } else {
        profile?.id &&
          updatePasswordApi(profile.id, {
            currentPassword: formValues.currentPassword,
            password: formValues.newPassword,
          })
            .then(() => {
              setFormValues({
                ...formValues,
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
              });
            })
            .catch((e) => {
              if (e.error === "validation") {
                setErrors({ currentPassword: "Invalid password" });
              }
            });
      }
    }
  }

  const handleEditClick = async () => {
    const isValid = await validate({
      ...formValues,
      phoneNumber: formValues.phoneNumber.replace("+1", ""),
    });
    if (isValid) {
      const { phoneNumber, firstName, lastName } = formValues;
      profile?.id &&
        updateUserApi(profile.id, {
          phoneNumber: phoneNumber ? "+1" + phoneNumber : "",
          firstName,
          lastName,
        }).then(() => {
          setIsProfileFormDirty(false);
        });
    }
  };

  const isPasswordButtonDisabled = (): boolean => {
    const { currentPassword, newPassword, confirmPassword } = formValues;
    return !(currentPassword.length > 0 && newPassword.length > 0 && confirmPassword.length > 0);
  }

  useEffect(() => {
    setFormValues({
      ...formValues,
      firstName: profile?.firstName || "",
      lastName: profile?.lastName || "",
      phoneNumber: profile?.phoneNumber
        ? profile?.phoneNumber.replace("+1", "")
        : "",
    });
  }, [profile]);

  return (
    <MainWrapper>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="personal information tab"
        >
          <Tab label="My Profile" {...getProps(0)} />
          <Tab label="Password" {...getProps(1)} />
        </Tabs>
        <CustomTabPanel value={tab} index={0}>
          <Box sx={{ width: "300px", marginTop: "40px" }}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={formValues.firstName}
              style={{ marginBottom: "15px" }}
              onChange={handleInputChange}
              autoComplete="false"
              error={!!errors?.firstName}
              helperText={errors?.firstName || ""}
            />
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              type="name"
              value={formValues.lastName}
              style={{ marginBottom: "15px" }}
              onChange={handleInputChange}
              error={!!errors?.lastName}
              helperText={errors?.lastName || ""}
            />
            <TextField
              id="outlined-basic"
              style={{ marginBottom: "15px", width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+1</InputAdornment>
                ),
              }}
              label="Mobile Number"
              name="phoneNumber"
              type="number"
              variant="outlined"
              value={formValues.phoneNumber}
              onChange={handleInputChange}
              error={!!errors?.phoneNumber}
              helperText={errors?.phoneNumber || ""}
            />

            <Button
              onClick={handleEditClick}
              variant="contained"
              disabled={!isProfileFormDirty}
              color="primary"
              sx={{ mt: 2 }}
            >
              Save
            </Button>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <Box sx={{ width: "300px", marginTop: "40px" }}>
            <FormControl
              variant="outlined"
              style={{ marginBottom: "18px", display: "block" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                style={errors?.currentPassword ? { color: "#d32f2f" } : {}}
              >
                Current Password
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="outlined-adornment-password"
                type={showCurrentPassword ? "text" : "password"}
                value={formValues.currentPassword}
                onChange={handlePasswordInputChange}
                name="currentPassword"
                error={!!errors?.currentPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Current Password"
              />
              <FormHelperText
                id="component-error-text"
                style={{ color: "#d32f2f" }}
              >
                {errors.currentPassword || ""}
              </FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              style={{ marginBottom: "18px", display: "block" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                style={errors?.newPassword ? { color: "#d32f2f" } : {}}
              >
                New Password
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="outlined-adornment-password"
                type={showNewPassword ? "text" : "password"}
                value={formValues.newPassword}
                onChange={handlePasswordInputChange}
                name="newPassword"
                error={!!errors?.newPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
              <FormHelperText
                id="component-error-text"
                style={{ color: "#d32f2f" }}
              >
                {errors.newPassword || ""}
              </FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              style={{ marginBottom: "18px", display: "block" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                style={errors?.confirmPassword ? { color: "#d32f2f" } : {}}
              >
                Confirm Password
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="outlined-adornment-password"
                type={showConfirmPassword ? "text" : "password"}
                value={formValues.confirmPassword}
                onChange={handlePasswordInputChange}
                name="confirmPassword"
                error={!!errors?.confirmPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
              <FormHelperText
                id="component-error-text"
                style={{ color: "#d32f2f" }}
              >
                {errors.confirmPassword || ""}
              </FormHelperText>
            </FormControl>
            <Button
              onClick={handlePasswordEditClick}
              disabled={isPasswordButtonDisabled()}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Change Password
            </Button>
          </Box>
        </CustomTabPanel>
      </Box>
    </MainWrapper>
  );
};

export default PersonalInformation;
