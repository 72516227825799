import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MainWrapper from "src/components/MainWrapper/view"
import { selectProfile } from 'src/store/profile/profileSlice';
import AccessDenied from '../AccessDenied';
import { Tabs, Tab, Box } from "@mui/material";
import BhiTable from 'src/components/Table/view';
import { useGetAdminCompaniesApi } from 'src/api/adminCompanies';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const Companies = () => {
  const [tab, setTab] = useState(0);
  const { profile } = useSelector(selectProfile);
  const getCompanies = useGetAdminCompaniesApi();

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const getProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const isBhiAdmin = profile?.type === "admin";

  if (!isBhiAdmin) {
    return <AccessDenied />
  }

  return (
    <MainWrapper>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="companies tab"
        >
          <Tab label="Companies" {...getProps(0)} />
        </Tabs>
        <CustomTabPanel value={tab} index={0}>
          <Box sx={{ width: "100%" }}>
            <BhiTable fetchData={getCompanies} pageSize={100} />
          </Box>
        </CustomTabPanel>     
      </Box>
    </MainWrapper>
  )
}

export default Companies;