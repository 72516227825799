import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Menu,
  SelectChangeEvent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import FileUploaderInput from "src/components/FileUploaderInput/view";
import { Message, MessageType } from "../types";
import dayjs from "dayjs";

import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { DefaultCustomField, FileLinkWithMimeType } from "src/constants/types";
import HighlightWithinTextarea from "react-highlight-within-textarea";

export type MessageProps = {
  metadata: DefaultCustomField[] | undefined;
  prevMessage: Message | undefined;
  nextMessage: Message | undefined;
  campaignDateScheduled: string | null | undefined;
  dateScheduled: string | null;
  message: Message;
  updateMessage: React.Dispatch<React.SetStateAction<Message>>;
};

export default function MessageForm({
  message,
  prevMessage,
  nextMessage,
  campaignDateScheduled,
  metadata,
  updateMessage,
}: MessageProps) {
  const [messageValues, setMessageValues] = useState<Message>(message);
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const [openEmojiModal, setOpenEmojiModal] = useState<boolean>(false);
  const [delayPeriod, setDelayPeriod] = useState<string>("m");
  const [delayValue, setDelayValue] = useState<string>(
    message.scheduledType === "delay" && message.delay
      ? message.delay.slice(0, -1)
      : ""
  );

  // const [metadataListAnchorEl, setMetadataListAnchorEl] =
  //   React.useState<null | HTMLElement>(null);
  const [openMetadata, setOpenMetadata] = useState<boolean | null>(null);
  const handleMetadataMenuClose = () => {
    //setMetadataListAnchorEl(null);
    setOpenMetadata(false);
  };

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (messageValues.scheduledType === "delay" && messageValues.delay) {
      setDelayPeriod(messageValues.delay[messageValues.delay?.length - 1]);
    }

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setMessageValues({ ...message });
    }
  }, [message.id]);

  useEffect(() => {
    if (isLoaded) {
      updateMessage({ ...messageValues });
    }
  }, [messageValues]);

  const isShowMetadataMenu = (inputElement: HTMLTextAreaElement) => {
    const value = inputElement.value;
    const caretPosition = inputElement.selectionStart;
    const lastChars = value.substring(caretPosition - 2, caretPosition);

    return lastChars;
  };

  const isShowEmodjiMenu = (inputElement: HTMLTextAreaElement) => {
    const value = inputElement.value;
    const caretPosition = inputElement.selectionStart;
    if (value && value.length === 1 && value === ':') {
      return true;
    }
    if (value.substring(caretPosition - 2, caretPosition) === ' :') {
      return true;
    }
    return false;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "scheduledType") {
      if (value === "time") {
        setMessageValues({
          ...messageValues,
          scheduledType: value,
          delay: null,
        });
      }
      if (value === "delay") {
        setMessageValues({
          ...messageValues,
          scheduledType: value,
          time: null,
        });
      }
      if (value === "immediate") {
        setMessageValues({
          ...messageValues,
          scheduledType: value,
          time: null,
          delay: null,
        });
      }
    } else {
      if (
        name === "text" &&
        messageInputRef.current &&
        messageValues.text.length < value.length
      ) {
        if (isShowMetadataMenu(messageInputRef.current) === "{{") {
          setOpenMetadata(true);

          if (messageInputRef.current) {
            messageInputRef.current.setSelectionRange(
              messageInputRef.current?.selectionStart,
              messageInputRef.current?.selectionStart + 1
            );
            messageInputRef.current.focus();
          }
        } else {
          setOpenMetadata(false);
        }

        if (isShowEmodjiMenu(messageInputRef.current)) {
          setOpenEmojiModal(true);
          messageInputRef.current.focus();
        } else {
          setOpenEmojiModal(false);
        }
      }

      setMessageValues({ ...messageValues, [name]: value });
    }
  };

  const insertMetadata = (name: string) => {
    const start = messageInputRef.current?.value.substring(
      0,
      messageInputRef.current.selectionStart
    );
    const end = messageInputRef.current?.value.substring(
      messageInputRef.current.selectionStart
    );
    const text =
      (start?.endsWith("{{")
        ? messageInputRef.current?.value.substring(
            0,
            messageInputRef.current.selectionStart - 2
          )
        : start) +
      `{{` +
      name +
      `}}` +
      end;
    setMessageValues({
      ...messageValues,
      text: text,
    });

    if (messageInputRef.current) {
      messageInputRef.current.setSelectionRange(
        messageInputRef.current?.selectionStart,
        messageInputRef.current?.selectionStart + 1
      );
    }
    messageInputRef.current?.focus();
    setOpenMetadata(false);
  };

  const handleLinksUpdate = (links: FileLinkWithMimeType[]) => {
    setMessageValues({
      ...messageValues,
      links: links.map((x) => x.url),
      linksWithMimeType: links,
    });
    updateMessage({ ...messageValues });
  };

  const handleMessageTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    messageType: string
  ) => {
    setMessageValues({ ...messageValues, type: messageType as MessageType });
  };

  const handleSchedulePeriodChange = ({
    target,
  }: SelectChangeEvent<string>) => {
    setDelayPeriod(target.value);
    setMessageValues({
      ...messageValues,
      time: null,
      delay: `${delayValue}${target.value}`,
    });
    updateMessage({
      ...messageValues,
      time: null,
      delay: `${delayValue}${target.value}`,
    });
  };

  const handleDelayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (value && +value > 0) {
      setDelayValue(value);
      setMessageValues({
        ...messageValues,
        time: null,
        [name]: `${value}${delayPeriod}`,
      });
    }
  };

  const handleTimeSave = async (date: dayjs.Dayjs | null) => {
    setMessageValues({
      ...messageValues,
      delay: null,
      time: dayjs(date).toDate().toISOString(),
    });
  };

  return (
    <Fragment>
      {message.position > 1 && (
        <FormControl
          fullWidth
          style={{
            flexDirection: "row",
            alignItems: "center",
            margin: "0 0",
            marginBottom: "10px",
          }}
        >
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={message.type}
            exclusive
            onChange={handleMessageTypeChange}
            aria-label="messageType"
          >
            <ToggleButton value="sms">SMS</ToggleButton>
            <ToggleButton value="mms">MMS</ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      )}
      {message.position > 0 && message.type === "mms" && (
        <FileUploaderInput
          key={message.id}
          links={message.linksWithMimeType as FileLinkWithMimeType[]}
          updateLinks={handleLinksUpdate}
        />
      )}
      {message.position > 1 && message.type === "mms" && (
        <>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Subject (optional)"
            name="subject"
            variant="outlined"
            value={message.subject}
            onChange={handleChange}
            style={{ marginTop: "20px" }}
          />
          <FormHelperText id="outlined-weight-helper-text">
            Different devices and applications display the subject of an MMS in
            different ways. Avoid including critical information in the subject.
          </FormHelperText>
        </>
      )}
      {message.position > 1 && (
        <Stack direction="column">
          {openEmojiModal && (
            <ClickAwayListener
              onClickAway={() => {
                setOpenEmojiModal(false);
              }}
            >
              <Box
                sx={{ position: "absolute", bottom: "120px", right: "-350px" }}
              >
                <EmojiPicker
                  style={{ zIndex: 999999 }}
                  autoFocusSearch={false}
                  onEmojiClick={(e: EmojiClickData) => {
                    const emoji = e.emoji;
                    const start = messageInputRef.current?.value.substring(
                      0,
                      messageInputRef.current.selectionStart
                    );
                    const end = messageInputRef.current?.value.substring(
                      messageInputRef.current.selectionStart
                    );
                    const text =
                      (start?.endsWith(":")
                        ? messageInputRef.current?.value.substring(
                            0,
                            messageInputRef.current.selectionStart - 1
                          )
                        : start) +
                      emoji +
                      end;

                    setMessageValues({
                      ...messageValues,
                      text: text,
                    });

                    if (messageInputRef.current) {
                      messageInputRef.current.setSelectionRange(
                        messageInputRef.current?.selectionStart,
                        messageInputRef.current?.selectionStart + 1
                      );
                    }
                    messageInputRef.current?.focus();
                    setOpenEmojiModal(false);
                  }}
                />
              </Box>
            </ClickAwayListener>
          )}
          <Box sx={{ position: "relative" }}>
            <Box
              style={{
                position: "absolute",
                width: "100%",
                top: "27px",
                paddingLeft: 0,
                outline: 0,
                marginLeft: "10px",
                marginRight: "10px",
              }}
              sx={{
                "& .DraftEditor-root": {
                  color: "transparent",
                  lineHeight: "23px",
                  wordWrap: "false",
                  height: "100%",
                  marginLeft: "2px",
                },
                "& mark": {
                  color: "transparent",
                  backgroundColor: "lightgray",
                },
              }}
            >
              <HighlightWithinTextarea
                placeholder=""
                value={messageValues.text}
                highlight={metadata?.map((m) => "{{" + m.name + "}}")}
              />
            </Box>
            <TextField
              inputRef={messageInputRef}
              fullWidth
              id="outlined-multiline-static"
              name="text"
              value={messageValues.text}
              multiline
              minRows={4}
              style={{ margin: "10px 0px" }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "transparent",
                  maxHeight: "45%",
                },
              }}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <>
                    {metadata && metadata.length && (
                      <>
                        <ClickAwayListener
                          onClickAway={() => {
                            handleMetadataMenuClose();
                          }}
                        >
                          <>
                            <Button
                              sx={{
                                minWidth: "10px",
                                padding: 0,
                                width: "10px",
                                margin: 0,
                                position: "absolute",
                                right: "40px",
                                bottom: "15px",
                              }}
                              style={{
                                backgroundColor: "transparent",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOpenMetadata(true);
                              }}
                            >
                              <LocalOfferOutlinedIcon />
                            </Button>

                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={messageInputRef.current}
                              open={!!openMetadata}
                              onClose={handleMetadataMenuClose}
                              slotProps={{
                                paper: {
                                  style: {
                                    maxHeight: 48 * 4.5,
                                  },
                                },
                              }}
                            >
                              {metadata.map((m) => (
                                <MenuItem
                                  key={m.name}
                                  onClick={() => {
                                    insertMetadata(m.name);
                                    handleMetadataMenuClose();
                                  }}
                                >
                                  {m.name.startsWith("contact")
                                    ? "Contact: "
                                    : "Company: "}
                                  {m.description}
                                </MenuItem>
                              ))}
                            </Menu>
                          </>
                        </ClickAwayListener>
                      </>
                    )}
                    <Button
                      sx={{
                        minWidth: "10px",
                        padding: 0,
                        width: "10px",
                        margin: 0,
                        position: "absolute",
                        right: "10px",
                        bottom: "5px",
                      }}
                      style={{
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenEmojiModal(true);
                      }}
                    >
                      <InsertEmoticonIcon />
                    </Button>
                  </>
                ),
              }}
            />
          </Box>
        </Stack>
      )}
      <FormControl />
      <FormControl
        fullWidth
        style={{
          flexDirection: "row",
          alignItems: "center",
          margin: "0 0px",
        }}
      >
        <FormLabel style={{ color: "#000" }}>Schedule:</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={message.scheduledType}
          value={message.scheduledType}
          name="scheduledType"
          style={{ flexDirection: "row", marginLeft: "10px" }}
          onChange={handleChange}
          defaultChecked={true}
        >
          <FormControlLabel
            value="immediate"
            control={<Radio />}
            label="Immediately"
          />
          {message.position > 1 && (
            <FormControlLabel
              value="delay"
              control={<Radio />}
              label="After Delay"
            />
          )}
          <FormControlLabel
            value="time"
            control={<Radio />}
            label="Specific Date/Time"
          />
        </RadioGroup>
      </FormControl>
      {message.scheduledType === "delay" && (
        <Box
          display={"flex"}
          sx={{
            flexDirection: "column",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Box display={"flex"}>
            <TextField
              sx={{ marginRight: "10px" }}
              id="outlined-basic"
              label="Period"
              name="delay"
              size="small"
              type="number"
              inputProps={{ min: 1 }}
              variant="outlined"
              value={delayValue}
              onChange={handleDelayChange}
            />
            <Select
              MenuProps={{ disableScrollLock: true }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              value={delayPeriod}
              onChange={handleSchedulePeriodChange}
              name="scheduledType"
            >
              <MenuItem value={"m"} key={"minutes"}>
                Minutes
              </MenuItem>
              <MenuItem value={"h"} key={"hours"}>
                Hours
              </MenuItem>
              <MenuItem value={"d"} key={"days"}>
                Days
              </MenuItem>
            </Select>
          </Box>
        </Box>
      )}
      {message.scheduledType === "time" && (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <DateTimePicker
                minTime={
                  message.position > 1
                    ? prevMessage?.time
                      ? dayjs(prevMessage.time).add(5, "minutes")
                      : campaignDateScheduled
                      ? dayjs(campaignDateScheduled).add(5, "minutes")
                      : null
                    : null
                }
                minDate={
                  message.position > 1
                    ? prevMessage?.time
                      ? dayjs(prevMessage.time)
                      : campaignDateScheduled
                      ? dayjs(campaignDateScheduled)
                      : null
                    : null
                }
                maxDate={nextMessage ? dayjs(nextMessage.time) : null}
                maxTime={
                  nextMessage
                    ? dayjs(nextMessage.time).subtract(5, "minutes")
                    : null
                }
                format="YYYY-MM-DD hh:mm A"
                label="Date and Time Scheduled"
                disablePast
                closeOnSelect={false}
                onChange={(value) => handleTimeSave(value)}
                defaultValue={
                  message.position === 1 && campaignDateScheduled
                    ? dayjs(campaignDateScheduled)
                    : message.time
                    ? dayjs(message.time)
                    : prevMessage?.time
                    ? dayjs(prevMessage.time).add(5, "minutes")
                    : null
                }
              />
            </DemoContainer>
          </LocalizationProvider>
        </>
      )}
    </Fragment>
  );
}
