import { Column } from "./types";

export const columns: Column[] = [
    { id: 'name', label: 'Campaign', minWidth: 150 },
    { id: 'type', label: 'Type', minWidth: 150 },
    { id: 'createdAt', label: 'Created At', minWidth: 150 },
    { id: 'updatedAt', label: 'Updated At', minWidth: 150 },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 150,
      align: 'center',
    },
  ];

export const itemsPerPage = 25;