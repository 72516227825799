import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { ContentContainer } from "./styles";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import {
  addAdminCompanyValidationSchema,
  editAdminCompanyValidationSchema,
  ValidationErrors,
} from "src/constants/validations";
import { AdminCompany } from "src/constants/types";
import * as Yup from "yup";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Stack,
} from "@mui/material";
import {
  useCreateAdminCompanyApi,
  useCreateAdminCompanyPhoneNumberApi,
  useDeleteAdminCompanyPhoneNumberApi,
  useGetAdminCompanyPhoneNumbersApi,
  useUpdateAdminCompanyPhoneNumberApi,
  useUpdatedAdminCompanyApi,
} from "src/api/adminCompanies";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "700px",
    height: "700px",
  },
}));

type CreateCompanyModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  initialCompany: AdminCompany;
  adminCompany: AdminCompany;
  refreshCompanies: () => void;
};

export default function CreateAdminCompanyModal({
  show,
  setShowModal,
  initialCompany,
  adminCompany,
  refreshCompanies,
}: CreateCompanyModalType) {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const createAdminCompany = useCreateAdminCompanyApi();
  const updateAdminCompany = useUpdatedAdminCompanyApi();
  const getAdminCompanyPhoneNumbers = useGetAdminCompanyPhoneNumbersApi();
  const createAdminCompanyPhoneNumbers = useCreateAdminCompanyPhoneNumberApi();
  const deleteAdminCompanyPhoneNumbers = useDeleteAdminCompanyPhoneNumberApi();
  const updateAdminCompanyPhoneNumbers = useUpdateAdminCompanyPhoneNumberApi();

  const [formValues, setFormValues] = useState<AdminCompany>(adminCompany);
  const [phoneNumbers, setPhoneNumbers] = useState<
    { id: string; number: string; isDeleted?: boolean; isNew?: boolean }[]
  >(adminCompany.phoneNumbers);
  useEffect(() => {
    setFormValues(formValues.id ? formValues : adminCompany);
    if (adminCompany.id) {
      getAdminCompanyPhoneNumbers(adminCompany.id).then((response) => {
        setPhoneNumbers(response.data);
      });
    }
  }, [adminCompany]);

  const handleClose = () => {
    setShowModal(false);
    setPhoneNumbers([]);
    setFormValues(initialCompany);
    setErrors({});
  };

  const handleIsMessagingProfile = (isMessagingProfile: boolean) => {
    setFormValues({ ...formValues, createMessageProfile: isMessagingProfile });
  };

  const handleAdminCompanySave = () => {
    const isValid = validate(formValues);
    if (isValid) {
      if (formValues.id) {
        updateAdminCompany(
          {
            name: formValues.name,
            messagingProfileId: formValues.messagingProfileId,
            tcrCampaignId: formValues.tcrCampaignId,
          },
          formValues.id
        )
          .then(() => {
            phoneNumbers.forEach((p) => {
              if (p.isDeleted) {
                if (!p.isNew) {
                  deleteAdminCompanyPhoneNumbers(formValues.id, p.id);
                }
              } else if (p.isNew && !p.isDeleted) {
                createAdminCompanyPhoneNumbers(
                  formValues.id,
                  p.number?.startsWith("+1") ? p.number : "+1" + p.number
                );
              } else if (p.id && !p.isNew && !p.isDeleted) {
                updateAdminCompanyPhoneNumbers(
                  formValues.id,
                  p.id,
                  p.number?.startsWith("+1") ? p.number : "+1" + p.number
                );
              }
            });
          })
          .then(() => {
            window.setTimeout(() => {
              refreshCompanies();
              handleClose();
            }, 400);
          });
      } else {
        createAdminCompany({
          name: formValues.name,
          createMessageProfile: !!formValues.createMessageProfile,
        })
          .then((response) => {
            setFormValues(response.data);
            refreshCompanies();
          })
          .catch(({ error }) => {
            if (error === "conflict") {
              setErrors({ name: "Entity with this name already exists" });
            }
            if (error.message && error.message.includes("already exists")) {
              setErrors({
                name: "Entity with this name already exists",
              });
            }
          });
      }
    }
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const modifiedPhoneNumbers = phoneNumbers.map((p) => {
      if (p.id === name) {
        p.number = value;
      }
      return p;
    });
    setPhoneNumbers(modifiedPhoneNumbers);
  };

  const handlePhoneNumberDelete = (id: string) => {
    const modifiedPhoneNumbers = phoneNumbers.map((p) => {
      if (p.id === id) {
        p.isDeleted = true;
      }
      return p;
    });
    setPhoneNumbers(modifiedPhoneNumbers);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFormValues((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addNewPhoneNumber = () => {
    const newPhoneNumbers = phoneNumbers.concat([
      {
        id: Date.now().toString(36) + Math.random().toString(36).substring(2),
        number: "",
        isDeleted: false,
        isNew: true,
      },
    ]);
    setPhoneNumbers(newPhoneNumbers);
  };

  const isValidForm = (values: typeof formValues) => {
    try {
      addAdminCompanyValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      return true;
    } catch {
      return false;
    }
  };

  const validate = (values: typeof formValues) => {
    try {
      if (adminCompany.id) {
        editAdminCompanyValidationSchema.validateSync(values, {
          abortEarly: false,
        });
      } else {
        addAdminCompanyValidationSchema.validateSync(values, {
          abortEarly: false,
        });
      }
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {formValues.id ? "Update " : "Create "}
          {"Company"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ContentContainer>
            <TextField
              id="outlined-basic"
              label={"Name"}
              name="name"
              variant="outlined"
              value={formValues.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors?.name || ""}
            />
            {!formValues.id && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValues.createMessageProfile}
                      onChange={(event) =>
                        handleIsMessagingProfile(event.target.checked)
                      }
                    />
                  }
                  label="Create a Messaging Profile for this company?"
                />
              </FormGroup>
            )}
            {formValues.id && (
              <Box sx={{ marginTop: "20px" }}>
                <TextField
                  sx={{ width: "49%", marginRight: "1%" }}
                  id="outlined-basic"
                  label={"Message Profile ID"}
                  name="messagingProfileId"
                  variant="outlined"
                  value={formValues.messagingProfileId}
                  onChange={handleChange}
                  error={!!errors.messagingProfileId}
                  helperText={errors?.messagingProfileId || ""}
                />
                <TextField
                  sx={{ width: "49%", marginLeft: "1%" }}
                  id="outlined-basic"
                  label={"TCR Campaign ID"}
                  name="tcrCampaignId"
                  variant="outlined"
                  value={formValues.tcrCampaignId}
                  onChange={handleChange}
                  error={!!errors.tcrCampaignId}
                  helperText={errors?.tcrCampaignId || ""}
                />
                {phoneNumbers && (
                  <Box sx={{ marginTop: "20px" }}>
                    {phoneNumbers
                      .filter((p) => !p.isDeleted)
                      .map((p) => (
                        <Box
                          key={p.id}
                          sx={{ width: "100%", marginTop: "20px" }}
                        >
                          <TextField
                            sx={{ width: "92%" }}
                            id="outlined-basic"
                            label={"Phone"}
                            name={p.id}
                            inputProps={{
                              pattern: "d*",
                              min: 10,
                              maxLength: 10,
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +1
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            value={p.number?.replace("+1", "")}
                            onChange={handlePhoneNumberChange}
                          />
                          <IconButton
                            onClick={() => {
                              handlePhoneNumberDelete(p.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      ))}
                  </Box>
                )}
              </Box>
            )}
            <Stack direction="row" justifyContent="space-between">
              {formValues.id && (
                <Button
                  size="small"
                  variant="contained"
                  style={{ width: "150px", marginTop: "20px" }}
                  onClick={addNewPhoneNumber}
                >
                  Add New Phone
                </Button>
              )}
              <Button
                size="small"
                disabled={!isValidForm(formValues)}
                variant="contained"
                style={{ width: "150px", marginTop: "20px" }}
                onClick={handleAdminCompanySave}
              >
                {formValues.id ? "Save" : "Create"}
              </Button>
            </Stack>
          </ContentContainer>
        </DialogContent>
      </BootstrapDialog>
    </Fragment>
  );
}
