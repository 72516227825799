import React from 'react';
import Header from 'src/components/Header/view';
import Sidebar from 'src/components/Sidebar/view';
import { Container, Body, Main } from './styles';

function MainWrapper ({children}: {children: React.ReactNode}) {
    return (
        <Container>
            <Sidebar />
            <Body>
                <Header />
                <Main>{children}</Main>
            </Body>
        </Container>
    );
}

export default MainWrapper;
