import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { Company } from "src/constants/types";

interface CompaniesState {
  list: Company[];
  isFetching: boolean | undefined;
  isError: boolean | undefined;
  selectedCompanyId?: string
}

const initialState: CompaniesState = {
  list: [],
  isFetching: undefined,
  selectedCompanyId: undefined,
  isError: undefined,
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanies: (state, action: PayloadAction<Company[]>) => {
      state.list = action.payload;
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
    setSelectedCompany: (state, action: PayloadAction<string>) => {
      state.selectedCompanyId = action.payload;
    },
  },
});

export const { setCompanies, setSelectedCompany, setIsFetching, setIsError } = companiesSlice.actions;

export const selectCompanies = (state: RootState) => state.companies.list;

export const isCompaniesFetching = (state: RootState) => state.companies.isFetching;

export const isCompaniesError = (state: RootState) => state.companies.isError;

export const selectedCompany = (state: RootState): string | undefined => {
  return state.companies.selectedCompanyId || (state.companies.list?.length > 0 ? state.companies.list[0].id : undefined);
};

export default companiesSlice.reducer;
