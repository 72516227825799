import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import permissionsReducer from './permission/permissionSlice';
import companiesReducer from './company/companySlice';
import profileReducer from './profile/profileSlice';
import roleReducer from './profile/roleSlice';
import inboxReducer from './inbox/inboxSlice';

const appReducer = combineReducers({
  permissions: permissionsReducer,
  companies: companiesReducer,
  profile: profileReducer,
  roles: roleReducer,
  inbox: inboxReducer,
});

type RootState = ReturnType<typeof appReducer>;

type RootAction = Action<'logout'>;

const rootReducer = (state: RootState | undefined, action: RootAction): RootState => {
  if (action.type === 'logout') {
    return appReducer(undefined, { type: 'init' });
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type { RootState };
export type AppDispatch = typeof store.dispatch;
