import React from "react";
import {
  PhoneWrapper,
  ChatContainer,
  ChatBubble,
  Screen,
} from "./styles";
import ChatFileIcon from "../ChatFileIcon/view";
import {FileLinkWithMimeType} from "../../constants/types";

interface IMessage {
  isUser: boolean;
  text: string;
  links?: string[];
  linksWithMimeType?: FileLinkWithMimeType[];
  subject?: string;
  position?: number;
}

const Phone = ({
  messages,
  optOutText,
}: {
  messages: IMessage[];
  optOutText: string;
}) => {
  return (
    <PhoneWrapper>
      <Screen>
        <ChatContainer>
          {messages.map((content, index) =>
            content.subject ||
            content.text ||
            (content.linksWithMimeType && content.linksWithMimeType.length > 0) ? (
              <ChatBubble key={index} isUser={content.isUser}>
                {content.linksWithMimeType?.map((item: FileLinkWithMimeType, index) => {
                  return <ChatFileIcon url={item.url} mimeType={item.mimeType} key={index}/>;
                })}
                <div style={{fontWeight: '600', marginBottom: '6px'}}>{content.subject}</div>
                {content.text}
                {!content.isUser && optOutText && content.position === 1 && (
                  <div style={{ marginTop: "15px" }}>{optOutText}</div>
                )}
              </ChatBubble>
            ) : null
          )}
        </ChatContainer>
      </Screen>
    </PhoneWrapper>
  );
};

export default Phone;
