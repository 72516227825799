import { GetInboxContactsApiParams, GetInboxContactChatApiParams, InboxMarkChatAsReadApiParams, InboxMarkMessageAsUnreadApiParams, AddInboxMessageApiParams } from './types';
import { useCallback } from 'react';
import apiClient from '../axiosClient';

export function useGetInboxContactsApi(): GetInboxContactsApiParams {
  return useCallback(params => apiClient.get(`/message-history/contacts`, {params}), []);
}

export function useGetInboxContactChatApi(): GetInboxContactChatApiParams {
  return useCallback((params) => apiClient.get(`/message-history/contact-chat`, { params }), []);
}

export function useInboxMarkChatAsReadApi(): InboxMarkChatAsReadApiParams {
  return useCallback((params, companyId) => apiClient.put(`/message-history/mark-read`, params, {params: { companyId }}), []);
}

export function useInboxMarkMessageAsUnreadApi(): InboxMarkMessageAsUnreadApiParams {
  return useCallback((params, companyId) => apiClient.put(`/message-history/mark-unread`, params, {params: { companyId }}), []);
}

export function useAddInboxMessageApi(): AddInboxMessageApiParams {
  return useCallback((params, companyId) => apiClient.post(`/message-history`, params, {params: { companyId }}), []);
}
