import React, { useState, useEffect, Fragment, useRef } from "react";
import MainWrapper from "src/components/MainWrapper/view";
import {
  PageHeader,
  HeaderContainer,
  ButtonContainer,
  CampaignIdCopy,
  TextCopyToClipboard,
} from "./styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import { columns, itemsPerPage } from "./constants";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { Campaign } from "src/constants/types";
import { useGetCampaignsApi } from "src/api/campaigns";
import { getDateTimeUSAFormat } from "src/constants/helpers";
import CreateCampaignModal from "./CreateCampaignModal/view";
import {
  useActivateCampaignApi,
  useDeactivateCampaignApi,
} from "../../api/campaigns";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import dayjs from "dayjs";
import SortIcon from "../../components/SortIcon/view";
import SearchInput from "../../components/SearchInput/view";
import { GetCampaignsParams } from "../../api/campaigns/types";

function Campaigns() {
  const [page, setPage] = useState(0);
  const tableRef = useRef<HTMLTableElement>(null);
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortDir, setSortDir] = useState<"asc" | "desc">("desc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showCreateCampaignModal, setCreateCampaignModal] = useState(false);
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  const [campaigns, setCampaigns] = useState<{
    count: number;
    items: Campaign[] | null;
  }>({ count: 0, items: null });
  const getCampaigns = useGetCampaignsApi();
  const activate = useActivateCampaignApi();
  const deactivate = useDeactivateCampaignApi();
  const { openDialog, ConfirmationDialog } = useConfirmationDialog();
  const [copiedId, setCopiedId] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };

  const formatTableRows = (campaigns: Campaign[]) => {
    return campaigns.map(
      ({
        id,
        name,
        type,
        externalType,
        status,
        createdAt,
        updatedAt,
        dateSend,
        messageType,
        dateScheduled,
        scheduledMessages,
      }: Campaign) => {
        return {
          id,
          name,
          messageType,
          type:
            externalType === "flyer"
              ? "Text Flyer"
              : type === "message"
              ? "Bulk Message"
              : "Text-To-Join",
          actions: "edit/delete",
          status,
          createdAt: getDateTimeUSAFormat(new Date(createdAt)),
          updatedAt: getDateTimeUSAFormat(new Date(updatedAt)),
          dateSend,
          dateScheduled: dateScheduled,
          scheduledMessages,
        };
      }
    );
  };

  const mapSortPropsToApi: Record<string, string> = {
    name: "name",
    type: "type",
    status: "status",
    createdAt: "date_created",
    updatedAt: "date_updated",
  };

  const isFieldSortable = (fieldName: string) =>
    Object.prototype.hasOwnProperty.call(mapSortPropsToApi, fieldName);

  const getSortFieldForApi = (fieldName: string) => {
    return mapSortPropsToApi[fieldName];
  };

  const swapSortDirection = (dir?: "asc" | "desc") => {
    if (dir) {
      setSortDir(dir);
    } else {
      setSortDir(sortDir == "asc" ? "desc" : "asc");
    }
  };

  const changeSortField = (fieldName: string) => {
    if (sortField == fieldName) {
      swapSortDirection();
    } else {
      swapSortDirection("desc");
    }
    setSortField(fieldName);
  };

  const handleSearchQuery = (text: string) => {
    setSearchQuery(text);
  };

  const refreshCampaigns = () => {
    if (selectedCompanyId) {
      const reqQuery: GetCampaignsParams = {
        companyId: selectedCompanyId,
        limit: itemsPerPage,
        offset: page * itemsPerPage,
        sort: `${getSortFieldForApi(sortField)}_${sortDir}`,
      };
      if (searchQuery) {
        reqQuery.query = searchQuery;
      }
      getCampaigns(reqQuery)
        .then(({ data }) => {
          setCampaigns(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const copyCampaignIdToClipboard = (id: string) => {
    navigator.clipboard.writeText(id).then(() => {
      setCopiedId(id);
      setTimeout(() => setCopiedId(undefined), 400);
    });
  };

  useEffect(() => {
    refreshCampaigns();
  }, [page, selectedCompanyId, sortDir, sortField, searchQuery]);

  useEffect(() => {
    setPage(0);
    tableRef?.current?.scrollIntoView();
  }, [sortDir, sortField, searchQuery]); 

  const handleCampaignEdit = (id: string) => {
    const campaign = campaigns.items?.find((item) => item.id === id);
    // const isMultipleMessagingCampaign = campaign?.scheduledMessages
    //   ? campaign.scheduledMessages.length > 1
    //   : false;
    if (campaign && campaign.externalType === "flyer") {
      navigate(`/campaigns/text-flyer/${campaign.id}`);
      return;
    }

    campaign && navigate(`/campaigns/multiple-${campaign.type}/${campaign.id}`);
  };

  const handleChangeCampaignStatus = (id: string, action: string) => {
    if (action === "activate") {
      selectedCompanyId &&
        activate([id], selectedCompanyId).then(refreshCampaigns);
    } else if (action === "deactivate") {
      selectedCompanyId &&
        deactivate([id], selectedCompanyId).then(refreshCampaigns);
    }
  };

  return (
    <MainWrapper>
      <PageHeader>Campaigns</PageHeader>
      <HeaderContainer>
        <SearchInput handleSearch={handleSearchQuery} />
        <Button
          variant="contained"
          onClick={() => {
            setCreateCampaignModal(true);
          }}
        >
          Create Campaign
        </Button>
      </HeaderContainer>
      <CreateCampaignModal
        show={showCreateCampaignModal}
        setShowModal={setCreateCampaignModal}
      />

      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer  sx={{ height: "calc(100vh - 250px)" }}>
          <Table ref={tableRef} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    onClick={() =>
                      isFieldSortable(column.id)
                        ? changeSortField(column.id)
                        : {}
                    }
                    width={
                      column.id === "name"
                        ? "40%"
                        : column.id === "type"
                        ? "20%"
                        : "15%"
                    }
                    align={column.align}
                    style={{
                      background: "#254597",
                      color: "#fff",
                      cursor: isFieldSortable(column.id) ? 'pointer' : 'initial',
                    }}
                  >
                    {column.label}
                    {sortField == column.id && <SortIcon dir={sortDir} />}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {campaigns?.items &&
                formatTableRows(campaigns?.items).map((row, index) => {
                  const messageTypes = new Set(
                    row.scheduledMessages.map((m) => m.type)
                  );
                  const isMmsAndSms = messageTypes.size > 1;
                  const isSms =
                    messageTypes.size === 1 && [...messageTypes][0] === "sms";
                  const isMms =
                    messageTypes.size === 1 && [...messageTypes][0] === "mms";
                  return (
                    <TableRow key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        const cellWidth =
                          column.id === "name"
                            ? "40%"
                            : column.id === "type"
                            ? "20%"
                            : "15%";
                        return (
                          <TableCell
                            width={cellWidth}
                            key={column.id}
                            align={column.align}
                          >
                            {column.id === "actions" ? (
                              <Tooltip
                                placement="top"
                                arrow
                                key={index}
                                slotProps={{
                                  popper: {
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, -10],
                                        },
                                      },
                                    ],
                                  },
                                }}
                                title={
                                  (row.type === "Bulk Message" ||
                                    row.type === "Text Flyer") &&
                                  (!!row.dateSend ||
                                    (row.dateScheduled &&
                                      dayjs(row.dateScheduled) < dayjs()))
                                    ? "This campaign has ended is unable to be adjusted."
                                    : ""
                                }
                              >
                                <ButtonContainer>
                                  <Button
                                    // TODO: remove this check after mss integration
                                    disabled={
                                      (row.type === "Bulk Message" ||
                                        row.type === "Text Flyer") &&
                                      (!!row.dateSend ||
                                        !row.dateScheduled ||
                                        (!!row.dateScheduled &&
                                          dayjs(row.dateScheduled) < dayjs()))
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCampaignEdit(row.id);
                                    }}
                                  >
                                    <EditIcon />
                                  </Button>
                                  <Switch
                                    checked={row.status === "active"}
                                    disabled={
                                      (row.type === "Bulk Message" ||
                                        row.type === "Text Flyer") &&
                                      (!!row.dateSend ||
                                        !row.dateScheduled ||
                                        (!!row.dateScheduled &&
                                          dayjs(row.dateScheduled) < dayjs()))
                                    }
                                    onChange={() => {
                                      if (row.status === "active") {
                                        openDialog({
                                          title: `Deactivate "${row.name}"`,
                                          content:
                                            "Are you sure you want to deactivate this campaign?",
                                          onConfirm: () => {
                                            handleChangeCampaignStatus(
                                              row.id,
                                              "deactivate"
                                            );
                                          },
                                        });
                                      } else {
                                        openDialog({
                                          title: `Activate "${row.name}"`,
                                          content:
                                            "Are you sure you want to activate this campaign? If keywords in this campaign are already part of another active campaign, this may cause a bad experience for the recipient. Are you sure you want to continue?",
                                          onConfirm: () => {
                                            handleChangeCampaignStatus(
                                              row.id,
                                              "activate"
                                            );
                                          },
                                        });
                                      }
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                </ButtonContainer>
                              </Tooltip>
                            ) : column.id === "name" ? (
                              <>
                                <div>
                                  {` ${value} `}
                                  {isMmsAndSms && (
                                    <Fragment>
                                      <Chip
                                        style={{
                                          fontSize: "0.875rem",
                                          height: "initial",
                                        }}
                                        color="success"
                                        label="SMS"
                                        size="small"
                                        variant="outlined"
                                      />
                                      <Chip
                                        style={{
                                          fontSize: "0.875rem",
                                          height: "initial",
                                          marginLeft: "5px",
                                        }}
                                        color="primary"
                                        label="MMS"
                                        size="small"
                                        variant="outlined"
                                      />
                                    </Fragment>
                                  )}
                                  {isSms && (
                                    <Chip
                                      style={{
                                        fontSize: "0.875rem",
                                        height: "initial",
                                      }}
                                      color="success"
                                      label="SMS"
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                  {isMms && (
                                    <Chip
                                      style={{
                                        fontSize: "0.875rem",
                                        height: "initial",
                                      }}
                                      color="primary"
                                      label="MMS"
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                </div>
                                <CampaignIdCopy
                                  onClick={() =>
                                    copyCampaignIdToClipboard(row["id"])
                                  }
                                >
                                  <small>{` ${row["id"]} `}</small>
                                  <TextCopyToClipboard
                                    className={
                                      row["id"] == copiedId ? "grow" : ""
                                    }
                                  >
                                    <ContentCopyIcon />
                                  </TextCopyToClipboard>
                                </CampaignIdCopy>
                              </>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={campaigns.count}
          rowsPerPageOptions={[-1]}
          rowsPerPage={itemsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
      <ConfirmationDialog />
    </MainWrapper>
  );
}

export default Campaigns;
