import { Role } from "src/constants/types";

const rolesHierarchy = [
  "bhi_admin",
  "company_admin",
  "company_manager",
  "company_user",
];

export const isActionAllowed = ({
  authorRole,
  userRole,
}: {
  authorRole: string;
  userRole?: string;
  action?: string;
}) => {
  if (rolesHierarchy.indexOf(authorRole) === 0) {
    return true;
  } else if (rolesHierarchy.indexOf(authorRole) === rolesHierarchy.length - 1) {
    return false;
  } else if (rolesHierarchy.indexOf(authorRole) && !userRole) {
    return true;
  } else if (
    userRole &&
    rolesHierarchy.indexOf(authorRole) < rolesHierarchy.indexOf(userRole)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isPageForbidden = () => {
  return !localStorage.getItem('a_token');
}; 

export const filterAllowedRoles = (authorRole: string, roles: Role[]) => {
  return roles
    .filter(
      (role) =>
        rolesHierarchy.indexOf(authorRole) < rolesHierarchy.indexOf(role.name)
    )
    .map((role) => role.id);
};

export const reorderRoles = (roles: Role[]) => {
  const cRoles: Role[] = [];
  rolesHierarchy.map((roleName) => {
    const item = roles.find((role) => role.name === roleName);
    item && cRoles.push(item);
  });
  return cRoles;
};
