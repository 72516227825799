import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { Role } from 'src/constants/types';

interface RolesState {
  roles: Role[];
}

const initialState: RolesState = {
  roles: [],
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
    },
    addRole: (state, action: PayloadAction<Role>) => {
      state.roles.push(action.payload);
    },
    removeRole: (state, action: PayloadAction<string>) => {
      state.roles = state.roles.filter(role => role.id !== action.payload);
    },
  },
});

export const { setRoles, addRole, removeRole } = rolesSlice.actions;

export const selectRoles = (state: RootState) => state.roles;

export default rolesSlice.reducer;
