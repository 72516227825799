import styled from 'styled-components';

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0 20px 20px 20px
`

export const Label = styled.div`
    margin-top: 10px;
`;

export const UrlContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    justify-content: flex-end;
`;

export const FileContainer = styled.div`
    width: 550px;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    justify-content: flex-end;
`;

export const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

export const focusedStyle = {
    borderColor: '#2196f3'
};

export const acceptStyle = {
    borderColor: '#00e676'
};

export const rejectStyle = {
    borderColor: '#ff1744'
};

export const Cont = styled.div`
    flex-direction: column
`;

export const ErrorMessage = styled.div`
    max-width: 100%;
    word-wrap: break-word;
    text-align: center;
    margin-top: 10px;
    color: #d32f2f;
`;