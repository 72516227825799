import React, { SetStateAction, forwardRef } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import {
  FileLinkWithMimeType,
  Group,
  InboxContact,
  InboxContactChat,
  InboxContactChatMessage,
} from "src/constants/types";
import ChatHeader from "./ChatHeader";
import Conversation from "./Conversation";
import ChatFooter from "./ChatFooter";
import ContactsSearch from "./ContactsSearch";

type InboxContactChatProps = {
  messages: InboxContactChat | null;
  contact: InboxContact | undefined;
  markMessageAsUnread: (message: InboxContactChatMessage) => void;
  addMessage: (text: string) => Promise<void>;
  group: Group | undefined;
  updateLinks: (link: FileLinkWithMimeType) => void;
  handleContactEdit: (contactId: string) => void;
  isChatLoading: boolean | undefined;
  isNewChat: boolean | undefined;
  refreshContacts: React.Dispatch<React.SetStateAction<void>>
  onSelectContact: React.Dispatch<React.SetStateAction<string | null>>;
  setIsChatLoading: React.Dispatch<SetStateAction<boolean | undefined>>;
};

const Chat = forwardRef(function Chat(
  {
    messages,
    contact,
    markMessageAsUnread,
    addMessage,
    updateLinks,
    isChatLoading,
    handleContactEdit,
    isNewChat,
    onSelectContact,
    setIsChatLoading,
    refreshContacts,
    group,
  }: InboxContactChatProps,
  ref
) {
  if (isNewChat) {
    return (
      <Stack
        direction="column"
        sx={{ boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)" }}
        height={"85vh"}
        maxHeight={"85vh"}
        width={"100%"}
        justifyContent="space-between"
        alignItems="center"
      >
        <ContactsSearch refreshContacts={refreshContacts} group={group} setIsChatLoading={setIsChatLoading} onSelectContact={onSelectContact} />
        <Box></Box>
      </Stack>
    );
  }

  if (contact === undefined) {
    return (
      <Stack
        sx={{ boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)" }}
        height={"85vh"}
        maxHeight={"85vh"}
        width={"100%"}
        justifyContent="center"
        alignItems="center"
      >
        {isChatLoading === true && <CircularProgress color="inherit" size={20} /> }
        {isChatLoading === undefined && <Box>Select Chat</Box>}
      </Stack>
    );
  }

  return (
    <Stack height={"100%"} minHeight={"85vh"} maxHeight={"85vh"} width={"100%"}>
      {isChatLoading && (
        <Stack
          width="100%"
          height={"85vh"}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="inherit" size={20} />
        </Stack>
      )}
      {!isChatLoading && (
        <>
          <ChatHeader handleContactEdit={handleContactEdit} contact={contact} />
          <Box
            ref={ref}
            width={"100%"}
            sx={{
              borderLeft: 0,
              position: "relative",
              flexGrow: 1,
              overflowY: "scroll",
              overflowX: "hidden",
              backgroundColor: "#F0F4FA",
              boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Box>
              <Conversation
                isChatLoading={isChatLoading}
                markMessageAsUnread={markMessageAsUnread}
                messages={messages}
              />
            </Box>
          </Box>
          <ChatFooter
            selectedContactObject={contact}
            updateLinks={updateLinks}
            addMessage={addMessage}
          />
        </>
      )}
    </Stack>
  );
});

export default Chat;
