import { Column } from "./types";

export const columns: Column[] = [
    { metadataId: null, id: 'name', label: 'Name', minWidth: 80, isSelected: true },
    { metadataId: null, id: 'phoneNumber', label: 'Phone Number', minWidth: 100, isSelected: true },
    { metadataId: null, id: 'email', label: 'Email', minWidth: 110, isSelected: true },
    { metadataId: null, id: 'status', label: 'Status', minWidth: 30, isSelected: true },
    { metadataId: null, id: 'birthdate', label: 'BirthDate', minWidth: 70, isSelected: true },
    { metadataId: null, id: 'timeZone', label: 'Timezone', minWidth: 100, isSelected: true },
    {
      metadataId: null, id: 'optInType',
      label: 'Opt In Type',
      minWidth: 70,
      isSelected: true,
    },
  ];
export const itemsPerPage = 25;

export const customFieldsLimit = 10000;

 