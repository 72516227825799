import { GetCustomFieldsApiParams, CreateCustomFieldApiParams, DeleteCustomFieldApiParams, UpdateCustomFieldApiParams, AddContactCustomFieldApiParams, DeleteContactCustomFieldApiParams, GetDefaultCustomFieldsValues, UpdateDefaultCustomFieldsValues, UpdateCustomCompanyFieldApiParams } from './types';
import { useCallback } from 'react';
import apiClient from '../axiosClient';

export function useGetCustomCompanyFieldsApi(): GetCustomFieldsApiParams {
  return useCallback(params => apiClient.get(`/company-metadata`, {params}), []);
}

export function useUpdateCustomCompanyFieldApi(): UpdateCustomCompanyFieldApiParams {
  return useCallback((params, id, companyId) => apiClient.put(`/company-metadata/company/${id}`, params, {params: {companyId}}), []);
}

export function useDeleteCompanyCustomFieldApi(): DeleteContactCustomFieldApiParams {
  return useCallback((metadataId, contactId) => apiClient.delete(`company-metadata/company/${contactId}`, {data: {metadataId}}), []);
}

export function useGetCustomFieldsApi(): GetCustomFieldsApiParams {
  return useCallback(params => apiClient.get(`/contact-metadata`, {params}), []);
}

export function useCreateCustomFieldApi(): CreateCustomFieldApiParams {
  return useCallback((params, companyId) => apiClient.post(`/contact-metadata`, params, {params: {companyId}}), []);
}


export function useUpdateCustomFieldApi(): UpdateCustomFieldApiParams {
  return useCallback((params, id, companyId) => apiClient.put(`/contact-metadata/${id}`, params, {params: {companyId}}), []);
}

export function useDeleteCustomFieldApi(): DeleteCustomFieldApiParams {
  return useCallback((id, companyId) => apiClient.delete(`/contact-metadata`, {params: {companyId}, data: {ids: [id]}}), []);
}

export function useAddContactCustomField(): AddContactCustomFieldApiParams {
  return useCallback((params, contactId) => apiClient.put(`contact-metadata/contact/${contactId}`, params), []);
}

export function useDeleteContactCustomField(): DeleteContactCustomFieldApiParams {
  return useCallback((metadataId, contactId) => apiClient.delete(`contact-metadata/contact/${contactId}`, {data: {metadataId}}), []);
}

export function useGetDefaultCustomFieldsValuesApi(): GetDefaultCustomFieldsValues {
  return useCallback(params => apiClient.get(`/templates/default-values`, {params}), []);
}

export function useUpdateDefaultCustomFieldsValues() : UpdateDefaultCustomFieldsValues {
  return useCallback((params, companyId) => apiClient.patch(`templates/default-values/`, params, {params: {companyId}}), []);
}
