import React, { useState, useMemo, useEffect, useRef } from "react";
import { HeaderContainer } from "./styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { columns, itemsPerPage } from "./constants";
import { User } from "src/constants/types";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { useDeleteUserApi, useGetUsersApi } from "src/api/users";
import InviteUserModal from "./InviteUserModal/view";
import { selectProfile } from "src/store/profile/profileSlice";
import { isActionAllowed } from "src/helpers/permissions";
import { GetUsersParams } from "../../../../api/users/types";
import SearchInput from "../../../../components/SearchInput/view";
import SortIcon from "../../../../components/SortIcon/view";

const UserManagment = () => {
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState<string>("lastName");
  const tableRef = useRef<HTMLTableElement>(null);
  const [sortDir, setSortDir] = useState<"asc" | "desc">("asc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showInviteUserModal, setInviteUserModal] = useState(false);
  const [users, setUsers] = useState<{ count: number; items: User[] | null }>({
    count: 0,
    items: null,
  });
  const [editableUser, setEditableUser] = useState<User | undefined>();
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  const deleteUser = useDeleteUserApi();
  const { profile } = useSelector(selectProfile);
  const profileRole = useMemo(
    () => (profile?.role ? profile.role.name : "bhi_admin"),
    [profile]
  );
  const handleUserEdit = (userId: string) => {
    setInviteUserModal(true);
    setEditableUser(users.items?.find(({ id }) => id === userId));
  };
  const handleUserDelete = (id: string) => {
    selectedCompanyId && deleteUser(id, selectedCompanyId).then(refreshUsers);
  };

  const { openDialog, ConfirmationDialog } = useConfirmationDialog();
  const getUsers = useGetUsersApi();

  const refreshUsers = () => {
    setEditableUser(undefined);
    if (selectedCompanyId) {
      const reqQuery: GetUsersParams = {
        companyId: selectedCompanyId,
        limit: itemsPerPage,
        offset: page * itemsPerPage,
        sort: `${getSortFieldForApi(sortField)}_${sortDir}`,
      };
      if (searchQuery) {
        reqQuery.query = searchQuery;
      }
      getUsers(reqQuery)
        .then(({ data }) => {
          setUsers(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const mapSortPropsToApi: Record<string, string> = {
    firstName: "first_name",
    lastName: "last_name",
    email: "email",
    role: "role",
  };

  const isFieldSortable = (fieldName: string) =>
    Object.prototype.hasOwnProperty.call(mapSortPropsToApi, fieldName);

  const getSortFieldForApi = (fieldName: string) => {
    return mapSortPropsToApi[fieldName];
  };

  const swapSortDirection = (dir?: "asc" | "desc") => {
    if (dir) {
      setSortDir(dir);
    } else {
      setSortDir(sortDir == "asc" ? "desc" : "asc");
    }
  };

  const changeSortField = (fieldName: string) => {
    if (sortField == fieldName) {
      swapSortDirection();
    } else {
      swapSortDirection("desc");
    }
    setSortField(fieldName);
  };

  const handleSearchQuery = (text: string) => {
    setSearchQuery(text);
  };

  useEffect(() => {
    selectedCompanyId && refreshUsers();
  }, [page, selectedCompanyId, sortDir, sortField, searchQuery]);

  useEffect(() => {
    setPage(0);
    tableRef?.current?.scrollIntoView();
  }, [sortDir, sortField, searchQuery]); 

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };

  const formatTableRows = (users: User[]) => {
    return users.map(
      ({ id, firstName, lastName, phoneNumber, email, role }: User) => {
        return {
          id,
          firstName,
          lastName,
          email,
          phoneNumber,
          role: role.title,
          roleName: role.name,
          actions: "edit/delete",
        };
      }
    );
  };

  return (
    <>
      <HeaderContainer>
        <SearchInput handleSearch={handleSearchQuery} />
        <Button
          variant="contained"
          onClick={() => {
            setInviteUserModal(true);
            setEditableUser(undefined);
          }}
          disabled={!isActionAllowed({ authorRole: profileRole })}
        >
          Invite User
        </Button>
      </HeaderContainer>
      <InviteUserModal
        show={showInviteUserModal}
        setShowModal={setInviteUserModal}
        userData={editableUser}
        refresh={refreshUsers}
      />

      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer sx={{ height: "calc(100vh - 300px)" }}>
          <Table ref={tableRef}  stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    onClick={() =>
                      isFieldSortable(column.id)
                        ? changeSortField(column.id)
                        : {}
                    }
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      background: "#254597",
                      color: "#fff",
                      cursor: isFieldSortable(column.id)
                        ? "pointer"
                        : "initial",
                    }}
                  >
                    {column.label}
                    {sortField == column.id && <SortIcon dir={sortDir} />}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.items &&
                formatTableRows(users?.items).map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "actions" ? (
                              <>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleUserEdit(row.id);
                                  }}
                                  disabled={
                                    !isActionAllowed({
                                      authorRole: profileRole,
                                      userRole: row.roleName,
                                    })
                                  }
                                >
                                  <EditIcon />
                                </Button>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openDialog({
                                      title: `Delete "${row.firstName} ${row.lastName}"`,
                                      content:
                                        "Are you sure you want to remove this user from your company?",
                                      onConfirm: () => {
                                        handleUserDelete(row.id);
                                      },
                                    });
                                  }}
                                  disabled={
                                    !isActionAllowed({
                                      authorRole: profileRole,
                                      userRole: row.roleName,
                                    })
                                  }
                                >
                                  <DeleteIcon />
                                </Button>
                              </>
                            ) : (
                              <>{value}</>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={users.count}
          rowsPerPageOptions={[-1]}
          rowsPerPage={itemsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
      <ConfirmationDialog />
    </>
  );
};

export default UserManagment;
