import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "700px",
    height: "100px",
  },
  "& .MuiPaper-root": {
    maxWidth: '800px',
  },
}));

type CreateCampaignModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function CreateCampaignModal({
  show,
  setShowModal,
}: CreateCampaignModalType) {
  const handleClose = () => {
    setShowModal(false);
  };

  const navigate = useNavigate();

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
        fullWidth={true}
      >
        <DialogTitle id="customize  d-dialog-title">
          Choose Campaign Type
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          style={{ padding: "0px 24px 20px 24px", display: "flex" }}
        >
          <Box display={"flex"} sx={{ flexDirection: "column" }}>
            {/* <Box display={'flex'} sx={{marginBottom: '20px'}}>
              <Card sx={{ width: '50%', marginRight: "20px" }}>
                <CardActionArea
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "start",
                  }}
                  onClick={() => navigate("/campaigns/message")}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Bulk Message
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Send a message to all or a subset of your opted-in
                      contacts.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ width: '50%' }}>
                <CardActionArea
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "start",
                  }}
                  onClick={() => navigate("/campaigns/join")}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Text-to-Join
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      A campaign that gives your audience an easy way to opt-in
                      to your marketing and automatically segment themselves
                      based on interests.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box> */}
            <Box display={'flex'}>
            <Card sx={{ width: '33%', marginRight: "20px" }}>
                <CardActionArea
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "start",
                  }}
                  onClick={() => navigate("/campaigns/text-flyer")}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Text Flyer
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Send a mobile flyer to all or a portion of your opted-in contacts.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ width: '33%', marginRight: "20px" }}>
                <CardActionArea
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "start",
                  }}
                  onClick={() => navigate("/campaigns/multiple-message")}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                     Custom Bulk Message
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Send a message to all or a subset of your opted-in
                      contacts.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card sx={{ width: '33%' }}>
                <CardActionArea
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "start",
                  }}
                  onClick={() => navigate("/campaigns/multiple-join")}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        Custom Text-to-Join
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      A campaign that gives your audience an easy way to opt-in
                      to your marketing and automatically segment themselves
                      based on interests.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Fragment>
  );
}
