import React, { useState, useMemo } from 'react';
import { SetPasswordContainer, Container, Header } from './styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from "react-router-dom";
import { useSetPasswordApi } from 'src/api/authentication';
import { useLocation } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import { passwordValidationSchema, ValidationErrors } from 'src/constants/validations';
import * as Yup from 'yup';

function SetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formValues, setFormValues] = useState({ password: '' });

  const validate = async (values: typeof formValues) => {
    try {
        await passwordValidationSchema.validate(values, { abortEarly: false });
        setErrors({});
        return true;
    } catch (err) {
        if (err instanceof Yup.ValidationError) {
            const newErrors = err.inner?.reduce((acc, curr) => {
                if (curr.path) {
                    acc[curr.path] = curr?.message;
                }
                return acc;
            }, {} as ValidationErrors);
            setErrors(newErrors);
        }
        return false;
    }
};

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const navigate = useNavigate();
  const setPasswordApi = useSetPasswordApi();
  const location = useLocation();

  const code = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('code');
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSetPassword = async () => {
    const isValid = await validate(formValues);
    if (isValid) {
     code && setPasswordApi({token: code, password: formValues.password}).then(() => {
      navigate('/login');
    }).catch((e) => {
      if (e.error === 'validation') {
        setErrors({token: 'Link is invalid or expired.'})
      }
    })
    }
  }

  return <Container>
    <SetPasswordContainer>
      <Header>Set Password</Header>
      <FormControl variant="outlined" style={{margin: '0px 0px 20px 0px'}}>
          <InputLabel htmlFor="outlined-adornment-password" style={errors?.password ? {color: '#d32f2f'} : {}}>Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={formValues.password}
            error={!!errors?.password}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            name="password"
            label="Password"
          />
        <FormHelperText id="component-error-text" style={{color: '#d32f2f'}}>{errors.password || errors.token || ''}</FormHelperText>
        </FormControl>
      <Button variant="contained" size="large" onClick={handleSetPassword}>
          Submit
      </Button>
    </SetPasswordContainer>
  </Container>
}

export default SetPassword;
