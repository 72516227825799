import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { sidebarItems } from './constants';
import LogoSidebar from 'src/ui/assets/biz.png';
import { SidebarLogo } from './styles';
import { useNavigate } from "react-router-dom";

function Sidebar() {
  const navigate = useNavigate();

  return <Drawer
    variant="permanent"
    sx={{
      width: '230px',
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: { width: '230px', boxSizing: 'border-box' },
    }}
  >
    <SidebarLogo src={LogoSidebar} />
    <Box sx={{ overflow: 'auto' }} >
      <List>
        {sidebarItems.map(({ text, icon, path }) => (
          <ListItem key={text} disablePadding onClick={() => navigate(path)}>
            <ListItemButton style={{ paddingLeft: '34px' }}>
              <ListItemIcon style={{ minWidth: '45px' }}>
                {icon}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  </Drawer>;
}

export default Sidebar;
