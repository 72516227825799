import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  useDeleteIntegrationApi,
  useGetIntegrationsApi,
} from "src/api/integrations";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";
import { Integration } from "src/constants/types";
import { selectedCompany } from "src/store/company/companySlice";
import { EventType, columns, itemsPerPage } from "./constants";
import {
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import AddIntegrationModal from "./AddIntegration/view";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { HeaderContainer } from "./styles";
import { GetIntegrationsParams } from "../../../../api/integrations/types";
import SearchInput from "../../../../components/SearchInput/view";
import SortIcon from "../../../../components/SortIcon/view";

const Integrations = () => {
  const [page, setPage] = useState<number>(0);
  const tableRef = useRef<HTMLTableElement>(null);
  const [sortField, setSortField] = useState<string>("name");
  const [sortDir, setSortDir] = useState<"asc" | "desc">("asc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showAddIntegrationModal, setShowAddIntegrationModal] =
    useState<boolean>(false);
  const [integrations, setIntegrations] = useState<{
    count: number;
    items: Integration[] | null;
  }>({
    count: 0,
    items: null,
  });
  const [editibleIntegration, setEditibleIntegration] = useState<
    Integration | undefined
  >();
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  const deleteIntegration = useDeleteIntegrationApi();

  const handleIntegrationEdit = (integrationId: string) => {
    setShowAddIntegrationModal(true);
    setEditibleIntegration(
      integrations.items?.find(({ id }) => id === integrationId)
    );
  };
  const handleIntegrationDelete = (integrationId: string) => {
    selectedCompanyId &&
      deleteIntegration(integrationId, selectedCompanyId).then(
        refreshIntegrations
      );
  };

  const { openDialog, ConfirmationDialog } = useConfirmationDialog();
  const getIntegrations = useGetIntegrationsApi();

  const refreshIntegrations = () => {
    setEditibleIntegration(undefined);
    if (selectedCompanyId) {
      const reqQuery: GetIntegrationsParams = {
        companyId: selectedCompanyId,
        limit: itemsPerPage,
        offset: page * itemsPerPage,
        sort: `${getSortFieldForApi(sortField)}_${sortDir}`,
      };
      if (searchQuery) {
        reqQuery.query = searchQuery;
      }
      getIntegrations(reqQuery)
        .then(({ data }) => {
          setIntegrations(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const mapSortPropsToApi: Record<string, string> = {
    name: "name",
    event_type: "event_type",
    url: "url",
  };

  const isFieldSortable = (fieldName: string) =>
    Object.prototype.hasOwnProperty.call(mapSortPropsToApi, fieldName);

  const getSortFieldForApi = (fieldName: string) => {
    return mapSortPropsToApi[fieldName];
  };

  const swapSortDirection = (dir?: "asc" | "desc") => {
    if (dir) {
      setSortDir(dir);
    } else {
      setSortDir(sortDir == "asc" ? "desc" : "asc");
    }
  };

  const changeSortField = (fieldName: string) => {
    if (sortField == fieldName) {
      swapSortDirection();
    } else {
      swapSortDirection("desc");
    }
    setSortField(fieldName);
  };

  const handleSearchQuery = (text: string) => {
    setSearchQuery(text);
  };

  useEffect(() => {
    selectedCompanyId && refreshIntegrations();
  }, [page, selectedCompanyId, sortDir, sortField, searchQuery]);

  useEffect(() => {
    setPage(0);
    tableRef?.current?.scrollIntoView();
  }, [sortDir, sortField, searchQuery]); 

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };

  const formatEventType = (type: EventType): string => {
    switch (type) {
      case EventType.NewInboxMessage:
        return "New Inbox Message";
      case EventType.UserJoinedGroup:
        return "User Joined Group";
      case EventType.UserOptedOut:
        return "User Opted Out";
      default:
        return "";
    }
  };

  const formatTableRows = (integrations: Integration[]) => {
    return integrations.map(({ id, name, event_type, url }: Integration) => {
      return {
        id,
        name,
        event_type,
        url,
        actions: "edit/delete",
      };
    });
  };

  return (
    <>
      <HeaderContainer>
        <SearchInput handleSearch={handleSearchQuery} />
        <Button
          variant="contained"
          onClick={() => {
            setShowAddIntegrationModal(true);
            setEditibleIntegration(undefined);
          }}
        >
          Add Webhook
        </Button>
      </HeaderContainer>
      <AddIntegrationModal
        show={showAddIntegrationModal}
        setShowModal={setShowAddIntegrationModal}
        integrationData={editibleIntegration}
        refresh={refreshIntegrations}
      />
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer sx={{ height: "calc(100vh - 300px)" }}>
          <Table ref={tableRef}  stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    onClick={() =>
                      isFieldSortable(column.id)
                        ? changeSortField(column.id)
                        : {}
                    }
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      background: "#254597",
                      color: "#fff",
                      cursor: isFieldSortable(column.id)
                        ? "pointer"
                        : "initial",
                    }}
                  >
                    {column.label}
                    {sortField == column.id && <SortIcon dir={sortDir} />}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {integrations?.items &&
                formatTableRows(integrations?.items).map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "event_type" ? (
                              formatEventType(value as EventType)
                            ) : column.id === "actions" ? (
                              <>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleIntegrationEdit(row.id);
                                  }}
                                >
                                  <EditIcon />
                                </Button>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openDialog({
                                      title: `Delete "${row.name}"`,
                                      content:
                                        "Are you sure you want to remove this integration from your company?",
                                      onConfirm: () => {
                                        handleIntegrationDelete(row.id);
                                      },
                                    });
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </>
                            ) : (
                              <>{value}</>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={integrations.count}
          rowsPerPageOptions={[-1]}
          rowsPerPage={itemsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
      <ConfirmationDialog />
    </>
  );
};

export default Integrations;
