
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
`
export const Body = styled.div`
    width: calc(100% - 230px);
    padding: 0 40px 40px 40px;
    box-sizing: border-box;
`;

export const Main = styled.div`
    margin-top: 80px;
`;