import { CreateMediaApiParams, VerifyMediaApiParams } from './types';
import apiClient from '../axiosClient';
import {useCallback} from 'react';
import axios from 'axios';

/**
 * @function useCreateMedia
 * @returns { CreateMediaApiParams }
 */
export function useCreateMediaApi(): CreateMediaApiParams {
    return useCallback(mimeType => apiClient.post('/files', { 'mime_type': mimeType }), []);
}

/**
 * @function useVerifyMedia
 * @returns { VerifyMediaApiParams }
 */
export function useVerifyMediaApi(): VerifyMediaApiParams {
    return useCallback(mediaId => apiClient.post(`files/${mediaId}/verify`, undefined), []);
}

export const uploadFileToS3 = async (file: File, presignedUrl: string) => {
    const authorization = axios.defaults.headers.common["Authorization"];
    try {
      axios.defaults.headers.common["Authorization"] = undefined;
      const type = file.name.includes('.vcf') ? "text/vcard" : file.type;
      const response = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': type,
        },
      });
      console.log('File uploaded successfully:', response);
      axios.defaults.headers.common["Authorization"] = authorization;
    } catch (error) {
      axios.defaults.headers.common["Authorization"] = authorization;
      console.error('Error uploading file:', error);
    }
  };