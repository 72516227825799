import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { PaperPlaneTilt } from "phosphor-react";
import { useTheme, styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AttachFileUrlModal from "../../../components/AttachFileUrlModal/view";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import ChatFileIcon from "../../../components/ChatFileIcon/view";
import {FileLinkWithMimeType, InboxContact} from "../../../constants/types";
import toast, {ToastOptions} from "react-hot-toast";

type ChatFooterProps = {
  addMessage: (text: string) => Promise<void>;
  updateLinks: (link?: FileLinkWithMimeType) => void;
  selectedContactObject: InboxContact;
};

const StyledInput = styled(TextField)(() => ({
  "& .MuiInputBase-input": {
    border: "none !important",
  },
}));

const Footer = ({
  addMessage,
  updateLinks,
  selectedContactObject,
}: ChatFooterProps) => {
  const [message, setMessage] = useState<string>("");
  const [showAttachFileModal, setAttachFileModal] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const [mimeType, setMimeType] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isContactInvalid] = useState<boolean>(selectedContactObject.contact.validStatus == 'invalid');
  const [isContactOptedOut] = useState<boolean>(!!selectedContactObject.contact.optOutDate);
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const [openEmojiModal, setOpenEmojiModal] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (messageInputRef.current) {
      setMessage("");
      setUrl("");
      setMimeType(undefined);
      updateLinks();
    }
  }, [selectedContactObject.contact.id]);

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement | HTMLTextAreaElement>
  ): void => {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setMessage(message + `\n`);
    } else if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      setIsLoading(true);
      addMessage(message).then(() => {
        setIsLoading(false);
      });
      setMessage("");
      setUrl("");
      setMimeType(undefined);
      updateLinks();
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "transparent !important",
      }}
    >
      <Box
        width={"100%"}
        sx={{
          backgroundColor: "#F8FAFF",
          boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.25)",
        }}
      >
        {url && (
          <Box sx={{ marginLeft: 5, paddingBottom: 1, paddingTop: 1 }}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <ChatFileIcon url={url} mimeType={mimeType} width="10%"/>
              <Button
                onClick={() => {
                  setUrl("");
                  setMimeType(undefined);
                  updateLinks();
                }}
              >
                <DeleteIcon />
              </Button>
            </Stack>
          </Box>
        )}
        <Stack direction="row" alignItems={"center"} spacing={0}>
          <IconButton
            onClick={() => {
              setAttachFileModal(true);
            }}
          >
            <AttachFileIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setOpenEmojiModal(true);
            }}
          >
            <InsertEmoticonIcon />
          </IconButton>
          {openEmojiModal && (
            <ClickAwayListener
              onClickAway={() => {
                setOpenEmojiModal(false);
              }}
            >
              <Box sx={{position:'absolute', bottom: '60px'}}>
                <EmojiPicker
                  onEmojiClick={(e: EmojiClickData) => {
                    const emoji = e.emoji;
                    const start = messageInputRef.current?.value.substring(0, messageInputRef.current.selectionStart)
                    const end = messageInputRef.current?.value.substring(messageInputRef.current.selectionStart)
                    const text = start + emoji + end;
                    setMessage(text);
                    messageInputRef.current?.focus();
                    messageInputRef.current?.setSelectionRange(messageInputRef.current?.selectionStart, messageInputRef.current?.selectionStart);
                    setOpenEmojiModal(false);
                  }}
                />
              </Box>
            </ClickAwayListener>
          )}
          {showAttachFileModal && (
            <AttachFileUrlModal
              show={showAttachFileModal}
              setShowModal={setAttachFileModal}
              handleFileChange={(link: FileLinkWithMimeType) => {
                setUrl(link.url);
                setMimeType(link.mimeType);
                updateLinks({url: link.url, mimeType: link.mimeType});
              }}
            />
          )}
          <Stack sx={{ width: "100%", marginLeft: 0 }}>
            <Box
              style={{
                zIndex: 10,
                position: "fixed",
                display: "none",
                bottom: 81,
              }}
            ></Box>
            <StyledInput
              inputRef={messageInputRef}
              multiline
              value={message}
              size={"small"}
              onKeyDown={onKeyDown}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
              fullWidth
              placeholder="Write a message..."
              variant="outlined"
            />
          </Stack>

          <div
            style={{
              backgroundColor: (isContactInvalid || isContactOptedOut) ? theme.palette.grey[500] : theme.palette.primary.main,
              borderRadius: "4px",
              marginLeft: "5px",
              marginRight: "5px",
              cursor: "pointer",
            }}
          >
            <IconButton
              onClick={() => {
                if (isContactInvalid || isContactOptedOut) {
                  const options: ToastOptions = {
                      position: 'bottom-right',
                  };
                  if (isContactInvalid) {
                      toast.error("This user has an invalid phone number and is not able to receive text messages.", options);
                  } else if (isContactOptedOut) {
                      toast.error("This user has opted out from receiving messages. They must opt back in before you are able to message them.", options);
                  }
                  return;
                }
                setIsLoading(true);
                addMessage(message).then(() => {
                  setIsLoading(false);
                });
                setMessage("");
                setUrl("");
                setMimeType("");
                updateLinks();
              }}
            >
              {(isContactInvalid || isContactOptedOut) ? <PaperPlaneTilt color="#ffffff" /> :
                  (isLoading ?
                      <CircularProgress size="24px" color="secondary" /> :
                      <PaperPlaneTilt color="#ffffff" />
                  )
              }
            </IconButton>
          </div>
        </Stack>
      </Box>
    </Box>
  );
};

export default Footer;
