import styled from "styled-components";

export const TextFieldWithCopyIconWrapper = styled.div`
  position: relative;
  width: 100%;
  > div {
    width: 100%;
  }
  * {
    cursor: pointer;
  }
  input {
    color: black;
    pointer-events: none;
    outline: none;
  }
  svg {
    position: absolute;
    height: 40px;
    top: 22px;
    right: 10px;
    color: lightgray;
    @keyframes hithere {
      30% {
        transform: scale(1.2);
      }
      40%, 60% {
        transform: rotate(-20deg) scale(1.2);
      }
      50% {
        transform: rotate(20deg) scale(1.2);
      }
      70% {
        transform: rotate(0deg) scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  &.grow {
    svg {
      visibility: visible;
      animation: hithere 400ms ease infinite;
      color: black;
    }
  }
  &:hover {
    svg {
      visibility: visible;
      color: black;
    }
  }
`;
