import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  useDeleteApikeyApi,
  useGetApikeysApi,
  useRenewApikeyApi,
} from "src/api/apikeys";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";
import { Apikey } from "src/constants/types";
import { selectedCompany } from "src/store/company/companySlice";
import {
  columns as tableColumns,
  itemsPerPage,
} from "./constants";
import Tooltip from "@mui/material/Tooltip";
import toast from "react-hot-toast";
import {
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import AddApikeyModal from "./AddApiKey/view";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { HeaderContainer } from "./styles";
import dayjs from "dayjs";
import { DotsThreeVertical } from "phosphor-react";
import { Column } from "./types";
import { GetApikeysParams } from "../../../../api/apikeys/types";
import SearchInput from "../../../../components/SearchInput/view";
import SortIcon from "../../../../components/SortIcon/view";

const ApiKeys = () => {
  const [page, setPage] = useState<number>(0);
  const tableRef = useRef<HTMLTableElement>(null);
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortDir, setSortDir] = useState<"asc" | "desc">("asc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showAddApikeyModal, setShowAddApikeyModal] = useState<boolean>(false);
  const [Apikeys, setApikeys] = useState<{
    count: number;
    items: Apikey[] | null;
  }>({ count: 0, items: null });
  const [editibleApikey, setEditibleApikey] = useState<Apikey | undefined>();
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  const [columns, setColumns] = useState<Column[]>(tableColumns);
  const [anchorElColumnsMenu, setAnchorElColumnsMenu] =
    useState<null | HTMLElement>(null);
  const deleteApikey = useDeleteApikeyApi();
  const renewApikey = useRenewApikeyApi();

  const handleOpenSelectColumnsMenu = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElColumnsMenu(event.currentTarget);
  };

  const handleCloseSelectColumnsMenu = () => {
    setAnchorElColumnsMenu(null);
  };

  const handleApikeyEdit = (ApikeyId: string) => {
    setShowAddApikeyModal(true);
    setEditibleApikey(Apikeys.items?.find(({ id }) => id === ApikeyId));
  };
  const handleApikeyDelete = (apikeyId: string) => {
    selectedCompanyId &&
      deleteApikey([apikeyId], selectedCompanyId).then(refreshApikeys);
  };

  const handleApikeyRenew = (apikeyId: string) => {
    selectedCompanyId &&
      renewApikey(apikeyId, selectedCompanyId).then(() => {
        refreshApikeys();
        toast.success("Token has been successfully regenerated");
      });
  };

  const { openDialog, ConfirmationDialog } = useConfirmationDialog();
  const getApikeys = useGetApikeysApi();

  const refreshApikeys = () => {
    setEditibleApikey(undefined);
    if (selectedCompanyId) {
      const reqQuery: GetApikeysParams = {
        companyId: selectedCompanyId,
        limit: itemsPerPage,
        offset: page * itemsPerPage,
        sort: `${getSortFieldForApi(sortField)}_${sortDir}`,
      };
      if (searchQuery) {
        reqQuery.query = searchQuery;
      }
      getApikeys(reqQuery)
        .then(({ data }) => {
          setApikeys(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const mapSortPropsToApi: Record<string, string> = {
    name: "name",
    description: "description",
    permissions: "permissions",
    createdBy: "created_by",
    createdAt: "date_created",
    updatedAt: "date_updated",
  };

  const isFieldSortable = (fieldName: string) =>
    Object.prototype.hasOwnProperty.call(mapSortPropsToApi, fieldName);

  const getSortFieldForApi = (fieldName: string) => {
    return mapSortPropsToApi[fieldName];
  };

  const swapSortDirection = (dir?: "asc" | "desc") => {
    if (dir) {
      setSortDir(dir);
    } else {
      setSortDir(sortDir == "asc" ? "desc" : "asc");
    }
  };

  const changeSortField = (fieldName: string) => {
    if (sortField == fieldName) {
      swapSortDirection();
    } else {
      swapSortDirection("desc");
    }
    setSortField(fieldName);
  };

  const handleSearchQuery = (text: string) => {
    setSearchQuery(text);
  };

  useEffect(() => {
    selectedCompanyId && refreshApikeys();
  }, [page, selectedCompanyId, sortDir, sortField, searchQuery]);

  useEffect(() => {
    setPage(0);
    tableRef?.current?.scrollIntoView();
  }, [sortDir, sortField, searchQuery]); 

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };

  const formatPermissions = (permissions: { name: string, description: string}[]) => {
    return permissions ?  permissions.map(p => p.description).join(", ") : [];
  };

  const formatTableRows = (Apikeys: Apikey[]) => {
    return Apikeys.map(
      ({
        id,
        name,
        description,
        token,
        permissions,
        createdAt,
        updatedAt,
      }: Apikey) => {
        return {
          id,
          name,
          description,
          token,
          permissions,
          createdAt,
          updatedAt,
          actions: "edit/delete",
        };
      }
    );
  };

  return (
    <>
      <HeaderContainer>
        <SearchInput handleSearch={handleSearchQuery} />
        <Button
          variant="contained"
          onClick={() => {
            setShowAddApikeyModal(true);
            setEditibleApikey(undefined);
          }}
        >
          Create API Key
        </Button>
      </HeaderContainer>
      <AddApikeyModal
        show={showAddApikeyModal}
        setShowModal={setShowAddApikeyModal}
        apikeyData={editibleApikey}
        refresh={refreshApikeys}
      />
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer sx={{ height: "calc(100vh - 300px)" }}>
          <Table ref={tableRef}  aria-label="sticky table">
            <TableHead sx={{ position: "sticky", top: 0, zIndex: 100 }}>
              <TableRow>
                {columns.map((column) => {
                  if (!column.isSelected) {
                    return null;
                  }
                  return (
                    <TableCell
                      key={column.id}
                      onClick={() =>
                        isFieldSortable(column.id)
                          ? changeSortField(column.id)
                          : {}
                      }
                      align={column.align}
                      style={{
                        paddingLeft: column.id === "actions" ? 0 : "16px",
                        paddingRight: column.id === "actions" ? 0 : "16px",
                        minWidth: column.minWidth,
                        background: "#254597",
                        color: "#fff",
                        cursor: isFieldSortable(column.id) ? 'pointer' : 'initial',
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        sx={{
                          paddingLeft: column.id === "actions" ? "15px" : 0,
                        }}
                      >
                        <Box>
                          {column.label}
                          {sortField == column.id && <SortIcon dir={sortDir} />}
                        </Box>
                        {column.id === "actions" && (
                          <Box>
                            <IconButton
                              onClick={handleOpenSelectColumnsMenu}
                              sx={{ p: 0, marginRight: "10px" }}
                            >
                              <DotsThreeVertical weight="bold" color="#fff" />
                            </IconButton>
                            <Menu
                              id="columns-multiple-checkbox"
                              anchorEl={anchorElColumnsMenu}
                              open={Boolean(anchorElColumnsMenu)}
                              onClose={handleCloseSelectColumnsMenu}
                            >
                              {columns.map((column, index) => (
                                <MenuItem
                                  disabled={["name", "actions"].includes(
                                    column.id
                                  )}
                                  key={column.id}
                                  value={column.id}
                                  onClick={() => {
                                    const newSelectedColumns = columns.map(
                                      (c, i) => {
                                        if (i === index) {
                                          return {
                                            ...c,
                                            isSelected: !c.isSelected,
                                          };
                                        } else {
                                          return c;
                                        }
                                      }
                                    );
                                    setColumns(newSelectedColumns);
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Checkbox
                                      sx={{ paddingLeft: 0 }}
                                      value={column.id}
                                      checked={column.isSelected}
                                    />
                                    <ListItemText primary={column.label} />
                                  </Box>
                                </MenuItem>
                              ))}
                            </Menu>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {Apikeys?.items &&
                formatTableRows(Apikeys?.items).map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {columns.map((column) => {
                        if (!column.isSelected) {
                          return null;
                        }
                        const value = row[column.id];
                        return (
                          <TableCell
                            style={{
                              minWidth: column.minWidth,
                              paddingLeft: column.id === "actions" ? 0 : "16px",
                              paddingRight:
                                column.id === "actions" ? 0 : "16px",
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            {column.id === "createdAt" ||
                            column.id === "updatedAt" ? (
                              dayjs(value as string).format(
                                "YYYY-MM-DD hh:mm A"
                              )
                            ) : column.id === "permissions" ? (
                              <div>{formatPermissions(value as {name: string, description: string}[])}</div>
                            ) : column.id === "actions" ? (
                              <Box flex="flex" justifyContent="start">
                                <Tooltip
                                  placement="top"
                                  arrow
                                  title="Regenerate token"
                                >
                                  <Button
                                    sx={{ padding: 0 }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      openDialog({
                                        title: "Renew",
                                        content:
                                          "By regenerating this API key, any request made using the old key will be rejected, which could cause systems depending on it to break. You will need to update any application or system using the old key with the new one. Are you sure you want to regenerate this API Key for your company?",
                                        onConfirm: () => {
                                          handleApikeyRenew(row.id);
                                        },
                                      });
                                    }}
                                  >
                                    <AutorenewIcon />
                                  </Button>
                                </Tooltip>

                                <Button
                                  sx={{ padding: 0 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleApikeyEdit(row.id);
                                  }}
                                >
                                  <EditIcon />
                                </Button>
                                <Button
                                  sx={{ padding: 0 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openDialog({
                                      title: `Delete "${row.name}"?`,
                                      content:
                                        "By deleting this API key, any request made using this key will be rejected, which could cause systems depending on it to break. Once deleted, you will not be able to view or modify this key. Are you sure you want to remove this API Key from your company?",
                                      onConfirm: () => {
                                        handleApikeyDelete(row.id);
                                      },
                                    });
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Box>
                            ) : (
                              <>{value}</>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={Apikeys.count}
          rowsPerPageOptions={[-1]}
          rowsPerPage={itemsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
      <ConfirmationDialog />
    </>
  );
};

export default ApiKeys;
