import React, {Fragment, useEffect, useRef, useState} from "react";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { InboxContacts } from "src/constants/types";
import Contact from "./Contact";
import { NotePencil, MagnifyingGlass } from "phosphor-react";

type InboxContactsProps = {
  isShowAll: boolean;
  onSelectContact: React.Dispatch<React.SetStateAction<string | null>>;
  toggleShowAll: () => void;
  onSearchContact: (value: string) => void;
  contacts: InboxContacts | null;
  selectedContact: string | null;
  lastSelectedChatMessageDate: string | undefined;
  isContactsLoading: boolean;
  handleNewChatClick: () => void;
  fetchMoreData: () => void;
  totalContactsCount: number;
  hasMoreContacts: boolean;
  search: string;
};

function Contacts({
  onSelectContact,
  onSearchContact,
  isShowAll,
  toggleShowAll,
  selectedContact,
  contacts,
  lastSelectedChatMessageDate,
  isContactsLoading,
  handleNewChatClick,
  hasMoreContacts,
  fetchMoreData,
  search,
}: InboxContactsProps) {
  const [term, setTerm] = useState(search);

  const contactsListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const contactsList = contactsListRef.current;
    if (contactsList) {
      contactsList.addEventListener("scroll", onScroll);

      // Clean-up
      return () => {
        contactsList.removeEventListener("scroll", onScroll);
      };
    }
  }, [contactsListRef, contacts?.items, hasMoreContacts]);

  const onScroll = () => {
    if (contactsListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contactsListRef.current;
      const isNearBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
      if (isNearBottom) {
        if (hasMoreContacts) {
          fetchMoreData();
        }
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          minHeight: "100%",
          height: "100%",
          width: 320,
          overflowX: "hidden",
          backgroundColor: "#fff",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack spacing={2} sx={{ height: "85vh", maxHeight: "85vh" }}>
          <Stack
            p={2}
            alignItems={"center"}
            justifyContent="space-between"
            direction="row"
          >
            <Typography variant="h5">Contacts</Typography>
            <IconButton onClick={() => {
              handleNewChatClick();
            }}>
              <NotePencil />
            </IconButton>
          </Stack>
          <Stack p={2} paddingTop={0}>
            <ToggleButtonGroup
              sx={{ marginBottom: 2 }}
              fullWidth
              size="small"
              color="primary"
              value={isShowAll}
              exclusive
              onChange={() => { toggleShowAll(); setTerm(''); }}
              aria-label="messageType"
            >
              <ToggleButton selected={isShowAll} value="true">
                All
              </ToggleButton>
              <ToggleButton selected={!isShowAll} value="false">
                Unread Only
              </ToggleButton>
            </ToggleButtonGroup>
            <FormControl
              sx={{ width: "100%", borderColor: "rgba(0, 0, 0, 0.25)" }}
              size="small"
              variant="outlined"
            >
              <OutlinedInput
                value={term}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  setTerm(value);
                  onSearchContact(value);
                  onSelectContact(null);
                }}
                placeholder="Search Contacts..."
                id="outlined-adornment-weight"
                startAdornment={
                  <InputAdornment position="start">
                    <MagnifyingGlass />
                  </InputAdornment>
                }
                aria-describedby="outlined-search-helper-text"
                inputProps={{
                  "aria-label": "search",
                }}
              />
            </FormControl>
          </Stack>
          {isContactsLoading && (
            <Stack direction="row" width="100%" height="100%">
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size="24px" color="secondary" />
              </Box>
            </Stack>
          )}
          {!isContactsLoading && contacts && contacts.items && !contacts.items.length && (
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              No Results
            </Box>
          )}
          {!isContactsLoading && contacts && contacts.items && contacts.items.length > 0 && (
            <Stack ref={contactsListRef}
              sx={{
                flexGrow: 1,
                overflowY: "scroll",
                overflowX: "hidden",
                height: "100%",
              }}
            >
              <Stack>
                {!contacts.items!.length && (
                  <Box sx={{ color: "#ccc" }}>No Results</Box>
                )}
                {[...contacts.items!]
                  .map((el) => {
                    return (
                      <Fragment key={el.contact.id}>
                        <Contact
                          contact={el}
                          selectedContact={selectedContact}
                          lastSelectedChatMessageDate={
                            lastSelectedChatMessageDate
                          }
                          onSelectChat={onSelectContact}
                        />
                        <Divider />
                      </Fragment>
                    );
                  })}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  );
}

export default Contacts;
