import React, { Fragment, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { ContentContainer, Container, FooterContainer } from "./styles";
import Button from "@mui/material/Button";
import {
  ValidationErrors,
  addIntegrationValidationSchema,
} from "src/constants/validations";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import FormHelperText from "@mui/material/FormHelperText";
import { Integration } from "src/constants/types";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  useAddIntegrationApi,
  useUpdateIntegrationApi,
} from "src/api/integrations";
import { EventType } from "../constants";
import { Box } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    height: "700px",
  },
  "& .MuiPaper-root": {
    maxWidth: "unset",
  },
}));

type AddIntegrationModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  integrationData?: Integration;
  refresh: () => void;
};

const initialValues = {
  name: "",
  eventType: EventType.NewInboxMessage,
  url: "",
};

const payloadExamples = {
  new_inbox_message:
    '{"type": "new_inbox_message", phone": "+1234567890", "message": "Example", "timestamp": "2024-07-17T15:35:56.789Z"}',
  user_joined_group:
    '{"type", "user_joined_group", phone": "+1234567890", "group_id": "d825637f-4bb1-4494-a16b-fb56477aed66", "group": "Group Name", "timestamp": "2024-07-17T15:35:56.789Z"}',
  user_opted_out:
    '{"type": "user_opted_out", phone": "+1234567890", "timestamp": "2024-07-17T15:35:56.789Z"}',
};

export default function AddIntegrationModal({
  show,
  setShowModal,
  refresh,
  integrationData,
}: AddIntegrationModalType) {
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [showPayloadExample, setShowPayloadExample] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  const addIntergation = useAddIntegrationApi();
  const updateIntegration = useUpdateIntegrationApi();

  const handleClose = () => {
    setFormValues(initialValues);
    setErrors({});
    setIsButtonDisabled(true);
    setShowModal(false);
    refresh();
  };

  const toggleEventTypePayloadExample = () => {
    setShowPayloadExample(!showPayloadExample);
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const values = { ...formValues, [name]: value };
    setFormValues(values);
    setIsButtonDisabled(!validate(values));
  };


  const handleSelectChange = ({ target }: SelectChangeEvent<string>) => {
    const values = { ...formValues, eventType: target.value as EventType };
    setFormValues(values);
    setIsButtonDisabled(!validate(values));
  };

  const validate = (values: typeof formValues) => {
    try {
      addIntegrationValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleAddIntegration = async () => {
    const isValid = await validate(formValues);
    if (isValid) {
      selectedCompanyId &&
        addIntergation(
          {
            ...formValues,
          },
          selectedCompanyId
        )
          .then(() => {
            handleClose();
          })
          .catch((e) => {
            if (e.error === "conflict") {
              setErrors({
                validation: "Integration with the same name already exists!",
              });
            }
          });
    }
  };

  const handleIntegrationEdit = () => {
    selectedCompanyId &&
      integrationData?.id &&
      updateIntegration(
        {
          name: formValues.name,
          eventType: formValues.eventType,
          url: formValues.url,
        },
        integrationData?.id,
        selectedCompanyId
      )
        .then(() => {
          handleClose();
        })
        .catch((e) => {
          if (e.error === "conflict" || e.error === "validation") {
            setErrors({ validation: "Something went wrong!" });
          }
        });
  };

  useEffect(() => {
    integrationData &&
      setFormValues({
        name: integrationData.name,
        eventType: integrationData.event_type as EventType,
        url: integrationData.url,
      });
  }, [integrationData?.id]);

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
        keepMounted={false}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {integrationData ? "Edit Webhook" : "Add Webhook"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
        >
          <Container>
            <ContentContainer>
              <TextField
                id="outlined-basic"
                label="Name"
                name="name"
                variant="outlined"
                value={formValues.name}
                onChange={handleChange}
                error={!!errors?.name}
                helperText={errors?.name || ""}
              />
              <FormControl sx={{ marginTop: "20px" }}>
                <InputLabel htmlFor="bhi-simple-select">Event</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.eventType}
                  label="Event"
                  defaultValue={EventType.NewInboxMessage}
                  onChange={handleSelectChange}
                  name="eventType"
                  error={!!errors.eventType}
                >
                  <MenuItem
                    value={EventType.NewInboxMessage}
                    key={EventType.NewInboxMessage}
                  >
                    New Inbox Message
                  </MenuItem>
                  <MenuItem
                    value={EventType.UserJoinedGroup}
                    key={EventType.UserJoinedGroup}
                  >
                    User Joined Group
                  </MenuItem>
                  <MenuItem
                    value={EventType.UserOptedOut}
                    key={EventType.UserOptedOut}
                  >
                    User Opted Out
                  </MenuItem>
                </Select>
                {errors?.eventType && (
                  <FormHelperText error>{errors?.eventType}</FormHelperText>
                )}
              </FormControl>
              <TextField
                id="outlined-basic"
                style={{ marginTop: "20px" }}
                label="Webhook URL"
                name="url"
                variant="outlined"
                value={formValues.url}
                onChange={handleChange}
                error={!!errors?.url}
                helperText={errors?.url || ""}
              />
              <Button
                variant="text"
                disableRipple
                sx={{
                textTransform: 'none',
                padding: '0',
                margin: '0 !important',
                justifyContent: 'flex-start',
                  ml: 1,
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "transparent",
                  },
                }}
                onClick={toggleEventTypePayloadExample}
              >
                {showPayloadExample
                  ? "Hide Payload Example"
                  : "Show Payload Example"}{"  "}
                <HelpIcon fontSize="small" sx={{marginLeft: '5px'}} />
              </Button>
              {showPayloadExample && (
                <Box
                  component="section"
                  sx={{ p: 2, border: "1px dashed grey", fontSize: '12px' }}
                >
                  {payloadExamples[formValues.eventType]}
                </Box>
              )}
            </ContentContainer>
          </Container>
          {errors?.validation && (
            <FormHelperText error>{errors?.validation}</FormHelperText>
          )}
          <FooterContainer>
            {integrationData ? (
              <Button
                variant="contained"
                disabled={isButtonDisabled}
                style={{ width: "180px", marginTop: "20px" }}
                onClick={handleIntegrationEdit}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{ width: "180px", marginTop: "20px" }}
                onClick={handleAddIntegration}
                disabled={isButtonDisabled}
              >
                Add Webhook
              </Button>
            )}
          </FooterContainer>
        </DialogContent>
      </BootstrapDialog>
    </Fragment>
  );
}
