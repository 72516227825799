import { CreateAdminCompanyApiParams, CreateAdminCompanyPhoneNumberApiParams, DeleteAdminCompanyApiParams, DeleteAdminCompanyPhoneNumberApiParams, GetAdminCompaniesApiParams, GetAdminCompanyApiParams, GetAdminCompanyPhoneNumbersApiParams, UpdateAdminCompanyApiParams, UpdateAdminCompanyPhoneNumberApiParams } from './types';
import { useCallback } from 'react';
import apiClient from '../axiosClient';

export function useGetAdminCompaniesApi(): GetAdminCompaniesApiParams {
  return useCallback(params => apiClient.get(`/companies`, { params }), []);
}

export function useGetAdminCompanyApi(): GetAdminCompanyApiParams {
  return useCallback((conpanyId) => apiClient.get(`/companies/${conpanyId}`), []);
}

export function useCreateAdminCompanyApi(): CreateAdminCompanyApiParams {
  return useCallback((params) => apiClient.post(`/companies`, params), []);
}

export function useDeleteAdminCompanyApi(): DeleteAdminCompanyApiParams {
  return useCallback((companyId) => apiClient.delete(`companies/${companyId}`), []);
}

export function useUpdatedAdminCompanyApi(): UpdateAdminCompanyApiParams {
  return useCallback((params, companyId) => apiClient.put(`companies/${companyId}`, params), []);
}


export function useGetAdminCompanyPhoneNumbersApi(): GetAdminCompanyPhoneNumbersApiParams {
  return useCallback((conpanyId) => apiClient.get(`/companies/${conpanyId}/phone`), []);
}

export function useDeleteAdminCompanyPhoneNumberApi(): DeleteAdminCompanyPhoneNumberApiParams {
  return useCallback((companyId, phoneId) => apiClient.delete(`companies/${companyId}/phone/${phoneId}`), []);
}

export function useUpdateAdminCompanyPhoneNumberApi(): UpdateAdminCompanyPhoneNumberApiParams {
  return useCallback((companyId, phoneId, number) => apiClient.put(`companies/${companyId}/phone/${phoneId}`, {number}), []); 
}

export function useCreateAdminCompanyPhoneNumberApi(): CreateAdminCompanyPhoneNumberApiParams {
  return useCallback((companyId, number) => apiClient.post(`companies/${companyId}/phone/`, {number}), []); 
}

