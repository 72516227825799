import { Link } from '@mui/material';
import React from 'react';

const DownloadCSV = ({ data, fileName }: {data: string, fileName: string}) => {
  const formatHeaders = (headers: string) => {
    return headers + '\r\n';
  };

  const downloadCSV = () => {
    const csvData = new Blob([formatHeaders(data)], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.target ='_blank';
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Link sx={{textDecoration: 'none', cursor: 'pointer', textTransform: 'lowercase'}} onClick={downloadCSV}>here</Link>
  );
}

export default DownloadCSV;