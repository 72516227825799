import { Column } from "./types";

export const columns: Column[] = [
    { id: 'name', label: 'Groups', minWidth: 100 },
    { id: 'validContacts', label: 'Valid Contacts', minWidth: 100 },
    { id: 'invalidContacts', label: 'Invalid Contacts', minWidth: 100 },
    {
      id: 'createdAt',
      label: 'Created',
      minWidth: 100,
    },
    {
      id: 'updatedAt',
      label: 'Updated',
      minWidth: 100,
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      align: 'center',
    },
  ];
export const itemsPerPage = 25;

export enum ImportContactsModalStep {
  import,
  addToGroups
}

export const customFieldsLimit = 10000;

 