import { useCallback } from 'react';

import { useGetCompaniesApi } from "src/api/company";
import { setCompanies, setIsFetching, setIsError } from 'src/store/company/companySlice';
import { useDispatch } from 'react-redux';

export const useGetCompaniesService = () => {
    const dispatch = useDispatch();
    const searchCompaniesApi = useGetCompaniesApi();

    return useCallback(
        () => {
            dispatch(setIsFetching(true));
            dispatch(setIsError(false));
            searchCompaniesApi()
                .then(({data}) => {
                    dispatch(setCompanies(data));
                    dispatch(setIsFetching(false));
                    dispatch(setIsError(false));

                }).catch((e) => {
                    console.log(e);
                    setIsError(true);
                    dispatch(setIsFetching(false));
                  });
        },
        [dispatch, searchCompaniesApi]
    );
};

