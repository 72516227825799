import { ContactsCsvResponse } from "src/api/contacts/types";
import { Contact } from "src/constants/types";

export const generateSummary = ({ createdContacts, existedContacts, invalidContacts, repeatedContacts }: ContactsCsvResponse): string => {
    return `${createdContacts.length} contacts added, ${invalidContacts.length} invalid, ${repeatedContacts.length} duplicated, ${existedContacts.length} already existed.`;
}

export const getCreatedContactsIds = ( createdContacts: Contact[]): string[] => {
    if (!createdContacts) {
        return [];
    }

    return createdContacts.map(c => c.id);
}

export const getExistedContactsIds = ( existedContacts: Contact[]): string[] => {
    if (!existedContacts) {
        return [];
    }

    return existedContacts.map(c => c.id);
}
