import { GetPermissionsApiParams, GetProfileApiParams, UpdatePasswordApiParams, UpdateUserApiParams, GetRolesApiParams } from './types';
import { useCallback } from 'react';
import apiClient from '../axiosClient';

/**
 * @function useGetPermissionsApi
 * @returns { GetPermissionsApiParams }
 */
export function useGetPermissionsApi(): GetPermissionsApiParams {
  return useCallback(params => apiClient.get(`/users/permissions`, {params}), []);
}

/**
 * @function useGetRolesApi
 * @returns { GetRolesApiParams }
 */
export function useGetRolesApi(): GetRolesApiParams {
  return useCallback(() => apiClient.get(`/roles`), []);
}

/**
 * @function useGetProfileApi
 * @returns { GetProfileApiParams }
 */
export function useGetProfileApi(): GetProfileApiParams {
  return useCallback(params => apiClient.get(`/users/me`, {params}), []);
}

/**
 * @function useUpdatePasswordApi
 * @returns { UpdatePasswordApiParams }
 */
export function useUpdatePasswordApi(): UpdatePasswordApiParams {
  return useCallback((userId, params) => apiClient.put(`/users/${userId}/password`, params), []);
}

/**
 * @function useUpdateUserApi
 * @returns { UpdateUserApiParams }
 */
export function useUpdateUserApi(): UpdateUserApiParams {
  return useCallback((userId, params) => apiClient.put(`/users/${userId}`, params), []);
}