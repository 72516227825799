import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "./constants";
import toast from "react-hot-toast";

// Define the structure of a retry queue item
interface RetryQueueItem {
  resolve: (value?: unknown) => void;
  reject: (error?: unknown) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;
let hasError = false;

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

const refreshAccessToken = async (token: string) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/refresh-token`, {
      token,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("a_token");
    if (token) {
      config.headers["Authorization"] = `JWT ${token}`;
    }
    config.validateStatus = (status) => {
      if ([404, 500, 502].includes(status)) {
        if (!hasError) {
          hasError = true;
          toast.error("Something went wrong. Please try again.");
          return false;
        }
        return false;
      }

      return status >= 200 && status < 300;
    };
    return config;
  },
  (error) => {
    if (!hasError) {
      hasError = true;
      toast.error("Something went wrong. Please try again.");
    }
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;
        const token = localStorage.getItem("r_token");
        if (token) {
          try {
            const data = await refreshAccessToken(token);

            if (data?.accessToken && data.refreshToken) {
              localStorage.setItem("a_token", data.accessToken);
              localStorage.setItem("r_token", data.refreshToken);
              axios.defaults.headers.common[
                "Authorization"
              ] = `JWT ${data.accessToken}`;

              // Retry all requests in the queue with the new token
              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                apiClient
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });

              // Clear the queue
              refreshAndRetryQueue.length = 0;

              return apiClient(originalRequest);
            }
          } catch {
            return Promise.reject(error.response.data);
          } finally {
            isRefreshing = false;
          }
        } else {
          isRefreshing = false;
          console.log(error);
        }
      }

      // Add the original request to the queue
      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }
    if (!hasError) {
      hasError = true;
      toast.error("Something went wrong. Please try again.");
    }
    return Promise.reject(error.response.data);
  }
);

export default apiClient;
