import React from "react";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import DescriptionIcon from "@mui/icons-material/Description";
import EventIcon from "@mui/icons-material/Event";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import VideoFileIcon from "@mui/icons-material/VideoFile";

function renderFileIconByExtensionOrMime(options: {url: string, extOrMime?: string, width?: string
}): React.JSX.Element {
    const w = options.width ? options.width : '100%';
    switch (options.extOrMime) {
        case 'vcf':
        case 'text/vcard':
        case 'text/x-vcard':
            return <ContactPhoneIcon/>;
        case 'txt':
        case 'text/plain':
        case 'pdf':
        case 'application/pdf':
            return <DescriptionIcon/>;
        case 'ics':
        case 'text/calendar':
            return <EventIcon/>;
        case 'm4a':
        case 'audio/x-m4a':
            return <AudioFileIcon/>;
        case '3pg':
        case 'video/3gpp':
        case 'mp4':
        case 'video/mp4':
            return <VideoFileIcon/>;
        default:
            return (<img
                width={w}
                height="auto"
                src={options.url}
                alt="File"
            />);
    }
}

const ChatFileIcon = ({url, mimeType, width}: {
    url: string;
    mimeType?: string | null;
    width?: string;
}) => {
    if (mimeType) {
        return renderFileIconByExtensionOrMime({url, extOrMime: mimeType, width});
    }
    const match = /\.(.{0,5})$/.exec(url);
    if (match && match[1]) {
        return renderFileIconByExtensionOrMime({url, extOrMime: match[1], width});
    }
    return renderFileIconByExtensionOrMime({url, width});
};

export default ChatFileIcon;
