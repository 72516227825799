import { Column } from "./types";

export const columns: Column[] = [
    { id: 'firstName', label: 'First Name', minWidth: 100 },
    { id: 'lastName', label: 'Last Name', minWidth: 100 },
    { id: 'email', label: 'Email', minWidth: 150 },
    { id: 'phoneNumber', label: 'Phone Number', minWidth: 150 },
    { id: 'role', label: 'Role', minWidth: 100 },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 150,
      align: 'center',
    },
  ];

export const itemsPerPage = 25;

 