import * as yup from 'yup';

// const allowedMimeTypes = [
//   'text/plain',
//   'text/vcard',
//   'image/jpeg',
//   'image/png',
//   'image/gif',
//   'video/3gpp',
//   'video/mp4',
// ];

const disallowedDomains = [
  'bit.ly',
  'bit.com',
  'goo.gl',
  'tinyurl.com',
  'tiny.cc',
  'lc.chat',
  'is.gd',
  'soo.gd',
  's2r.co',
  'clicky.me',
  'budurl.com'
];

const domainRegex = /https?:\/\/(www\.)?([^/]+)/i
export const acceptTypes = {
  'text/plain': ['.txt'],
  'text/vcard': ['.vcf'],
  'text/calendar': ['.ics'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'video/3gpp': ['.3gp'],
  'video/mp4': ['.mp4'],
  'application/pdf': ['.pdf'],
  'audio/mp4': ['.m4a'],
};

export const urlValidateSchema = yup.object().shape({
  url: yup.string()
    .required('URL is required')
    .url('URL must be valid')
    .test(
      'is-not-disallowed-domain',
      'The attached URL is unable to be used as part of your message due to deliverability issues with carriers',
      (url) => {
        const match = url?.match(domainRegex);
        const domain = match ? match[2] : '';
        if (disallowedDomains.some(disallowedDomain => domain.includes(disallowedDomain))) {
          return false
        }
        return true;
      }
    )
});
// .test(
//   'is-valid-mime-type',
//   'URL must point to a valid file format.',
//   async (url) => {
//     if (!url) return false;

//     try {
//       const response = await fetch(url, { method: 'HEAD' });
//       const contentType = response.headers.get('content-type') || '';
//       return allowedMimeTypes.some(type => contentType.includes(type));
//     } catch (error) {
//       console.error('Error checking MIME type:', error);
//       return false;
//     }
//   }
// ).test(
//   'is-within-size-limit',
//   'File size must not exceed 5 MB.',
//   async (url) => {
//     try {
//       const response = await fetch(url, { method: 'HEAD' });
//       const contentLength = response.headers.get('content-length') || '0';
//       const fileSize = parseInt(contentLength, 10);
//       return fileSize <= maxFileSize;
//     } catch (error) {
//       console.error('Error checking file size:', error);
//       return false;
//     }
//   }
// );


