import React, { SyntheticEvent, CSSProperties, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  Button,
  Paper,
} from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
type SelectWithSearchProps = {
  label: string;
  placeholder: string;
  options: { text: string; value: string }[];
  values: string[];
  handleAddItem?: (text: string) => void;
  handleSelectChange: (
    event: SyntheticEvent<Element, Event>,
    value: { text: string; value: string, hasWarning?: boolean }[]
  ) => void;
  style: CSSProperties;
};

function SelectWithSearch({
  label,
  placeholder,
  options,
  values,
  handleSelectChange,
  style,
  handleAddItem,
}: SelectWithSearchProps) {
  const [inputValue, setInputValue] = useState("");
  const filter = createFilterOptions();
  const selectedValues = React.useMemo(
    () => options.filter((v) => values.includes(v.value)),
    [values]
  );

  const addItem = (item: string) => {
    if (handleAddItem) {
      handleAddItem(item);
    }
  };

  return (
    <Autocomplete
      multiple
      style={style}
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      noOptionsText={"No options"}
      getOptionLabel={(option: {
        text: string;
        value: string;
        hasWarning?: boolean;
      }) => {
        return option.hasWarning ?  `${option.text} ⚠` : option.text;
      }}
      value={selectedValues}
      filterOptions={(options: unknown[], params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        setInputValue(inputValue);
        return filtered;
      }}
      renderOption={(props, option: { text: string }, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.text}
        </li>
      )}
      PaperComponent={({ children }) => {
        return (
          <Paper>
            {children}
            {handleAddItem &&
              inputValue &&
              !options.find((v) => v.text === inputValue) && (
                <Button
                  sx={{
                    marginLeft: "25px",
                    marginBottom: "25px",
                    marginTop: "25px",
                  }}
                  variant="contained"
                  onMouseDown={() => {
                    addItem(inputValue);
                  }}
                >
                  Add New Item
                </Button>
              )}
          </Paper>
        );
      }}
      onChange={handleSelectChange}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
    />
  );
}

export default SelectWithSearch;
