import { ForgotPasswordApiParams, LoginApiParams, SetPasswordApiParams } from './types';
import { useCallback } from 'react';
import apiClient from '../axiosClient';

/**
 * @function useLoginApi
 * @returns { LoginApiParams }
 */
export function useLoginApi(): LoginApiParams {
  return useCallback(params => apiClient.post(`/auth/token`, params), []);
}

/**
 * @function useForgotPasswordApi
 * @returns { ForgotPasswordApiParams }
 */
export function useForgotPasswordApi(): ForgotPasswordApiParams {
  return useCallback(params => apiClient.post(`/auth/forgot-password`, params), []);
}

/**
 * @function useSetPasswordApi
 * @returns { SetPasswordApiParams }
 */
export function useSetPasswordApi(): SetPasswordApiParams {
  return useCallback(params => apiClient.put(`/auth/set-password`, params), []);
}
