import React, { useState, useContext } from 'react';
import { StyledHeader } from './styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AuthContext } from 'src/contexts/AuthContext';
import { selectedCompany, selectCompanies } from 'src/store/company/companySlice';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCompany } from 'src/store/company/companySlice';
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { selectProfile } from 'src/store/profile/profileSlice';

function Header() {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const authContext = useContext(AuthContext);
  const selectedCompanyId = useSelector(selectedCompany);
  const { profile } = useSelector(selectProfile);
  const isBhiAdmin = profile?.type === 'admin';
  const companies = useSelector(selectCompanies);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogout = () => {
    authContext?.setIsAuthenticated(false);
    localStorage.removeItem('a_token');
    localStorage.removeItem('r_token');
    localStorage.removeItem('bhiRefreshingToken');
    dispatch({ type: 'logout' });
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleChange = (e: SelectChangeEvent) => {
    dispatch(setSelectedCompany(e.target.value));
  }

  return <StyledHeader>
    {selectedCompanyId && <FormControl style={{width: '200px', marginRight: '20px'}} variant="standard">
        <InputLabel id="demo-simple-select-label">Company</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedCompanyId}
          defaultValue={selectedCompanyId}
          label="Company"
          onChange={handleChange}
        >
          {companies.map(({id, name}) => {
            return <MenuItem value={id} key={id}>{name}</MenuItem>
          })}
        </Select>
      </FormControl>}
    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
      <AccountCircleIcon sx={{ fontSize: 40 }} />
    </IconButton>
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
        <MenuItem key={'profile'} onClick={() => navigate('/profile')}>
          <Typography textAlign="center">{'My Profile'}</Typography>
        </MenuItem>
        {isBhiAdmin &&<MenuItem key={'companies'} onClick={() => navigate('/admin/companies')}>
          <Typography textAlign="center">{'Admin'}</Typography>
        </MenuItem>}
        <MenuItem key={'logout'} onClick={handleLogout}>
          <Typography textAlign="center">{'Log out'}</Typography>
        </MenuItem>
    </Menu>
  </StyledHeader>
}

export default Header;
