import React from "react";

import {
  Container,
  Stack,
  Box,
  Typography,
  IconButton,
  Popover,
} from "@mui/material";
import dayjs from "dayjs";
import {InboxContactChatMessage, FileLinkWithMimeType} from "src/constants/types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChatFileIcon from "../../../components/ChatFileIcon/view";

type MessageProps = {
  message: InboxContactChatMessage;
  markMessageAsUnread: (message: InboxContactChatMessage) => void;
  hoverMessageId: string | undefined;
  setHoverMessageId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const Message = ({
  message,
  markMessageAsUnread,
  hoverMessageId,
  setHoverMessageId,
}: MessageProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `message-context-menu-${message.id}` : undefined;

  return (
    <Container
      maxWidth={false}
      onMouseEnter={() => {
        setHoverMessageId(message.id);
      }}
    >
      <Stack
        key={message.id}
        direction="row"
        justifyContent={message.sender === "contact" ? "start" : "end"}
        alignItems="center"
      >
        <Box
          px={1.5}
          py={1.5}
          sx={{
            backgroundColor: message.sender === "contact" ? "#92A1CB" : "#FFF",
            borderRadius: 1.5,
            maxWidth: "50%",
          }}
        >
          <Box>
            {message.linksWithMimeType?.map((linkWithMime: FileLinkWithMimeType, index) =>
                <ChatFileIcon url={linkWithMime.url} mimeType={linkWithMime.mimeType} key={index}/>
            )}
          </Box>
          <Typography
            sx={{
              wordBreak: "break-word",
              whiteSpace: "pre-wrap",
            }}
            variant="body2"
            color={message.sender === "contact" ? "#fff" : "#000"}
          >
            {message.text}
          </Typography>
          <Box textAlign="right">
            <Typography
              variant="caption"
              sx={{
                color: "#ccc",
                fontSize: 10,
              }}
            >
              {dayjs(message.dateCreated).fromNow()}
            </Typography>
          </Box>
        </Box>

        {message.isRead && (
          <>
              <IconButton onClick={handleClick}>
                <MoreVertIcon
                  sx={{
                    cursor: "pointer",
                    visibility:
                      message.id === hoverMessageId ? "visible" : "hidden",
                  }}
                />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                 <Typography
                    sx={{ cursor: "pointer", padding: 1 }}
                    onClick={() => {
                      markMessageAsUnread(message);
                    }}
                  >
                    Mark as Unread
                  </Typography>
              </Popover>
          </>
        )}
      </Stack>
    </Container>
  );
};

export default Message;
