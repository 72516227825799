import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

interface PrivateRouteProps {
    children: JSX.Element;
}

function PrivateRoute ({ children }: PrivateRouteProps) {
    const authContext = useContext(AuthContext);
    
    if (!authContext) return null;
    return authContext.isAuthenticated ? children : <Navigate to="/login" replace />;
}

export default PrivateRoute;
