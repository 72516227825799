import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import MessageIcon from "@mui/icons-material/Message";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {PeopleAlt} from "@mui/icons-material";

export const sidebarItems = [
  {
    text: "Inbox",
    icon: <QuestionAnswerIcon />,
    path: "/inbox",
  },
  {
    text: "Campaigns",
    icon: <MessageIcon />,
    path: "/campaigns",
  },
  {
    text: "Contacts",
    icon: <PeopleAlt />,
    path: "/groups",
  },
  {
    text: "Keywords",
    icon: <TextSnippetIcon />,
    path: "/keywords",
  },
  {
    text: "Settings",
    icon: <SettingsIcon />,
    path: "/settings",
  },
];
