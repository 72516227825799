import React, { Fragment, useState, useEffect, SyntheticEvent } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { ContentContainer } from './styles';
import Button from '@mui/material/Button';
import { groupValidationSchema, ValidationErrors } from 'src/constants/validations';
import { Group } from 'src/constants/types';
import * as Yup from 'yup';
import { useCreateGroupApi, useUpdateGroupApi } from 'src/api/contacts';
import { useSelector } from 'react-redux';
import { selectedCompany } from 'src/store/company/companySlice';
import { useGetContactsApi } from 'src/api/contacts';
import { Contact } from 'src/constants/types';
import { useAssignContactsApi, useRemoveContactsApi } from 'src/api/contacts';
import SelectWithSearch from 'src/components/SelectWithSearch/view';
import TextFieldWithCopyIcon from "../../../components/TextFieldWithCopyIcon/view";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        width: '700px',
        height: '700px'
    },
}));

type CreateGroupModalType = {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    groupData?: Group;
    refreshGroups: () => void;
}

const initialFormValues = { name: '', description: '', contactIds: [] };
export default function CreateGroupModal({ show, setShowModal, groupData, refreshGroups }: CreateGroupModalType) {
    const [formValues, setFormValues] = useState<{ name: string, description: string, contactIds: string[] }>(initialFormValues);
    const [errors, setErrors] = useState<ValidationErrors>({});
    const [contacts, setContacts] = useState<Contact[]>()
    const createGroup = useCreateGroupApi();
    const updateGroup = useUpdateGroupApi();
    const selectedCompanyId = useSelector(selectedCompany);
    const getContacts = useGetContactsApi();
    const [existingContactIds, setExistingContactIds] = React.useState<string[]>([]);
    const assignContacts = useAssignContactsApi();
    const removeContacts = useRemoveContactsApi();

    const handleSelectChange = (event: SyntheticEvent<Element, Event>, items: { text: string; value: string; }[]) => {
        const contactIds = items?.map(({value}) => value) || [];
        setFormValues({ ...formValues, contactIds })
    };

    useEffect(() => {
        if (show && selectedCompanyId) {
            getContacts({
                companyId: selectedCompanyId,
                limit: 1000,
                offset: 0
            }).then(({ data }) => {
                setContacts(data.items)
                groupData?.id && getContacts({
                    companyId: selectedCompanyId,
                    limit: 1000,
                    groups: [groupData.id].toString(),
                    offset: 0
                }).then(({ data }) => {
                    const groupContacts = data?.items?.map(({ id }) => id);
                    setExistingContactIds(groupContacts);
                    setFormValues({ ...formValues, ...groupData, contactIds: groupContacts });
                }).catch((e) => {
                    console.log(e)
                })
            }).catch((e) => {
                console.log(e)
            });
            
        }

    }, [selectedCompanyId, show, groupData?.id]);

    const handleClose = () => {
        setShowModal(false);
    }

    const handleGroupSave = async () => {
        const isValid = await validate(formValues);
        if (isValid) {
            if (groupData?.id) {
                selectedCompanyId && updateGroup({ name: formValues.name, description: formValues.description || '' }, groupData?.id, selectedCompanyId).then(() => {
                    if (groupData?.id && formValues.contactIds) {
                        const addedIds = formValues.contactIds.filter((id) => !existingContactIds.includes(id));
                        const removedIds = existingContactIds.filter((id) => !formValues.contactIds.includes(id));
                        Promise.all([addedIds.length && assignContacts(addedIds, groupData?.id, selectedCompanyId as string),removedIds.length && removeContacts(removedIds, groupData?.id, selectedCompanyId as string)]).then(() => {
                            handleClose()
                            refreshGroups();
                        })
                    } else {
                        handleClose()
                        refreshGroups();
                    }
                })
            } else {
                selectedCompanyId && createGroup({ name: formValues.name, description: formValues.description || '', type: 'custom' }, selectedCompanyId).then(({ data }) => {
                    if (data.id && formValues.contactIds.length) {
                        assignContacts(formValues.contactIds, data.id, selectedCompanyId as string).then(() => {
                            handleClose()
                            refreshGroups();
                        })
                    } else {
                        handleClose()
                        refreshGroups();
                    }
                })
            }
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const validate = async (values: typeof formValues) => {
        try {
            await groupValidationSchema.validate(values, { abortEarly: false });
            setErrors({});
            return true;
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const newErrors = err.inner?.reduce((acc, curr) => {
                    if (curr.path) {
                        acc[curr.path] = curr?.message;
                    }
                    return acc;
                }, {} as ValidationErrors);
                setErrors(newErrors);
            }
            return false;
        }
    };

    const formatContactText = (firstName: string, lastName: string, phone: string): string => {
        return firstName && lastName ? firstName + ' ' + lastName : phone;
    }

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={show}
                fullWidth={true}
                keepMounted={false}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" >
                    {groupData ? 'Edit ' : 'Create '} Group
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <ContentContainer>
                        <TextField id="outlined-basic" label="Name" name="name" variant="outlined" value={formValues.name} onChange={handleChange} error={!!errors.name} helperText={errors?.name || ''} />
                        {groupData?.id && <TextFieldWithCopyIcon
                          disabled={true}
                            id="outlined-multiline-static"
                            label="Group ID"
                            name="de456scription"
                            style={{ margin: '15px 0px' }}
                            value={groupData?.id}
                        />}
                        <TextField
                            id="outlined-multiline-static"
                            label="Description"
                            name="description"
                            multiline
                            rows={4}
                            style={{ margin: '15px 0px' }}
                            value={formValues.description}
                            onChange={handleChange}
                        />
                        <SelectWithSearch
                            label='Contacts'
                            placeholder='Contacts' 
                            options={contacts?.map(({ firstName, lastName, phoneNumber, id }) => ({text: formatContactText(firstName, lastName, phoneNumber), value: id})) || []}
                            values={formValues.contactIds}
                            handleSelectChange={handleSelectChange}
                            style={{ width: '100%', margin: '0 0 20px 0', maxHeight: '120px', paddingTop: '10px', overflow: 'auto' }}
                        />
                        <Button variant="contained" style={{ width: '150px' }} onClick={handleGroupSave}>Save</Button>
                    </ContentContainer>
                </DialogContent>
            </BootstrapDialog>
        </Fragment>
    );
}
