import React, { useState, useEffect, useRef } from "react";
import MainWrapper from "src/components/MainWrapper/view";
import { PageHeader, HeaderContainer } from "./styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { columns, itemsPerPage } from "./costants";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { Keyword } from "src/constants/types";
import { useGetKeywordsApi } from "src/api/keywords";
import { getDateTimeUSAFormat } from "src/constants/helpers";
import { useDeleteKeywordApi } from "../../api/keywords";
import CreateKeywordModal from "./CreateKeywordModal/view";
import { firstLetterCamelCase } from "../../constants/helpers";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";
import SearchInput from "../../components/SearchInput/view";
import SortIcon from "../../components/SortIcon/view";
import { GetKeywordsParams } from "../../api/keywords/types";

function Keywords() {
  const [page, setPage] = useState(0);
  const tableRef = useRef<HTMLTableElement>(null);
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortDir, setSortDir] = useState<"asc" | "desc">("desc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showCreateKeywordModal, setCreateKeywordModal] = useState(false);
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  const [keywords, setKeywords] = useState<{
    count: number;
    items: Keyword[] | null;
  }>({ count: 0, items: null });
  const [editableKeyword, setEditableKeyword] = useState<Keyword | undefined>();
  const getKeywords = useGetKeywordsApi();
  const deleteKeyword = useDeleteKeywordApi();
  const { openDialog, ConfirmationDialog } = useConfirmationDialog();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };

  const formatCreatorText = (
    firstName: string,
    lastName: string,
    email: string
  ): string => {
    return email === "adminbhi@mailinator.com"
      ? "System"
      : firstName + " " + lastName; //TODO check with type
  };

  const formatTableRows = (keywords: Keyword[]) => {
    return keywords.map(
      ({ id, name, type, dateCreated, campaigns, creator }: Keyword) => {
        return {
          id,
          name: firstLetterCamelCase(name),
          type: type,
          createdAt: getDateTimeUSAFormat(new Date(dateCreated)),
          campaigns:
            campaigns
              ?.slice(0, 3)
              .map((item) => item.name)
              .join(", ") || "-",
          creator: formatCreatorText(
            creator.firstName,
            creator.lastName,
            creator.email
          ),
          actions: "edit/delete",
        };
      }
    );
  };

  const refreshKeywords = () => {
    setEditableKeyword(undefined);
    if (selectedCompanyId) {
      const reqQuery: GetKeywordsParams = {
        companyId: selectedCompanyId,
        limit: itemsPerPage,
        offset: page * itemsPerPage,
        sort: `${getSortFieldForApi(sortField)}_${sortDir}`,
      };
      if (searchQuery) {
        reqQuery.query = searchQuery;
      }
      getKeywords(reqQuery)
        .then(({ data }) => {
          setKeywords(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const mapSortPropsToApi: Record<string, string> = {
    name: "name",
    type: "type",
    createdAt: "date_created",
  };

  const isFieldSortable = (fieldName: string) =>
    Object.prototype.hasOwnProperty.call(mapSortPropsToApi, fieldName);

  const getSortFieldForApi = (fieldName: string) => {
    return mapSortPropsToApi[fieldName];
  };

  const swapSortDirection = (dir?: "asc" | "desc") => {
    if (dir) {
      setSortDir(dir);
    } else {
      setSortDir(sortDir == "asc" ? "desc" : "asc");
    }
  };

  const changeSortField = (fieldName: string) => {
    if (sortField == fieldName) {
      swapSortDirection();
    } else {
      swapSortDirection("desc");
    }
    setSortField(fieldName);
  };

  const handleSearchQuery = (text: string) => {
    setSearchQuery(text);
  };

  useEffect(() => {
    refreshKeywords();
  }, [page, selectedCompanyId, sortDir, sortField, searchQuery]);

  useEffect(() => {
    setPage(0);
    tableRef?.current?.scrollIntoView();
  }, [sortDir, sortField, searchQuery]); 

  const handleKeywordEdit = (id: string) => {
    keywords?.items &&
      setEditableKeyword(keywords.items?.find((item) => item.id === id));
    setCreateKeywordModal(true);
  };

  const handleKeywordDelete = (id: string) => {
    selectedCompanyId &&
      deleteKeyword(id, selectedCompanyId).then(refreshKeywords);
  };

  return (
    <MainWrapper>
      <PageHeader>Keywords</PageHeader>
      <HeaderContainer>
        <SearchInput handleSearch={handleSearchQuery} />
        <Button
          variant="contained"
          onClick={() => {
            setCreateKeywordModal(true);
            setEditableKeyword(undefined);
          }}
        >
          Create Keyword
        </Button>
      </HeaderContainer>
      <CreateKeywordModal
        show={showCreateKeywordModal}
        setShowModal={setCreateKeywordModal}
        keywordData={editableKeyword}
        refreshKeywords={refreshKeywords}
      />

      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer sx={{ height: "calc(100vh - 250px)" }}>
          <Table ref={tableRef}  stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    onClick={() =>
                      isFieldSortable(column.id)
                        ? changeSortField(column.id)
                        : {}
                    }
                    align={column.align}
                    width={
                      column.id === "name"
                        ? "10%"
                        : column.id === "createdAt"
                        ? "5%"
                        : column.id === "campaigns"
                        ? "20%"
                        : column.id === "actions"
                        ? "20%"
                        : "10%"
                    }
                    style={{
                      background: "#254597",
                      color: "#fff",
                      cursor: isFieldSortable(column.id)
                        ? "pointer"
                        : "initial",
                    }}
                  >
                    {column.label}
                    {sortField == column.id && <SortIcon dir={sortDir} />}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {keywords?.items &&
                formatTableRows(keywords?.items).map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        const cellWidth =
                          column.id === "name"
                            ? "10%"
                            : column.id === "createdAt"
                            ? "5%"
                            : column.id === "campaigns"
                            ? "20%"
                            : column.id === "actions"
                            ? "20%"
                            : "10%";
                        return (
                          <TableCell
                            width={cellWidth}
                            key={column.id}
                            align={column.align}
                          >
                            {column.id === "actions" ? (
                              row.type !== "default" ? (
                                <>
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleKeywordEdit(row.id);
                                    }}
                                    disabled={row.type === "default"}
                                  >
                                    <EditIcon />
                                  </Button>
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      openDialog({
                                        title: `Delete "${row.name}"`,
                                        content:
                                          "Are you sure you want to delete this keyword?",
                                        onConfirm: () => {
                                          handleKeywordDelete(row.id);
                                        },
                                      });
                                    }}
                                    disabled={row.type === "default"}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </>
                              ) : null
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={keywords.count}
          rowsPerPageOptions={[-1]}
          rowsPerPage={itemsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
      <ConfirmationDialog />
    </MainWrapper>
  );
}

export default Keywords;
