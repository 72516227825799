import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useCreateContactsApi } from "src/api/contacts";
import { useGetInboxContactsApi } from "src/api/inbox";
import { Group } from "src/constants/types";
import { selectedCompany } from "src/store/company/companySlice";

type ContactsSearchProps = {
  onSelectContact: React.Dispatch<React.SetStateAction<string>>;
  setIsChatLoading: React.Dispatch<SetStateAction<boolean | undefined>>;
  refreshContacts:React.Dispatch<SetStateAction<void>>;
  group: Group | undefined;
};

type ContactType = {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

const ContactsSearch = ({
  onSelectContact,
  setIsChatLoading,
  group,
  refreshContacts,
}: ContactsSearchProps) => {
  const searchInputRef = useRef<HTMLDivElement | HTMLTextAreaElement>(null);
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  const [hideHeader, setHideHeader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [contacts, setContacts] = useState<ContactType[]>();
  const [isContactsLoading, setIscontactsLoading] = useState<boolean>(false);
  const getAllContacts = useGetInboxContactsApi();
  const createContacts = useCreateContactsApi();

  const isValidPhoneNumber = (phone: string | undefined): boolean => {
    if (!phone) {
      return false;
    }

    const trimmedPhone = phone.replace("Add ", "").trim();
    if (!trimmedPhone) {
      return false;
    }

    const phonenumberRegexWithoutPlus = /^[+1]\d{11}$/;
    const phonenumberRegexWithPlus = /^\d{10}$/;
    return (
      new RegExp(phonenumberRegexWithoutPlus).test(trimmedPhone.toString()) ||
      new RegExp(phonenumberRegexWithPlus).test(trimmedPhone.toString())
    );
  };

  useEffect(() => {
    setContacts([]);
  }, [selectedCompanyId]);

  useEffect(() => {
    if (!group) {
      return;
    }

    setIscontactsLoading(true);
    const searchParams = search
      ? {
          query: search,
          companyId: selectedCompanyId!,
          limit: 100,
          offset: 0,
        }
      : {
          companyId: selectedCompanyId!,
          limit: 100,
          offset: 0,
        };
    getAllContacts(searchParams)
      .then(({ data }) => {
        if (data) {
          const contacts: ContactType[] = data.items.map((c) => ({
            id: c.contact.id,
            firstName: c.contact.firstName,
            lastName: c.contact.lastName,
            phoneNumber: c.contact.phoneNumber,
          }));
          setContacts(contacts);
          setIscontactsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [search]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  if (hideHeader) {
    return <Box sx={{ height: "120px" }}></Box>;
  }

  return (
    <>
      <Autocomplete
        sx={{ width: "100%", height: "120px" }}
        disablePortal
        freeSolo
        onChange={(e, option) => {
          const contactId = typeof option === "string" ? option : option?.id;
          if (contacts && contactId) {
            if (contacts.find((c) => c.id === contactId)) {
              onSelectContact(contactId!);
            }
          } else {
            if (
              isValidPhoneNumber(
                typeof option === "string"
                  ? option
                  : "+1" +
                      option?.phoneNumber
                        .replace("Add ", "")
                        .trim()
                        .replace("+1", "")
              )
            ) {
              setHideHeader(true);
              setIsChatLoading(true);
              const phone =
                typeof option === "string" ? option : option?.phoneNumber;
              const phoneNumber =
                "+1" + phone?.replace("Add ", "").trim().replace("+1", "");
              const newContact = {
                email: "",
                firstName: "",
                lastName: "",
                birthdate: "",
                timeZone: "",
                phoneNumber: phoneNumber,
              };
              createContacts([newContact], selectedCompanyId!).then((data) => {
                setIsChatLoading(true);
                refreshContacts(() => {
                  onSelectContact(data.data[0].id);
                });
              });
            }
          }
        }}
        disabled={isContactsLoading === undefined}
        id="constacts-search"
        options={contacts || []}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.phoneNumber) {
            return option.phoneNumber;
          }
          return option.phoneNumber;
        }}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              onClick={() => {
                if (!option.id && isValidPhoneNumber(option.phoneNumber)) {
                  const phoneNumber =
                    "+1" +
                    option.phoneNumber
                      .replace("Add ", "")
                      .trim()
                      .replace("+1", "");
                  const newContact = {
                    email: "",
                    firstName: "",
                    lastName: "",
                    birthdate: "",
                    timeZone: "",
                    phoneNumber: phoneNumber,
                  };
                  setIsChatLoading(true);
                  createContacts([newContact], selectedCompanyId!).then(
                    (data) => {
                      setIsChatLoading(true);
                      refreshContacts(() => {
                        onSelectContact(data.data[0].id);
                      });
                    }
                  );
                } else {
                  onSelectContact(option.id);
                }
              }}
              key={option.phoneNumber}
            >
              {option.firstName || ""} {option.lastName || ""}{" "}
              {option.phoneNumber}
            </li>
          );
        }}
        size="small"
        filterOptions={(options, params) => {
          const filtered: ContactType[] = options.filter(
            (m) =>
              m &&
              (m.firstName
                ?.toUpperCase()
                .includes(params.inputValue.toUpperCase()) ||
                m.lastName
                  ?.toUpperCase()
                  .includes(params.inputValue.toUpperCase()) ||
                m.phoneNumber
                  ?.toUpperCase()
                  .includes(params.inputValue.toUpperCase()))
          );
          if (
            params.inputValue !== "" &&
            !filtered.length &&
            isValidPhoneNumber(params.inputValue.replace("+", ""))
          ) {
            filtered.push({
              phoneNumber: `Add +1${params.inputValue.replace("+", "")}`,
              firstName: "",
              lastName: "",
              id: "",
            });
          }

          return filtered;
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            inputRef={searchInputRef}
            placeholder="Search Contacts..."
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            style={{ justifyContent: "center", alignItems: "center" }}
            sx={{ backgroundColor: "#EDEDED", border: "none", height: "60px" }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              ...params.InputProps,
              startAdornment: <Box sx={{ padding: 1 }}>To:</Box>,
              endAdornment: (
                <React.Fragment>
                  {isContactsLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default ContactsSearch;
