import { useCallback } from 'react';

import { useGetPermissionsApi, useGetProfileApi, useGetRolesApi } from "src/api/profile";
import { setPermissions } from 'src/store/permission/permissionSlice';
import { setProfile } from 'src/store/profile/profileSlice';
import { setRoles } from 'src/store/profile/roleSlice';
import { useDispatch } from 'react-redux';
import { useGetUserApi } from 'src/api/users';

export const useGetPermissionsService = () => {
    const dispatch = useDispatch();
    const getPermissionsApi = useGetPermissionsApi();

    return useCallback(
        (companyId: string) => {
            getPermissionsApi({ companyId })
                .then(({ data }) => {
                    dispatch(setPermissions(data.permissions));
                })
                .catch((error) => {
                    console.log(error)
                });
        },
        [dispatch, getPermissionsApi]
    );
};


export const useGetProfileService = () => {
    const dispatch = useDispatch();
    const getProfileApi = useGetProfileApi();
    const getUserApi = useGetUserApi();

    return useCallback(
        (companyId: string) => {
            getProfileApi({ companyId })
                .then(({ data }) => {
                    if (data.id) {
                        getUserApi(data.id, companyId).then(({ data: userData }) => { dispatch(setProfile(userData)); })
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        },
        [dispatch, getProfileApi]
    );
};

export const useGetRolesService = () => {
    const dispatch = useDispatch();
    const getRoles = useGetRolesApi();

    return useCallback(
        () => {
            getRoles()
                .then(({ data }) => {
                    dispatch(setRoles(data));
                })
                .catch((error) => {
                    console.log(error)
                });
        },
        [dispatch, getRoles]
    );
};