import styled from "styled-components";

export const PageHeader = styled.div`
  font-size: 23px;
  color: #254597;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: end;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

export const TextCopyToClipboard = styled.div`
  display: inline-block;

  &.grow {
    svg {
      visibility: visible;
      animation: hithere 400ms ease infinite;
      color: black;
    }
  }

  svg {
    visibility: hidden;
    position: absolute;
    height: 20px;
    top: 0;
    right: -25px;
    color: lightgray;
    @keyframes hithere {
      30% {
        transform: scale(1.2);
      }
      40%,
      60% {
        transform: rotate(-20deg) scale(1.2);
      }
      50% {
        transform: rotate(20deg) scale(1.2);
      }
      70% {
        transform: rotate(0deg) scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;
export const CampaignIdCopy = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;

  &:hover {
    svg {
      visibility: visible;
    }
  }
`;

export const CampaignIDCopy = styled.div`
  //top: 10px;
  //position: absolute;
`;
