import React from "react";
import { Box, IconButton, TextField } from "@mui/material";
import { InboxContact } from "src/constants/types";
import EditIcon from "@mui/icons-material/Edit";

type ChatHeaderProps = {
  contact: InboxContact | null;
  handleContactEdit: (contactId: string) => void;
};

const ChatHeader = ({ contact, handleContactEdit }: ChatHeaderProps) => {
  if (!contact) {
    return null;
  }

  const formatName = (): string => {
    if (contact.contact.firstName || contact.contact.lastName) {
        return `${contact.contact.firstName || ""} ${contact.contact.lastName || ""}`;
    }

    return contact.contact.phoneNumber;
  }

  return (
    <>
      <Box
        width={"100%"}
        sx={{
          maxHeight: "60%",
          backgroundColor: "#F8FAFF",
          boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.25)",
          paddingLeft: '-1px'
        }}
      >
        <TextField
          disabled
          value={formatName()}
          color="success"
          style={{justifyContent: "center", alignItems: "center"}}
          sx={{ height: '60px', backgroundColor: "#EDEDED", border: "none", "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          }, }}
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <Box sx={{paddingLeft: '10px'}}>

                {!contact.contact.id && <Box sx={{ padding: 1 }}>To:</Box>}
              </Box>
            ),
            endAdornment: (
              <Box>
                <IconButton
                  onClick={() => {
                    handleContactEdit(contact.contact.id);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default ChatHeader;
