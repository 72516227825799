import { GetKeywordsApiParams, CreateKeywordApiParams, DeleteKeywordApiParams, UpdateKeywordApiParams } from './types';
import { useCallback } from 'react';
import apiClient from '../axiosClient';

/**
 * @function useGetKeywordsApi
 * @returns { GetKeywordsApiParams }
 */
export function useGetKeywordsApi(): GetKeywordsApiParams {
  return useCallback(params => apiClient.get(`/keywords`, {params}), []);
}

/**
 * @function useCreateKeywordApi
 * @returns { CreateKeywordApiParams }
 */
export function useCreateKeywordApi(): CreateKeywordApiParams {
  return useCallback((params, companyId) => apiClient.post(`/keywords`, params, {params: {companyId}}), []);
}

/**
 * @function useUpdateKeywordApi
 * @returns { UpdateKeywordApiParams }
 */
export function useUpdateKeywordApi(): UpdateKeywordApiParams {
  return useCallback((params, keywordId, companyId) => apiClient.put(`/keywords/${keywordId}`, params, {params: {companyId}}), []);
}

/**
 * @function useDeleteKeywordApi
 * @returns { DeleteKeywordApiParams }
 */
export function useDeleteKeywordApi(): DeleteKeywordApiParams {
  return useCallback((keywordId, companyId) => apiClient.delete(`/keywords/${keywordId}`, {params: {companyId}}), []);
}
