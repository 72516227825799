import styled from 'styled-components';

export const ForgorPassContainer = styled.div`
    width: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
`

export const Header = styled.div`
    font-size: 34px;
    margin-bottom: 15px;
    font-weight: 600;
`

export const SubHeader = styled.div`
    margin-bottom: 30px;
`;