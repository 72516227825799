import { Column } from "./types";

export const columns: Column[] = [
    { id: 'name', label: 'Name', minWidth: 450 },
    { id: 'event_type', label: 'Event Type', minWidth: 100 },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      align: 'center',
    },
  ];

export enum EventType {
  NewInboxMessage = 'new_inbox_message',
  UserJoinedGroup = 'user_joined_group',
  UserOptedOut = 'user_opted_out',
}

export const itemsPerPage = 25;