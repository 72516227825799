import styled from 'styled-components';
import TextField from '@mui/material/TextField';

export const ContentContainer = styled.div<{hideBorder: boolean}>`
    display: flex;
    flex-direction: column;
    width: ${props => props.hideBorder ? '450px' : '350px'};
    padding: ${props => props.hideBorder ? '0px' : '20px'};
    border: ${props => props.hideBorder ? '0px' : '1px solid lightgray'};
    border-radius: 5px;
`;

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-left: 30px;
    width: 800px;
`

export const Container = styled.div`
    display: flex;
    width: 100%;
`

export const FooterContainer = styled.div`
    display: flex;
    margin-top: 10px;
    align-items: center;

    .MuiFormControlLabel-label { 
        font-size: 14px;
    }
`;

export const StyledTextField = styled(TextField)`
    margin-top: 20px !important;
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }
`