import { Column } from "./types";

export const columns: Column[] = [
    { id: 'name', label: 'Name', minWidth: 70, isSelected: true },
    { id: 'description', label: 'Description', minWidth: 100, isSelected: true },
    { id: 'permissions', label: 'Permissions', minWidth: 100, isSelected: true },
    { id: 'createdAt', label: 'Created At', minWidth: 70, isSelected: true },
    { id: 'updatedAt', label: 'Updated At', minWidth: 70, isSelected: true },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 200,
      align: 'left',
      isSelected: true
    },
  ];

export enum PermissionType {
  MessageSend = 'message_send',
  ContactMetadata = 'contact_metadata',
  CampaignSend = 'campaign_send',
}

export const itemsPerPage = 25;