import styled from "styled-components";
export const Container = styled.div`
    
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, #EEE, #AAA);   
`;
export const Lock = styled.div`
    position: relative;
    border-radius: 5px;
    width: 55px;
    height: 45px;
    background-color: #333;
    animation: dip 1s;
    animation-delay: ($animationTime - .5);

    &::before,
    &::after {
    content: '';
    position: absolute;
    border-left: 5px solid #333;
    height: 20px;
    width: 15px;
    left: calc(50% - 12.5px);
    }

    &::before {
    top: -30px;
    border: 5px solid #333;
    border-bottom-color: transparent;
    border-radius: 15px 15px 0 0;
    height: 30px;
    animation: lock $animationTime, spin $animationTime;
    }

    &::after {
    top: -10px; 
    border-right: 5px solid transparent;
    animation: spin $animationTime;
    }
    }

    @keyframes lock {
    0% {
    top: -45px;
    }
    65% {
    top: -45px;
    }
    100% {
    top: -30px;
    }
    }

    @keyframes spin {
    0% {
    transform: scaleX(-1);
    left: calc(50% - 30px);
    }
    65% {
    transform: scaleX(1);
    left: calc(50% - 12.5px);
    }
    }
`;

export const Title = styled.h1`
margin: 40px 0 20px;
text-align: center;
`;

export const Description = styled.p`
margin: 40px 0 20px;
text-align: center;
`;
