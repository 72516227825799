import React, {useEffect, useState} from "react";
import TextField, {StandardTextFieldProps} from "@mui/material/TextField";
import {TextFieldWithCopyIconWrapper} from "./styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function TextFieldWithCopyIcon(props: StandardTextFieldProps) {
  const [copiedText, setCopiedText] = useState<string | undefined>(undefined);
  const [propsOverride, setPropsOverride] = useState<StandardTextFieldProps>(props);
  const disabledClassNameProps = {className: "Mui-disabled"};

  const copyTextToClipboard = (text?: string) => {
    if (text) {
      navigator.clipboard.writeText(text).then(() => {
        setCopiedText(text);
        setTimeout(() => setCopiedText(undefined), 400)
      });
    }
  }

  useEffect(() => {
    const propsOverride = {...props};
    if (propsOverride.disabled || propsOverride['aria-disabled']) {
      propsOverride.disabled = false;
      propsOverride["aria-readonly"] = true;
      propsOverride.inputProps = {...propsOverride.inputProps, ...disabledClassNameProps}
      propsOverride.InputProps = {...propsOverride.InputProps, ...disabledClassNameProps}
      propsOverride.InputLabelProps = {...propsOverride.InputLabelProps, ...disabledClassNameProps}
      propsOverride.FormHelperTextProps = {...propsOverride.FormHelperTextProps, ...disabledClassNameProps}
    }
    setPropsOverride(propsOverride);
  }, [props]);

  return (
    <TextFieldWithCopyIconWrapper className={copiedText ? 'grow' : ''}
                                  onClick={() => copyTextToClipboard(props.value?.toString())}>
      <TextField {...propsOverride}/>
      <ContentCopyIcon/>
    </TextFieldWithCopyIconWrapper>
  );
}

export default TextFieldWithCopyIcon;
