export const APP_CONFIG = {
    local: {
        WEB_URL: 'localhost',
        API_BASE_URL: `https://api-dev.agencytext.com`,
    },
    dev: {
        WEB_URL: 'app-dev.agencytext.com',
        API_BASE_URL: `https://api-dev.agencytext.com`,
    },
    qa: {
        WEB_URL: 'app-qa.agencytext.com',
        API_BASE_URL: `https://api-qa.agencytext.com`,
    },
    prod: {
        WEB_URL: 'app.agencytext.com',
        API_BASE_URL: `https://api.agencytext.com`,
    }
};
