import React, { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import MainWrapper from "src/components/MainWrapper/view";
import Contacts from "./Components/Contacts";
import Chat from "./Components/Chat";
import {
  useGetInboxContactChatService,
  useGetInboxContactsService,
  useSetInboxChatAsReadService,
  useSetInboxMessageAsUnreadService,
} from "src/services/inbox";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { getInboxChat } from "src/store/inbox/inboxSlice";
import {
  ContactCustomField,
  FileLinkWithMimeType,
  Group,
  InboxContact,
  InboxContactChatMessage,
  InboxNewMessage,
} from "src/constants/types";
import { useAddInboxMessageApi, useGetInboxContactsApi } from "src/api/inbox";
import { useGetGroupsApi } from "src/api/contacts";
import CreateContactModal from "./Components/CreateContactModal/view";
import AccessDenied from "../AccessDenied";
import { isPageForbidden } from "src/helpers/permissions";
import { useGetCustomFieldsApi } from "src/api/customFields";

function Inbox() {
  const [selectedContact, setSelectedContact] = useState<string | null>(null);
  const [selectedContactObject, setSelectedContactObject] = useState<
    InboxContact | undefined
  >(undefined);
  const [search, setSearch] = useState<string>("");
  const [isShowAll, setIsShowAll] = useState<boolean>(true);
  const getContacts = useGetInboxContactsService();
  const getAllContacts = useGetInboxContactsApi();
  const [allContacts, setAllContacts] = useState<{
    count: number;
    items: InboxContact[] | null;
  }>({ count: 0, items: null });

  const [contactsOffset, setContactsOffset] = useState(0);
  const [hasMoreContacts, setHasMoreContacts] = useState(false);
  const [totalContactsCount, setTotalContactsCount] = useState(0);

  const getChat = useGetInboxContactChatService();
  const createMessage = useAddInboxMessageApi();
  const setMessageAsUnread = useSetInboxMessageAsUnreadService();
  const setChatAsRead = useSetInboxChatAsReadService();
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  const chat = useSelector(getInboxChat);
  const getGroups = useGetGroupsApi();
  const getCustomFields = useGetCustomFieldsApi();
  const [editableContact, setEditableContact] = useState<
    InboxContact | undefined
  >();
  const [showCreateContactModal, setCreateContactModal] = useState(false);
  const [isContactsLoading, setIsContactsLoading] = useState<boolean>(false);
  const [isChatLoading, setIsChatLoading] = useState<boolean | undefined>(
    undefined
  );
  const [group, setGroup] = useState<Group | undefined>();
  const messagesRef = useRef<HTMLDivElement>(null);
  const [newMessage, setNewMessage] = useState<InboxNewMessage>();
  const [isNewChat, setIsNewChat] = useState<boolean | undefined>(false);

  const scrollChatToBottom = () => {
    if (messagesRef.current?.scrollTop !== undefined) {
      messagesRef.current.scrollTop = messagesRef?.current?.scrollHeight;
    }
  };

  const isChatIsScrolledToBottom = () => {
    if (messagesRef.current?.scrollTop !== undefined) {
      return (
        messagesRef.current.scrollHeight -
          messagesRef.current.scrollTop -
          messagesRef.current.clientHeight <
        1
      );
    }
  };

  const handleNewChatClick = () => {
    setIsNewChat(true);
  };

  const handleContactEdit = async (contactId: string) => {
    const customFieldLimit = 1000000;
    const contact = allContacts?.items?.find(
      ({ contact }) => contact.id === contactId
    );
    const customFields = await getCustomFields({
      companyId: selectedCompanyId!,
      offset: 0,
      limit: customFieldLimit,
    });
    const metadata = customFields.data.items.map((customField) => {
      const field = contact?.contact.metadata.find(
        (m) => m.metadataId === customField.id
      );
      if (field) {
        return field;
      } else {
        return {
          contactId: contact!.contact.id,
          metadataId: customField.id,
          type: customField.type,
          name: customField.name,
          description: customField.description,
          value: "",
        } as ContactCustomField;
      }
    });

    contact!.contact.metadata = metadata;
    setEditableContact(contact);
    setCreateContactModal(true);
  };

  const refreshAllContacts = (callback: () => void = () => {}) => {
    setContactsOffset(0);
    getInboxContacts(callback);
  }

  const getInboxContacts = (callback: () => void  = () => {}) => {
    if (!selectedCompanyId) {
      return;
    }
    const searchParams = search
      ? {
          query: search,
          companyId: selectedCompanyId!,
          limit: 50,
          offset: contactsOffset,
        }
      : {
          companyId: selectedCompanyId!,
          limit: 50,
          offset: contactsOffset,
        };
    getAllContacts(searchParams)
      .then(({ data }) => {
        setAllContacts(data);
        setHasMoreContacts(data.count > (allContacts.items?.length || 0));
        setTotalContactsCount(data.count);
        setIsContactsLoading(false);
        if (callback) {
          callback();
        }
        window.setTimeout(() => {
          scrollChatToBottom();
        }, 50);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsContactsLoading(false);
      });
  };

  const fetchMoreData = () => {
    if (!selectedCompanyId) {
      return;
    }

    setContactsOffset((prev) => prev + 50);

    const searchParams = search
      ? {
          query: search,
          companyId: selectedCompanyId!,
          limit: 50,
          offset: contactsOffset + 50,
        }
      : {
          companyId: selectedCompanyId!,
          limit: 50,
          offset: contactsOffset + 50,
        };
    getAllContacts(searchParams)
      .then(({ data }) => {
        const result = {
          count: allContacts.count + data.count,
          items: [...(allContacts.items || [])].concat(data.items),
        };
        setAllContacts(result);
        setHasMoreContacts(data.count > (allContacts.items?.length || 0));
        setContactsOffset((prevState) => prevState + 50);
        setTotalContactsCount(data.count);
        setIsContactsLoading(false);
        window.setTimeout(() => {
          scrollChatToBottom();
        }, 50);
      })
      .catch((e) => {
        console.log(e);
      }).finally(() => {
        setIsContactsLoading(false);
      });
  };

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }
    setContactsOffset(0);
    setSelectedContact(null);
    setSelectedContactObject(undefined);
    getGroups({ companyId: selectedCompanyId!, offset: 0, limit: 10000 }).then(
      (data) => {
        const defaultGroup = data.data.items.find((g) => g.type === "default");
        if (defaultGroup) {
          setGroup(defaultGroup);
          getInboxContacts();
        }
      }
    );
  }, [selectedCompanyId]);

  useEffect(() => {
    setContactsOffset(0);
    getInboxContacts();
  }, [search]);


  useEffect(() => {
    if (!selectedCompanyId || !selectedContact) {
      return;
    }
    setIsChatLoading(true);

    if (!selectedContactObject && allContacts) {
      const contact = allContacts?.items?.find(
        (c) => c.contact.id === selectedContact
      );
      setSelectedContactObject(contact);
    }

    getChat(selectedCompanyId, selectedContact, 0, 10000).then(() => {
      setIsChatLoading(false);
      window.setTimeout(() => {
        scrollChatToBottom();
      }, 50);
    });
    const timer = setInterval(() => {
      getChat(selectedCompanyId, selectedContact, 0, 10000);
      if (isChatIsScrolledToBottom()) {
        scrollChatToBottom();
      }
    }, 10000);
    return () => {
      clearInterval(timer);
    };
  }, [selectedContact]);

  const addMessage = async (text: string): Promise<void> => {
    if (!text && newMessage && newMessage.links.length === 0) {
      return;
    }
    if (selectedContact && selectedCompanyId) {
      const message = { ...newMessage! };
      message.text = text;
      message.messageType =
        message.links && message.links.length > 0 ? "mms" : "sms";
      message.contactId = selectedContact;
      message.links = message.links || [];
      delete message.linksWithMimeType;
      await createMessage(message, selectedCompanyId)
        .then(async () => {
          scrollChatToBottom();
          getChat(selectedCompanyId, selectedContact, 0, 100000).then(() => {
            scrollChatToBottom();
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const updateLinks = (link: FileLinkWithMimeType) => {
    if (newMessage) {
      const message = { ...newMessage! };
      message.linksWithMimeType = link
        ? message.linksWithMimeType
          ? message.linksWithMimeType.concat([link])
          : [link]
        : [];
      message.links = link
        ? message.links
          ? message.links.concat([link.url])
          : [link.url]
        : [];
      message.messageType = link ? "mms" : "sms";
      setNewMessage(message);
    } else {
      if (selectedContact) {
        const newMessage: InboxNewMessage = {
          contactId: selectedContact,
          text: "",
          messageType: link ? "mms" : "sms",
          links: link ? [link.url] : [],
          linksWithMimeType: link ? [link] : [],
        };
        setNewMessage(newMessage);
      }
    }
  };

  const onSearchContact = (search: string) => {
    setSearch(search);
  };

  const markMessageAsUnread = (message: InboxContactChatMessage) => {
    if (selectedCompanyId && selectedContact) {
      setMessageAsUnread(selectedCompanyId, [message.id]);
      window.setTimeout(() => {
        getContacts(selectedCompanyId, search, 0, 10000);
        getChat(selectedCompanyId, selectedContact, 0, 10000);
      }, 200);
    }
  };

  const selectChat = (contactId: string | null) => {
    if (selectedCompanyId) {
      setSelectedContact(contactId);
      const contact = allContacts?.items?.find(
        (c) => c.contact.id === contactId
      );
      setSelectedContactObject(contact);
      setIsNewChat(undefined);
      if (contactId) {
        setChatAsRead(selectedCompanyId, [contactId]);
      }
    }
  };

  const unreadContacts = {
    ...allContacts,
    items:
      allContacts?.items?.filter(
        (c) => !c.isChatRead || c.contact.id === selectedContact
      ) || null,
  };

  if (isPageForbidden()) {
    return <AccessDenied />;
  }
  
  return (
    <MainWrapper>
      <Stack sx={{ width: "100%" }} direction="row">
        <Contacts
          search={search}
          fetchMoreData={fetchMoreData}
          hasMoreContacts={hasMoreContacts}
          totalContactsCount={totalContactsCount}
          handleNewChatClick={handleNewChatClick}
          isContactsLoading={isContactsLoading}
          isShowAll={isShowAll}
          toggleShowAll={() => {
            setIsShowAll(!isShowAll);
            setSearch("");
          }}
          selectedContact={selectedContact}
          onSearchContact={onSearchContact}
          onSelectContact={selectChat}
          lastSelectedChatMessageDate={
            chat?.items[chat.items.length - 1]?.dateCreated
          }
          contacts={isShowAll ? allContacts : unreadContacts}
        />
        <Box
          sx={{
            minHeight: "100%",
            height: "100%",
            width: "calc(100vw - 420px)",
          }}
        >
          <Chat
           refreshContacts={refreshAllContacts}
            setIsChatLoading={setIsChatLoading}
            isNewChat={isNewChat}
            onSelectContact={selectChat}
            ref={messagesRef}
            handleContactEdit={handleContactEdit}
            isChatLoading={isChatLoading}
            group={group}
            updateLinks={updateLinks}
            addMessage={addMessage}
            markMessageAsUnread={markMessageAsUnread}
            messages={chat}
            contact={selectedContactObject}
          />
        </Box>
      </Stack>
      {showCreateContactModal && (
        <CreateContactModal
          show={showCreateContactModal}
          setShowModal={setCreateContactModal}
          refresh={getInboxContacts}
          groupData={group}
          contactData={editableContact}
        />
      )}
    </MainWrapper>
  );
}

export default Inbox;
