import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { ContentContainer, Container, FooterContainer } from './styles';
import Button from '@mui/material/Button';
import { ValidationErrors, inviteUserValidationSchema } from 'src/constants/validations';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { selectedCompany } from 'src/store/company/companySlice';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import { User } from 'src/constants/types';
import { selectRoles } from 'src/store/profile/roleSlice';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useInviteUserApi, useUpdateUserRoleApi } from 'src/api/users';
import { selectProfile } from 'src/store/profile/profileSlice';
import { filterAllowedRoles, reorderRoles } from 'src/helpers/permissions';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        height: '700px',
    },
    '& .MuiPaper-root': {
        maxWidth: 'unset'
    }
}));

type InviteUserModalType = {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    userData?: User;
    refresh: () => void;
}

const initialValues = { email: '', firstName: '', lastName: '', phoneNumber: '', roleId: ''};

export default function InviteUserModal({ show, setShowModal, refresh, userData }: InviteUserModalType) {
    const [formValues, setFormValues] = useState(initialValues);
    const [errors, setErrors] = useState<ValidationErrors>({});

    const selectedCompanyId: string | undefined = useSelector(selectedCompany);
    const { profile } = useSelector(selectProfile);
    const { roles } = useSelector(selectRoles);
    const allowedRoles = useMemo(() => filterAllowedRoles(profile?.role ? profile.role.name : 'bhi_admin', roles), [roles, profile]);
    const inviteUser = useInviteUserApi();
    const updateRole = useUpdateUserRoleApi();

    const handleClose = () => {
        setFormValues(initialValues);
        setErrors({});
        setShowModal(false);
        refresh()
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSelectChange = ({ target }: SelectChangeEvent<string>) => {
        setFormValues({ ...formValues, roleId: target.value });
    };

    const validate = async (values: typeof formValues) => {
        try {
            await inviteUserValidationSchema.validate(values, { abortEarly: false });
            setErrors({});
            return true;
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const newErrors = err.inner?.reduce((acc, curr) => {
                    if (curr.path) {
                        acc[curr.path] = curr?.message;
                    }
                    return acc;
                }, {} as ValidationErrors);
                setErrors(newErrors);
            }
            return false;
        }
    };

    const handleInviteUser = async () => {
        const isValid = await validate(formValues);
        if (isValid) {
            selectedCompanyId && inviteUser({
                ...formValues,
                phoneNumber: formValues.phoneNumber ? "+1" + formValues.phoneNumber : "",
            }, selectedCompanyId).then(() => {
                handleClose();
                setErrors({});
                refresh();
            }).catch((e) => {
                if (e.error === 'conflict') {
                    setErrors({'validation': 'User with that email already exists!'})
                }
            });
        }
    };

    const handleUserEditRole = () => {
        selectedCompanyId && userData?.id && updateRole({roleId: formValues.roleId}, userData?.id, selectedCompanyId).then(() => {
            handleClose();
            refresh();
            setErrors({});
        }).catch((e) => {
            if (e.error === 'conflict' || e.error === 'validation') {
                setErrors({ 'validation': 'Something went wrong!' });
            }
        })
    }

    useEffect(() => {
        userData && setFormValues({
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            phoneNumber: userData.phoneNumber,
            roleId: userData.role.id
        })
    }, [userData?.id])

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={show}
                keepMounted={false}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" >
                    {userData ? 'Edit User' : 'Invite User'}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                    <Container>
                        <ContentContainer>
                            <TextField id="outlined-basic" label="First Name" name="firstName" variant="outlined" value={formValues.firstName} onChange={handleChange} disabled={!!userData} error={!!errors?.firstName} helperText={errors?.firstName || ''}/>
                            <TextField id="outlined-basic" style={{ marginTop: '20px' }} label="Last Name" name="lastName" variant="outlined" value={formValues.lastName} onChange={handleChange} disabled={!!userData} error={!!errors?.lastName} helperText={errors?.lastName || ''}/>
                            <TextField id="outlined-basic" style={{ marginTop: '20px' }} label="Email" name="email" variant="outlined" value={formValues.email} onChange={handleChange} error={!!errors?.email} helperText={errors?.email || ''} disabled={!!userData}/>
                            <TextField id="outlined-basic" style={{ marginTop: '20px' }} InputProps={{
                                startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                            }}
                                label="Phone Number" name="phoneNumber" type="number" variant="outlined" value={formValues.phoneNumber?.replace('+1', '')} onChange={handleChange} error={!!errors?.phoneNumber} helperText={errors?.phoneNumber || ''} disabled={!!userData}/>
                                <FormControl sx={{ marginTop: '20px' }}>
                                <InputLabel htmlFor="bhi-simple-select">Role</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formValues.roleId}
                                    label="Role"
                                    onChange={handleSelectChange}
                                    name="role"
                                    error={!!errors.roleId}
                                >
                                    {reorderRoles(roles).map((role) => {
                                        return <MenuItem value={role.id} key={role.id} disabled={!allowedRoles.includes(role.id)}>{role.title}</MenuItem>
                                    })}
                                </Select>
                                {errors?.roleId && <FormHelperText error>{errors?.roleId}</FormHelperText>}
                                </FormControl>
                        </ContentContainer>
                    </Container>
                    {errors?.validation && <FormHelperText error>{errors?.validation}</FormHelperText>}
                    <FooterContainer>
                        {userData ?
                            <Button variant="contained" style={{ width: '180px', marginTop: '20px' }} onClick={handleUserEditRole}>Save</Button> :
                            <Button variant="contained" style={{ width: '180px', marginTop: '20px' }} onClick={handleInviteUser}>Invite</Button>
                        }
                    </FooterContainer>
                </DialogContent>
            </BootstrapDialog>
        </Fragment>
    );
}
