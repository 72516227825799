import { useCallback } from "react";
import {
  useGetInboxContactsApi,
  useGetInboxContactChatApi,
  useInboxMarkChatAsReadApi,
  useInboxMarkMessageAsUnreadApi,
  useAddInboxMessageApi,
} from "src/api/inbox";
import {
  setContacts,
  setChat,
  setChatAsRead,
  setMessageAsUnread,
  addMessage,
} from "src/store/inbox/inboxSlice";
import { useDispatch } from "react-redux";
import {FileLinkWithMimeType, InboxContactChatMessage} from "src/constants/types";
import dayjs from "dayjs";

export const useGetInboxContactsService = () => {
  const dispatch = useDispatch();
  const getInboxContacts = useGetInboxContactsApi();

  return useCallback(
    async (companyId: string, query: string, offset: number, limit: number) => {
      return await getInboxContacts({ companyId, query, offset, limit })
        .then(({ data }) => {
          dispatch(setContacts(data));
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [dispatch, getInboxContacts]
  );
};

export const useGetInboxContactChatService = () => {
  const dispatch = useDispatch();
  const getInboxContactChat = useGetInboxContactChatApi();

  return useCallback(
    async (
      companyId: string,
      contactId: string,
      offset: number,
      limit: number
    ) => {
      try {
        const { data } = await getInboxContactChat({
          companyId,
          contactId,
          offset,
          limit,
        });
        data.items = data.items.sort(
          (a: InboxContactChatMessage, b: InboxContactChatMessage) =>
            dayjs(a.dateCreated).isAfter(dayjs(b.dateCreated)) ? 1 : -1
        );
        dispatch(setChat(data));
      } catch (e) {
        console.log(e);
      }
    },
    [dispatch, getInboxContactChat]
  );
};

export const useSetInboxChatAsReadService = () => {
  const dispatch = useDispatch();
  const setInboxChatAsRead = useInboxMarkChatAsReadApi();

  return useCallback(
    (companyId: string, contactIds: string[]) => {
      setInboxChatAsRead({ contactIds }, companyId)
        .then(() => {
          dispatch(setChatAsRead(contactIds));
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [dispatch, setInboxChatAsRead]
  );
};

export const useSetInboxMessageAsUnreadService = () => {
  const dispatch = useDispatch();
  const setInboxMessageAsUnread = useInboxMarkMessageAsUnreadApi();

  return useCallback(
    (companyId: string, messageIds: string[]) => {
      setInboxMessageAsUnread({ messageHistoryIds: messageIds }, companyId)
        .then(() => {
          dispatch(setMessageAsUnread(messageIds));
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [dispatch, setInboxMessageAsUnread]
  );
};

export const useAddInboxMessageService = () => {
  const dispatch = useDispatch();
  const addInboxMessage = useAddInboxMessageApi();

  return useCallback(
    (
      companyId: string,
      contactId: string,
      messageType: "sms" | "mms",
      text: string,
      links: string[],
      linksWithMimeType: FileLinkWithMimeType[],
    ) => {
      addInboxMessage({ contactId, messageType, text, links, linksWithMimeType }, companyId).then(
        () => {
          dispatch(addMessage({ contactId, messageType, text, links, linksWithMimeType }));
        }
      );
    },
    [dispatch, addInboxMessage]
  );
};
