import styled from 'styled-components';

export const PhoneWrapper = styled.div`
  width: 250px; 
  height: 490px; 
  border-radius: 25px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  box-sizing: border-box;
  border: 4px solid black;
  overflow: auto;
  scrollbar-width: thin;
`;

export const TopBar = styled.div`
    width: 77px;
    height: 15px;
    background: black;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    position: absolute;
`

export const Screen = styled.div`
  background-color: #f5f6f8;
  width: 100%;
  min-height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
`;

export const ChatBubble = styled.div<{ isUser: boolean }>`
  background-color: ${props => (props.isUser ? '#007aff' : '#f0f0f0')};
  color: ${props => (props.isUser ? '#ffffff' : '#000000')};
  padding: 10px 15px;
  margin: 5px;
  border-radius: 18px;
  max-width: 80%;
  align-self: ${props => (props.isUser ? 'flex-end' : 'flex-start')};
  word-break: break-word;
  max-height: 50%;
  overflow-y: auto;
  white-space: pre-wrap;
`;

export const ChatContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const ImgDisplayer = styled.img`
    width: 100%;
    height: auto;
`;