import { GetUsersApiParams, DeleteUserApiParams, InviteUserApiParams, UpdateUserRoleApiParams, GetUserApiParams } from './types';
import { useCallback } from 'react';
import apiClient from '../axiosClient';

/**
 * @function useGetUsersApi
 * @returns { GetUsersApiParams }
 */
export function useGetUsersApi(): GetUsersApiParams {
  return useCallback((params) => apiClient.get(`/users`, {params}), []);
}

/**
 * @function useGetUserApi
 * @returns { GetUserApiParams }
 */
export function useGetUserApi(): GetUserApiParams {
  return useCallback((userId, companyId) => apiClient.get(`/users/${userId}`, {params: {companyId}}), []);
}

/**
 * @function useDeleteUserApi
 * @returns { DeleteUserApiParams }
 */
export function useDeleteUserApi(): DeleteUserApiParams {
  return useCallback((userId, companyId) => apiClient.delete(`/users/${userId}/detach-company`, {params: {companyId}}), []);
}

/**
 * @function useInviteUserApi
 * @returns { InviteUserApiParams }
 */
export function useInviteUserApi(): InviteUserApiParams {
  return useCallback((params, companyId) => apiClient.post(`/users/invite`, params, {params: {companyId}}), []);
}

/**
 * @function useUpdateUserRoleApi
 * @returns { UpdateUserRoleApiParams }
 */
export function useUpdateUserRoleApi(): UpdateUserRoleApiParams {
  return useCallback((params, userId, companyId) => apiClient.put(`/users/${userId}/role`, params, {params: {companyId}}), []);
}
