import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import {
  InboxContact,
  InboxContactChat,
  InboxContactChatMessage,
  InboxContacts,
  InboxNewMessage
} from 'src/constants/types';

interface InboxState {
  contacts: InboxContacts | null;
  chat: InboxContactChat | null;
  newMessage: InboxNewMessage | null;
}

const initialState: InboxState = {
  contacts: null,
  chat: null,
  newMessage: null,
};

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    setContacts: (state, action: PayloadAction<InboxContacts>) => {
      state.contacts = action.payload;
    },
    setChat: (state, action: PayloadAction<InboxContactChat>) => {
      state.chat = action.payload;
    },
    setChatAsRead: (state, action: PayloadAction<string[]>) => {
      if (state.contacts && state.contacts.items) {
        state.contacts.items = state.contacts.items.map((c: InboxContact) => ({...c, isChatRead: action.payload.includes(c.contact.id) ? true : c.isChatRead }));
      }
    },
    setMessageAsUnread: (state, action: PayloadAction<string[]>) => {
      if (state.chat) {
        state.chat.items = state.chat.items.map((c: InboxContactChatMessage) => ({...c, isRead: action.payload.includes(c.id) ? true : c.isRead }))
      }
    },
    addMessage: (state, action: PayloadAction<InboxNewMessage>) => {
      state.newMessage = action.payload;
    }
  },
});

export const { setContacts, setChat, addMessage, setChatAsRead, setMessageAsUnread } = inboxSlice.actions;

export const getInboxContacts = (state: RootState) => state.inbox.contacts;
export const getInboxChat = (state: RootState) => state.inbox.chat;
export const getInboxNewMessage = (state: RootState) => state.inbox.newMessage;

export default inboxSlice.reducer;
