import React, {Fragment, useEffect, useState} from "react";
import { Box, Divider, Stack, CircularProgress } from "@mui/material";
import { InboxContactChat, InboxContactChatMessage } from "src/constants/types";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Message from "./Message";

type ConversationProps = {
  messages: InboxContactChat | null;
  markMessageAsUnread: (message: InboxContactChatMessage) => void;
  isChatLoading: boolean | undefined;
};

const Conversation = ({ messages, markMessageAsUnread, isChatLoading }: ConversationProps) => {
  dayjs.extend(relativeTime);
  const [unreadMessageId, setUnreadMessageId] = useState<string>();
  const [hoverMessageId, setHoverMessageId] = useState<string>();

  useEffect(() => {
    const unreadMessage = messages?.items.filter((m) => !m.isRead)[0];
    if (unreadMessage) {
      setUnreadMessageId(unreadMessage.id);
    } else {
      setUnreadMessageId('');
    }
  }, [messages]);


  if (isChatLoading === undefined) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '70vh', width: '100%'}} p={2} paddingRight={0}>
      <Stack spacing={3}>
      </Stack>
    </Box>
    )
  }

  if (isChatLoading === true) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '70vh', width: '100%'}} p={2} paddingRight={0}>
      <Stack spacing={3}>
        <CircularProgress />
      </Stack>
    </Box>
    )
  }

  return (
    <Box p={2} paddingRight={0}>
      <Stack spacing={3}>
        {messages?.items.map((el) => (
          <Fragment key={el.id}>
            {el.id === unreadMessageId && (
              <Divider textAlign="center">Unread</Divider>
            )}
            <Message message={el} markMessageAsUnread={markMessageAsUnread} hoverMessageId={hoverMessageId} setHoverMessageId={setHoverMessageId} />
          </Fragment>
        ))}
      </Stack>
    </Box>
  );
};

export default Conversation;
